import React from "react";

import hubspotImg from "../../assets/hubspot.png";
import style from "./style.module.scss";

export function Logo() {
  return (
    <header>
      <img src={hubspotImg} alt="hubspot" className={style.logo} />
    </header>
  );
}
