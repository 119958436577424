import React from "react";
import { CHANNEL_TAG_AUTOMATION_OBJECT_TYPES } from "./props";
import style from "./style.module.scss";
import TagAutomationTagging from "./TagAutomationTagging";

function ChannelTagAutomation({
  channelType,
  setTempModalState,
  tagAutomation,
}) {
  return (
    <div className={style.tagAutomationCard}>
      <div className={style.tagAutomationCard__text}>Auto Tag</div>
      <div className={style.tagAutomationCard__taggingArea}>
        <TagAutomationTagging
          channelType={channelType}
          objectType={CHANNEL_TAG_AUTOMATION_OBJECT_TYPES.INSTANCE}
          setTempModalState={setTempModalState}
          tags={tagAutomation.tagMeetings}
        />
        <TagAutomationTagging
          channelType={channelType}
          objectType={CHANNEL_TAG_AUTOMATION_OBJECT_TYPES.CONTACT}
          setTempModalState={setTempModalState}
          tags={tagAutomation.tagContacts}
        />
      </div>
    </div>
  );
}

export default ChannelTagAutomation;
