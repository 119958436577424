import React from "react";
import Flexbox from "../../../components/flexbox";
import { useInstanceDetails, useTableRowMeeting } from "../../context";
import { P1 } from "../../../components/typography";
import style from "./newTableCells.module.scss";

const Cost = () => {
  const meeting = useTableRowMeeting();
  const open = useInstanceDetails(meeting?.id || null);

  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.cell} onClick={open}>
          <P1>&mdash;</P1>
        </Column>
      )}
    </Flexbox>
  );
};

export default Cost;
