import React, { useCallback, useMemo } from "react";
import { mdiChevronDown } from "@mdi/js";
import Flexbox from "../../../components/flexbox";
import { ButtonContainer, ButtonIcon } from "../../../components/button";
import InitialsIcon from "../../../components/initials";
import { P1 } from "../../../components/typography";
import { SET_DM_IS_OPEN, useMeeting, useMessengerState } from "../state";
import { getInitials } from "../util/helpers";
import style from "./directMessengerHeader.module.scss";

const DirectMessengerHeader = () => {
  const [meeting] = useMeeting();
  const { messengerDispatch } = useMessengerState();

  const dmName = useMemo(() => {
    if (meeting?.guests?.length > 0) {
      const guest = meeting.guests[0];
      if (guest.firstName || guest.lastName) {
        return `${guest?.firstName || ""} ${guest?.lastName || ""}`;
      }
      return guest.email;
    }
  }, [meeting]);

  const initials = useMemo(() => {
    if (meeting?.guests?.length > 0) {
      return getInitials(meeting.guests[0]);
    }
    return "";
  }, [meeting]);

  const handleCloseDM = useCallback(() => {
    messengerDispatch({
      payload: { value: false },
      type: SET_DM_IS_OPEN,
    });
  }, [messengerDispatch]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row height="15%" justifyContent="flex-start">
          <ButtonContainer
            className={style.directMessengerHeader__action}
            onClick={handleCloseDM}
          >
            <ButtonIcon
              className={style.directMessengerHeader__actionIcon}
              path={mdiChevronDown}
              size={1.5}
            />
          </ButtonContainer>

          <P1 className={style.directMessengerHeader__name}>{dmName}</P1>

          <InitialsIcon
            initials={initials}
            className={style.directMessengerHeader__initalsIcon}
          />
        </Row>
      )}
    </Flexbox>
  );
};

export default DirectMessengerHeader;
