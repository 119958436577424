// legacy -- for building pre-multiguest logs
const ACCEPTED = "accepted";
const CANCELLED = "cancelled";
const DECLINED = "declined";
const ENGAGED_NO_INTENT = "engaged_no_intent";
const INIT_FAULT = "init_fault";
const INITIALIZED = "initialized";
export const INITIALIZING = "initializing";
const NEGOTIATING_IN_PROGRESS = "negotiation_in_progress";
const NO_RESPONSE = "no_response";
const QUEUED = "queued";
const REQUIRES_USER_INTERVENTION = "requires_user_intervention";
const USER_INTERVENED = "user_intervened";
const WAITING_FOR_FIRST_RESPONSE = "waiting_for_first_response";

export const LEGACY_STATUS_TO_LABEL = {
  [ACCEPTED]: "Accepted",
  [CANCELLED]: "Cancelled",
  [DECLINED]: "Declined",
  [ENGAGED_NO_INTENT]: "Engaged No Intent",
  [INITIALIZED]: "Initialized",
  [INITIALIZING]: "Initializing",
  [INIT_FAULT]: "Initialization Fault",
  [NEGOTIATING_IN_PROGRESS]: "A.I. Negotiating",
  [NO_RESPONSE]: "No Response",
  [QUEUED]: "Queued",
  [REQUIRES_USER_INTERVENTION]: "Requires User Intervention",
  [USER_INTERVENED]: "User Intervened",
  [WAITING_FOR_FIRST_RESPONSE]: "Proposal Pending",
};

export function toLegacyStatusLabel(s) {
  if (s in LEGACY_STATUS_TO_LABEL) {
    return LEGACY_STATUS_TO_LABEL[s];
  }
  return null;
}

const LEGACY_COLOR_MAP = {
  [ACCEPTED]: "#02BA86",
  [CANCELLED]: "#6894ad",
  [DECLINED]: "#6894ad",
  [ENGAGED_NO_INTENT]: "#b0d4e8",
  [INITIALIZED]: "#76C9F9",
  [INITIALIZING]: "#9b9696",
  [INIT_FAULT]: "#687272",
  [NEGOTIATING_IN_PROGRESS]: "#6894ad",
  [NO_RESPONSE]: "#9b9696",
  [QUEUED]: "#76C9F9",
  [REQUIRES_USER_INTERVENTION]: "#2BAEF9",
  [USER_INTERVENED]: "#9b9696",
  [WAITING_FOR_FIRST_RESPONSE]: "#b0d4e8",
};

export function toLegacyStatusColor(s) {
  if (s in LEGACY_COLOR_MAP) {
    return LEGACY_COLOR_MAP[s];
  }

  return null;
}
