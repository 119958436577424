import React from "react";
import { Box, Button } from "@mui/material";

function SignInButton({
  icon,
  color,
  children,
  disabled,
  onClick,
}: {
  color: string;
  icon: React.ReactNode;
  children: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      disableRipple
      disableElevation
      sx={{
        "&:hover": {
          backgroundColor: color,
          boxShadow: `0px 0px 2px 1px ${color}`,
        },
        alignItems: "center",
        backgroundColor: color,
        borderColor: color,
        borderRadius: "5px",
        borderStyle: "solid",
        borderWidth: "1px",
        justifyContent: "flex-start",
        padding: 0,
        textTransform: "none",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "white",
          borderColor: color,
          borderRadius: "5px",
          borderStyle: "transparent",
          borderWidth: "1px",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          padding: "4px",
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          paddingX: "8px",
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Button>
  );
}

export default SignInButton;
