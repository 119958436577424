import { useDebounce } from "src/hooks";
import { useTeams } from "src/queries";
import { Team } from "src/types";
import { FilterAutocomplete } from "./FilterAutocomplete";

export function TeamFilter(props: {
  search: string;
  onSearchChange: (value: string) => void;
  value?: Team[];
  onChange?: (value: Team[]) => void;
}) {
  const s = useDebounce(props.search, 750);

  const { data, loading } = useTeams(10, 0, {
    qry: s,
    sortBy: "name+asc",
  });

  return (
    <FilterAutocomplete
      label="Team"
      value={props.value}
      onChange={props.onChange}
      loading={loading}
      search={props.search}
      onSearchChange={props.onSearchChange}
      options={data?.data || []}
      getOptionLabel={(team) => team.name}
      getOptionId={(team) => team.id}
    />
  );
}

export default TeamFilter;
