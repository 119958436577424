import { Box, Container } from "@mui/material";
import { Breadcrumb } from "./Breadcrumbs";

import PageHeader from "./PageHeader";

export function PageLayout(props: {
  title: string;
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
}) {
  return (
    <Box sx={{ backgroundColor: "#f1f1f1", minHeight: "100%" }}>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{ px: "30px", pt: "35px", pb: "65px" }}
      >
        <PageHeader
          title={props.title}
          breadcrumbs={props.breadcrumbs}
          actions={props.actions}
        />
        {props.children}
      </Container>
    </Box>
  );
}
