import { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack, Typography, Box } from "@mui/material";

import { Calendar } from "../../types";
import { CalendarMonth } from "@mui/icons-material";
import useCalendars from "src/queries/useCalendars";

export function AddCalendarInput({
  onSelect,
}: {
  onSelect: (value: Calendar) => void;
}) {
  const searchInput = useRef<HTMLInputElement>();
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [focused, setFocus] = useState(false);

  const { data, loading } = useCalendars();

  const setInputFocus = (state: boolean) => {
    if (searchInput.current?.value) {
      return setFocus(true);
    }

    setFocus(state);
    setInput("");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Autocomplete
          sx={{ width: "100%" }}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          inputValue={input}
          onInputChange={(_, value) => {
            setInput(value);
          }}
          onChange={(_, value) => {
            if (value) {
              onSelect(value);
            }
            if (!searchInput?.current) {
              console.error("searchInput reference is undefined");
              return null;
            }

            searchInput.current.value = "";
            searchInput.current.blur();
          }}
          value={null}
          filterOptions={(options) => options}
          getOptionLabel={(option) =>
            `${option.userFirstName} ${option.userLastName} - ${option.calendarName}`
          }
          options={data || []}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CalendarMonth />
                  <Typography>Add Calendar</Typography>
                </Stack>
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </>
                ),
                inputRef: searchInput,
                onBlur: () => setInputFocus(false),
                onFocus: () => setInputFocus(true),
              }}
              InputLabelProps={{ shrink: focused }}
            />
          )}
        />
      </Box>
    </>
  );
}

export default AddCalendarInput;
