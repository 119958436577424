import React from "react";
import cx from "classnames";

import Flexbox from "../../../components/flexbox";
import { QuorumIcon } from "../../icons";
import { getInitials } from "../../instanceDetails/util/helpers";
import InitialsIcon from "../../../components/initials";

import style from "./guestIcons.module.scss";

function GuestIcons({ guest }) {
  return (
    <Flexbox>
      {({ Row }) => (
        <Row width="fit-content" style={{ paddingLeft: "15px" }}>
          <QuorumIcon
            className={cx({
              [style["guestIcons__icon--hidden"]]: guest === null,
            })}
            type="guest"
            width="25px"
            height="25px"
            size={{ small: true }}
          />

          <InitialsIcon
            className={cx({
              [style["guestIcons__icon--hidden"]]: guest === null,
            })}
            initials={getInitials(guest)}
            width="28px"
            height="28px"
            size={{ small: true }}
          />
        </Row>
      )}
    </Flexbox>
  );
}

export default GuestIcons;
