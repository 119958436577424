import { useRef, useState } from "react";
import { Box, Paper, Popover, TextField } from "@mui/material";
import FilterButton from "./FilterButton";

export function FilterSearchString(props: {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const selected = props.value || [];

  return (
    <div>
      <FilterButton
        ref={buttonRef}
        onClick={() => {
          setOpen(true);
        }}
        active={selected.length > 0}
        dropdown
      >
        {props.label}
      </FilterButton>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper>
          <Box sx={{ p: 1 }}>
            <TextField
              autoFocus
              size="small"
              fullWidth
              placeholder="Search"
              value={props.value}
              onChange={(event) => {
                props.onChange?.(event.target.value);
              }}
            />
          </Box>
        </Paper>
      </Popover>
    </div>
  );
}

export default FilterSearchString;
