import React from "react";
import { Stack, Typography, Link, useTheme, Card, Box } from "@mui/material";
import { CheckOutlined } from "@mui/icons-material";
import loaderSolid from "../../assets/LoaderSolid.png";
import { OneClickBackground } from "../../componentsV2/OneClickBackground";
import { useLocation } from "react-router-dom";
import { useConfigurationValue } from "src/configuration";
import OneClickLogoImage from "../../componentsV2/OneClickLogoImage";

export default function Page() {
  const theme = useTheme();
  const { email } = useLocation<{ email: string }>().state;
  const environment = useConfigurationValue("ENVIRONMENT", "dev");
  const poweredByURL = new URL("https://powered-by.kronologic.ai");
  poweredByURL.searchParams.append("c", btoa(email));
  poweredByURL.searchParams.append("e", btoa(environment));

  const url = useLocation<{ url: string }>().state?.url;

  return (
    <OneClickBackground>
      <Card
        sx={{
          zIndex: 10,
          px: "50px",
          py: "30px",
          maxWidth: "448px",
          width: "100%",
          minHeight: "440px",
        }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Box sx={{ marginBottom: "25px" }}>
            {url ? (
              <a href={url}>
                <OneClickLogoImage />
              </a>
            ) : (
              <OneClickLogoImage />
            )}
          </Box>
          <Typography variant="h4" align="center" sx={{ marginBottom: "10px" }}>
            Congratulations!
          </Typography>
          <Typography align="center" sx={{ marginBottom: "20px" }}>
            A meeting time was selected and a meeting invite is on the way!
          </Typography>

          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <img
              height="139px"
              width="139px"
              style={{ position: "absolute" }}
              src={loaderSolid}
            />
            <CheckOutlined
              sx={{ height: "79px", width: "79px", marginY: "44px" }}
              htmlColor={theme.palette.primary.dark}
            />
          </Stack>

          <Typography variant="caption" align="center">
            If this time does not work for you, please propose a new time, and
            the invite will be updated automatically. This service is powered by{" "}
            <Link href={poweredByURL.href}>Kronologic™</Link>
          </Typography>
        </Stack>
      </Card>
    </OneClickBackground>
  );
}
