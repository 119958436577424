import { Box, Card, Typography } from "@mui/material";
import { ReportsCampaignResponse } from "../queries/useReportingCampaign";

import { TableSortLabel } from "@mui/material";
import { Column, TableSort } from "./tables/Table";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LinkIcon from "@mui/icons-material/Link";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function ReportingCampaignTable({
  sortModel,
  setSortModel,
  data,
}: {
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (page: number) => void;
  sortModel: TableSort;
  setSortModel: (sort: TableSort) => void;
  data: ReportsCampaignResponse;
}) {
  const columns: Column<number>[] = [
    {
      component: () => <Typography>Template</Typography>,
      id: "campaign",
      label: "Template",
      sortable: true,
    },
    {
      component: () => <Typography>Accepts</Typography>,
      id: "acceptRate",
      label: "Accepts",
      sortable: true,
    },
    {
      component: () => <Typography>Declines</Typography>,
      id: "declineRate",
      label: "Declines",
      sortable: true,
    },
    {
      component: () => <Typography>Responses</Typography>,
      id: "responseRate",
      label: "Responses",
      sortable: true,
    },
    {
      component: () => <Typography>Team</Typography>,
      id: "team",
      label: "Team",
      sortable: true,
    },
    {
      component: () => <Typography>Total Meetings</Typography>,
      id: "meetingsCount",
      label: "Total Meetings",
      sortable: true,
    },
  ];

  return (
    <>
      <Card sx={{ mb: "30px" }} elevation={0}>
        <Box sx={{ padding: 2 }}>
          <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <>
                {columns.map((col) => {
                  return (
                    <TableCell
                      key={`col-${col.id}`}
                      align={col.align}
                      width={col.width}
                      padding="normal"
                    >
                      {col.sortable ? (
                        <TableSortLabel
                          active={col.id === sortModel?.columnId}
                          direction={
                            col.id === sortModel?.columnId
                              ? sortModel.order
                              : "asc"
                          }
                          onClick={() => {
                            const isAsc =
                              sortModel?.columnId === col.id &&
                              sortModel?.order === "asc";
                            setSortModel({
                              columnId: col.id,
                              order: isAsc ? "desc" : "asc",
                            });
                          }}
                        >
                          {col.label}
                        </TableSortLabel>
                      ) : (
                        col.label
                      )}
                    </TableCell>
                  );
                })}
              </>
            </TableHead>

            <TableBody>
              <>
                {data.data?.map((row) => {
                  return (
                    <TableRow
                      key={row.meetingDefinitionId}
                      tabIndex={-1}
                      sx={{ height: "54px" }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography display="flex" sx={{ fontSize: "14px" }}>
                          {row.inviteStyle === "kronologicInvite" && (
                            <CalendarMonthIcon width={20} height={16} />
                          )}
                          {row.inviteStyle === "kronologicEmail" && (
                            <MailOutlineIcon width={20} height={16} />
                          )}
                          {row.inviteStyle === "kronologicLink" && (
                            <LinkIcon width={20} height={16} />
                          )}
                          &nbsp;&nbsp;{row.campaign}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Typography
                          component="span"
                          sx={{ minWidth: "40px", textAlign: "right" }}
                        >
                          {Math.round(row.aggregateData.acceptRate * 100)}% |{" "}
                          {row.aggregateData.acceptCount}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Typography
                          component="span"
                          sx={{ minWidth: "40px", textAlign: "right" }}
                        >
                          {Math.round(row.aggregateData.declineRate * 100)}% |{" "}
                          {row.aggregateData.declineCount}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Typography
                          component="span"
                          sx={{ minWidth: "40px", textAlign: "right" }}
                        >
                          {Math.round(row.aggregateData.responseRate * 100)}% |{" "}
                          {row.aggregateData.responseCount}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.team}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.aggregateData.meetingsCount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            </TableBody>
          </MuiTable>
        </Box>
      </Card>
    </>
  );
}

export default ReportingCampaignTable;
