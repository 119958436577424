export const FileDownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <mask
      id="a"
      width={28}
      height={28}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h28v28H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        d="M7 23.333c-.642 0-1.19-.228-1.647-.684A2.248 2.248 0 0 1 4.667 21v-3.5H7V21h14v-3.5h2.333V21a2.25 2.25 0 0 1-.684 1.648 2.25 2.25 0 0 1-1.649.685H7Zm7-4.666-5.833-5.834L9.8 11.142l3.033 3.033V4.667h2.334v9.508l3.033-3.033 1.633 1.691L14 18.667Z"
      />
    </g>
  </svg>
);
