import React, { useMemo } from "react";
import moment from "moment";
import Flexbox from "../../../components/flexbox";
import { TableCell } from "../../../components/tableV5/GridTable";
import { P1 } from "../../../components/typography";
import { useTimezones } from "../../../components/timezone";
import style from "./tableCells.module.scss";
import { NO_QUORUM_STAGE } from "../../props";

const StartTime = ({ meeting }) => {
  const { browserTimezone } = useTimezones();

  const startTime = useMemo(() => {
    if (!meeting || meeting.status === NO_QUORUM_STAGE) {
      return null;
    }

    return meeting.startTime;
  }, [meeting]);

  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell className={style.tableCells}>
          <Column>
            {!startTime && <P1>&mdash;</P1>}
            {startTime && (
              <Column>
                <Column height="70%">
                  <P1 bold>
                    {`${moment(startTime).format("LT")} ${browserTimezone}`}
                  </P1>

                  <P1 style={{ marginTop: 8 }}>
                    {moment(startTime).format("dddd")}
                  </P1>

                  <P1 style={{ marginTop: 8 }}>
                    {moment(startTime).format("M/D/YYYY")}
                  </P1>
                </Column>

                <Column height="30%">
                  <P1>{moment(startTime).fromNow()}</P1>
                </Column>
              </Column>
            )}
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
};

export default StartTime;
