import React from "react";
import { useIsInitializingPhase } from "../../useIsInitializingPhase";
import { INITIALIZING_STAGE } from "../../props";
import { IsInitializingPhase, TableRowMeetingContext } from "../../context";
import {
  ActivateMeeting,
  Cost,
  EmptyCell,
  MeetingType,
  SaveMeeting,
  Tags,
} from "../newTableCells";
import Attendees from "../attendees";

import style from "./newMeetingTableRow.module.scss";

function NewMeetingTableRow({ onClose, onCreate }) {
  return (
    <TableRowMeetingContext.Provider
      value={{
        guests: null,
        host: null,
        meetingType: null,
        tags: [],
      }}
    >
      <IsInitializingPhase.Provider
        value={useIsInitializingPhase(INITIALIZING_STAGE)}
      >
        <div className={style.newMeetingRow__container}>
          <SaveMeeting closeNewInstanceMenu={onClose} />
          <ActivateMeeting />
          <EmptyCell />
          <MeetingType
            setMeetingTypeAndSave={(meetingType) => onCreate({ meetingType })}
          />
          <Attendees
            setHost={(host) => onCreate({ host })}
            setGuest={(guest) => onCreate({ guest })}
          />
          <EmptyCell />
          <Tags setTags={undefined} />
          <Cost />
        </div>
      </IsInitializingPhase.Provider>
    </TableRowMeetingContext.Provider>
  );
}

export default NewMeetingTableRow;
