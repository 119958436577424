import moment from "moment";

export function useDatePicker() {
  const onDatesChange = function (start, end) {
    const dates = {
      end: null,
      start: null,
    };

    if (start) {
      dates.start = start.startOf("day");
    }

    if (end) {
      dates.end = end.endOf("day");
    }

    return dates;
  };

  const getDateRangeWithKey = function (key) {
    let start = null;
    let end = null;
    switch (key) {
      case "custom":
      case "all_time":
        break;
      case "past":
        start = moment(0);
        end = moment().subtract("1", "minute");
        break;
      case "future":
        start = moment().add("1", "minute");
        end = moment().add("1", "year");
        break;
      // year
      case "this_year":
        start = moment().startOf("year");
        end = moment().endOf("year");
        break;
      case "next_year":
        start = moment().startOf("year").add("1", "year");
        end = moment().endOf("year").add("1", "year");
        break;
      case "last_year":
        start = moment().startOf("year").subtract("1", "year");
        end = moment().endOf("year").subtract("1", "year");
        break;
      case "year_to_date":
        start = moment().startOf("year");
        end = moment().endOf("day");
        break;
      // month
      case "this_month":
        start = moment().startOf("month");
        end = moment().endOf("month");
        break;
      case "next_month":
        start = moment().startOf("month").add("1", "month");
        end = moment().endOf("month").add("1", "month");
        break;
      case "last_month":
        start = moment().startOf("month").subtract("1", "month");
        end = moment(start).endOf("month");
        break;
      case "month_to_date":
        start = moment().startOf("month");
        end = moment().endOf("day");
        break;
      // quarter
      case "this_quarter":
        start = moment().startOf("quarter");
        end = moment().endOf("quarter");
        break;
      case "next_quarter":
        start = moment().add("1", "quarter").startOf("quarter");
        end = moment(start).endOf("quarter");
        break;
      case "last_quarter":
        start = moment().subtract("1", "quarter").startOf("quarter");
        end = moment(start).endOf("quarter");
        break;
      case "quarter_to_date":
        start = moment().startOf("quarter");
        end = moment().endOf("day");
        break;
      // week
      case "this_week":
        start = moment().startOf("week");
        end = moment().endOf("week");
        break;
      case "next_week":
        start = moment().startOf("week").add("1", "week");
        end = moment().endOf("week").add("1", "week");
        break;
      case "last_week":
        start = moment().startOf("week").subtract("1", "week");
        end = moment().endOf("week").subtract("1", "week");
        break;
      case "week_to_date":
        start = moment().startOf("week");
        end = moment().endOf("day");
        break;
      // day
      case "today":
        start = moment().startOf("day");
        end = moment().endOf("day");
        break;
      case "tomorrow":
        start = moment().startOf("day").add("1", "day");
        end = moment().endOf("day").add("1", "day");
        break;
      case "yesterday":
        start = moment().startOf("day").subtract("1", "day");
        end = moment().endOf("day").subtract("1", "day");
        break;
      case "last_24_hours":
        start = moment().startOf("day").subtract("1", "day");
        end = moment(new Date());
        break;
      default:
        break;
    }
    return { end, start };
  };

  return { getDateRangeWithKey, onDatesChange };
}
