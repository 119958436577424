import { useCallback } from "react";
import { useSearchUpdater } from "../../Search";

const useMeetingStatusFilterOnClick = (filters) => {
  const searchUpdater = useSearchUpdater();
  return useCallback(() => {
    searchUpdater.setMeetingStatusFilter(filters);
  }, [searchUpdater, filters]);
};

export default useMeetingStatusFilterOnClick;
