import React, { useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import { P1 } from "../../../components/typography";
import {
  INITIALIZING_READY_TO_ACTIVATE_STAGE,
  INITIALIZING_STAGE,
  toInstanceStageLabel,
} from "../../props";

function MeetingStatusRow({ status }) {
  const statusLabel = useMemo(() => toInstanceStageLabel(status), [status]);

  const color = useMemo(() => {
    if (
      status === INITIALIZING_STAGE ||
      status === INITIALIZING_READY_TO_ACTIVATE_STAGE
    ) {
      return "#000";
    }
    return "#FFF";
  }, [status]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row justifyContent="flex-start" style={{ padding: "5px 0 5px 15px" }}>
          <P1 bold style={{ color }}>
            {statusLabel}
          </P1>
        </Row>
      )}
    </Flexbox>
  );
}

export default MeetingStatusRow;
