import React from "react";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";

import { IntegrationButton } from "./integrationButton";

export function ConnectButton({ name, onClick, disabled }) {
  return (
    <IntegrationButton name={name} onClick={onClick} disabled={disabled}>
      <Icon size={1} path={mdiPlus} />
      Connect
    </IntegrationButton>
  );
}
