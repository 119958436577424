import React, { useEffect, useState } from "react";
import Log from "../log";
import Flexbox from "../../../components/flexbox";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import useLogsRepository from "../../../repository/logs";
import { P1 } from "../../../components/typography";
import { useMeeting } from "../state";
import style from "./instanceLogs.module.scss";

const InstanceLogs = () => {
  const [logs, setLogs] = useState([]);
  const [meeting] = useMeeting();
  const { getLogsByMeetingId } = useLogsRepository();
  const { addError } = useGeneralNotifications();
  const meetingId = meeting?.id || null;

  // Logs - This runs everytime Meetings Polling runs (desired)
  useEffect(() => {
    (async () => {
      if (meetingId !== null) {
        try {
          const { data } = await getLogsByMeetingId(meetingId);
          setLogs(data.data);
        } catch (error) {
          addError("There was an issue fetching the logs for this Meeting");
        }
      }
    })();
  }, [addError, getLogsByMeetingId, meetingId]);

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <Column className={style.systemNotes}>
          <Row
            className={style.systemNotes__header}
            height="15%"
            justifyContent="start"
          >
            <P1 bold>Meeting Logs</P1>
          </Row>

          <Column className={style.logs} justifyContent="unset">
            {logs.map((log) => (
              <Log key={log.timestamp} log={log} />
            ))}
          </Column>
        </Column>
      )}
    </Flexbox>
  );
};

export default InstanceLogs;
