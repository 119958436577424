import React from "react";

function NoResponseInfo() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.666748"
        width="30"
        height="30"
        rx="15"
        fill="#FF9900"
        fillOpacity="0.1"
      />
      <path
        d="M14.8335 10.8333H16.5002V12.5H14.8335V10.8333ZM14.8335 14.1667H16.5002V19.1667H14.8335V14.1667ZM15.6668 6.66666C11.0668 6.66666 7.3335 10.4 7.3335 15C7.3335 19.6 11.0668 23.3333 15.6668 23.3333C20.2668 23.3333 24.0002 19.6 24.0002 15C24.0002 10.4 20.2668 6.66666 15.6668 6.66666ZM15.6668 21.6667C11.9918 21.6667 9.00016 18.675 9.00016 15C9.00016 11.325 11.9918 8.33333 15.6668 8.33333C19.3418 8.33333 22.3335 11.325 22.3335 15C22.3335 18.675 19.3418 21.6667 15.6668 21.6667Z"
        fill="#FF9800"
      />
    </svg>
  );
}

export default NoResponseInfo;
