import React from "react";

import Flexbox from "../../../components/flexbox/FlexboxV2";
import List from "../../../components/list";
import GuestHubGuestListGuest from "../guestHubGuestListGuest";
import { useMeeting } from "../state";

import style from "./guestList.module.scss";

const GuestHubGuestList = () => {
  const [meeting] = useMeeting();
  const { guests = null } = meeting;

  return (
    <Flexbox.Column
      alignItems="flex-start"
      className={style.guestList}
      height="100%"
      justifyContent="flex-start"
    >
      {Array.isArray(guests) && guests.length !== 0 && (
        <List list={guests} wrapperHeight="fit-content">
          {(guest = { id: null }, i) => (
            <GuestHubGuestListGuest key={guest?.id} guest={guest} index={i} />
          )}
        </List>
      )}
      {(!Array.isArray(guests) || guests.length === 0) && (
        <GuestHubGuestListGuest index={0} />
      )}
    </Flexbox.Column>
  );
};

export default GuestHubGuestList;
