export const DefaultUserRole = 0;
export const TeamAdminRole = 1;
export const OrgAdminRole = 2;
export const SuperAdminRole = 3;
export const SuperAdminRORole = 4;

// The const definitions above will preserve the literal type. For instance
// 'typeof DefaultUserRole' will be 1 and not number. This is good to know for
// the union below. Though this looks weird and I am personally not a fan due to
// low readability the type below will result in a subset of valid numbers and
// will still allow the more readable consts above to be used for assignment.
export type Role =
  | typeof DefaultUserRole
  | typeof TeamAdminRole
  | typeof OrgAdminRole
  | typeof SuperAdminRole
  | typeof SuperAdminRORole;

export interface RoleOption {
  label: string;
  value: Role;
}

export const RoleOptions: RoleOption[] = [
  { label: "Default", value: DefaultUserRole },
  { label: "Team Admin", value: TeamAdminRole },
  { label: "Org Admin", value: OrgAdminRole },
];

export function roleToString(role: Role) {
  switch (role) {
    default:
      return "Unknown";
    case DefaultUserRole:
      return "Default User";
    case TeamAdminRole:
      return "Team Admin";
    case OrgAdminRole:
      return "Org Admin";
    case SuperAdminRole:
      return "Super Admin";
    case SuperAdminRORole:
      return "Super Admin Read Only";
  }
}
