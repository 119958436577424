import React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";

export const FilterButton = React.forwardRef<
  HTMLButtonElement | null,
  {
    children: React.ReactNode | React.ReactNode[];
    onClick: () => void;
    active?: boolean;
    dropdown?: boolean;
  }
>(function FilterButtonWithRef(props, ref) {
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      ref={ref}
      onClick={props.onClick}
      endIcon={props.dropdown && <ArrowDropDown />}
      sx={{
        minhHeight: "42px",
        height: "fit-content",
        borderColor: props.active
          ? theme.palette.primary.main
          : theme.palette.divider,
        backgroundColor: props.active
          ? theme.palette.primary.main + "0A"
          : undefined,
        textTransform: "none",
      }}
    >
      {props.children}
    </Button>
  );
});

export default FilterButton;
