import React from "react";
import { Checkbox, CheckboxProps, Stack } from "@mui/material";

function DayCheckbox({
  checked,
  children,
  onChange,
}: {
  checked: boolean;
  children: React.ReactNode;
  onChange: CheckboxProps["onChange"];
}) {
  return (
    <Checkbox
      disableRipple
      checked={checked}
      onChange={onChange}
      icon={
        <Stack
          sx={{
            borderRadius: 9999,
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            backgroundColor: "#f2f2f2",
          }}
        >
          {children}
        </Stack>
      }
      checkedIcon={
        <Stack
          sx={{
            borderRadius: 9999,
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          {children}
        </Stack>
      }
    />
  );
}

export default DayCheckbox;
