import { useLogoUrl } from "../utils/logoUrl";

function OneClickLogoImage() {
  const [logoUrl] = useLogoUrl();

  return (
    <img
      src={logoUrl}
      alt=""
      style={{
        height: "150px",
        width: "150px",
        objectFit: "contain",
      }}
    />
  );
}

export default OneClickLogoImage;
