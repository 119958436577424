import React, { useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import GuestIcons from "../guestIcons";
import GuestInput from "../guestInput";

function Guest({ guest, setGuest, guestIndex }) {
  const width = useMemo(() => {
    if (guest === null) {
      return "100%";
    }
    return "41%";
  }, [guest]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row width={width} justifyContent="flex-start">
          {guestIndex === 0 && (
            <>
              <GuestIcons guest={guest} />

              <GuestInput guest={guest} setGuest={setGuest} />
            </>
          )}
        </Row>
      )}
    </Flexbox>
  );
}

export default Guest;
