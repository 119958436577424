import { useMemo } from "react";

import { BLACK, NEUTRAL_500, PRIMARY_BLUE, WHITE } from "../colors";
import { useTheme } from "../theme";
import { ClearIndicator } from "./ClearIndicator";
import { DropdownIndicator } from "./DropdownIndicator";

const LARGE = "large";
const MEDIUM = "medium";
const SMALL = "small";

function getMinHeight(size) {
  switch (size) {
    case LARGE:
      return "40px";
    case MEDIUM:
      return "32px";
    case SMALL:
      return "22px";
    default:
      return "40px";
  }
}

export function useDefaultSelectTheme({ size = LARGE } = {}) {
  const { useThemeShadows } = useTheme();
  const { MD } = useThemeShadows();
  return useMemo(() => {
    return [
      { ClearIndicator, DropdownIndicator },
      {
        container: (base, { isDisabled }) => ({
          ...base,
          border: `1px solid ${NEUTRAL_500}`,
          backgroundColor: WHITE,
          borderRadius: "4px",
          minWidth: "225px",
          opacity: isDisabled ? "0.4" : null,
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          backgroundColor: WHITE,
          border: 0,
          borderRadius: "4px",
          minHeight: getMinHeight(size),
        }),
        indicatorSeparator: (base) => ({
          ...base,
          marginBottom: "10px",
          marginTop: "10px",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: null,
          boxShadow: MD,
          marginTop: "10px",
        }),
        menuList: (base) => ({
          ...base,
          backgroundColor: WHITE,
          borderRadius: "4px",
          boxShadow: "none",
          padding: "0px",
          width: "100%",
        }),
        option: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isFocused ? PRIMARY_BLUE : WHITE,
          color: isFocused ? WHITE : BLACK,
          cursor: "pointer",
          overflow: "hidden",
          padding: "7px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
        placeholder: (base) => ({
          ...base,
          color: NEUTRAL_500,
        }),
        singleValue: (base) => ({
          ...base,
          color: BLACK,
          maxWidth: "100%",
          padding: "0 12px",
        }),
        valueContainer: (base) => ({
          ...base,
          height: "100%",
          justifyContent: "center",
          padding: "0px",
        }),
      },
    ];
  }, [MD, size]);
}
