import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { errorHandler } from "src/hooks/errorHandler";
import { useUserService } from "../services";

export function useDeleteChannel() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (id: number): Promise<void> =>
    service
      // ################################
      // FIXME: fix route to be /api/user/channels/{channelType}/{id}
      // ################################
      .delete(`/api/user/channels/import/${id}`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: Response) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate channel queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/user/channels"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}
