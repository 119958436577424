import React from "react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 5,
            height: "32px",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            zIndex: 10000,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            alignItems: "center",
            display: "flex",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: ({ theme }) => ({
            "&.Mui-expanded": {
              color: theme.palette.primary.main,
            },
          }),
        },
      },
    },
    palette: {
      primary: {
        main: "#2BAEF9",
        light: "#8ED4FC",
        dark: "#014A74",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#079504",
        light: "#1FCBB6",
        dark: "#1AA573",
      },
      background: {
        default: "#rgb(250,250,250)",
        paper: "#FFFFFF",
      },
      text: {
        primary: "#3C4C5C",
        secondary: "#63707C",
        disabled: "#aeaeae",
      },
      error: {
        main: "#FF0000",
      },
      warning: {
        main: "#FFBB00",
      },
      divider: "rgba(0, 0, 0, 0.12)",
      success: {
        main: "#079504",
      },
      info: {
        main: "#2BAEF9",
      },
    },
    spacing: 8,
  });

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
