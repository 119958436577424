import { getUserToken } from "src/utils/jwtToken";
import { useSWRConfig } from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useEnablePAT() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const override = useActingAsOverrideHeader();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (id: number): Promise<void> =>
    service
      .post(`/api/pat/${id}/enable`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: ReadableStream<Uint8Array> | null) => {
        // @ts-ignore - the type for `cache` does not explicitly define `keys`
        const keys: IterableIterator<string> = cache.keys();

        // invalidate PAT queries and force refresh
        Array.from(keys)
          .filter((route: string) => route.includes("/api/pat"))
          .forEach((route: string) => {
            mutate(route);
          });
        return res;
      })
      .catch(errorHandler);
}
