import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import ImportFooter from "../ImportFooter";
import { UserUpload } from "./UserUpload";

export function Confirmation({
  users = [],
  onPrev = () => null,
  onNext = () => null,
}: {
  users: UserUpload;
  onPrev?: () => void;
  onNext?: () => void;
}) {
  return (
    <>
      <Box sx={{ width: "100%", minHeight: "400px" }}>
        <Stack alignItems="center">
          <Typography align="center">
            Let's make sure everything looks right
          </Typography>
          <Typography align="center">
            You will be inviting {users.length}{" "}
            {`${
              users.length === 1 ? "user" : "users"
            } to the Kronologic platform.`}
          </Typography>
        </Stack>
      </Box>
      <ImportFooter onLeftButtonClick={onPrev} onRightButtonClick={onNext} />
    </>
  );
}

export default Confirmation;
