import React from "react";
import { mdiClose } from "@mdi/js";
import { ButtonContainer, ButtonIcon } from "../../../components/button";
import style from "./close.module.scss";

const Close = ({ close }) => {
  return (
    <ButtonContainer
      className={style.close}
      name="/instanceDetails/close"
      onClick={close}
    >
      <ButtonIcon className={style.close__icon} path={mdiClose} />
    </ButtonContainer>
  );
};

export default Close;
