import { MeetingDefinition } from "src/types";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useCloneMeetingTemplate() {
  const accessToken = getUserToken();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return async (id: number): Promise<MeetingDefinition> => {
    return await service
      .post(`/api/meetings/definition/${id}/clone`)
      .set(headers)
      .send()
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler);
  };
}
