import React from "react";
import cx from "classnames";
import Icon from "@mdi/react";
import style from "./style.module.scss";

export function EmptyViewIcon({ icon, size = 10, ...props }) {
  return (
    <Icon
      path={icon}
      size={size}
      className={style.emptyView__icon}
      {...props}
    />
  );
}

export function EmptyViewContainer({
  className,
  children,
  style: emptyViewStyle,
}) {
  return (
    <div
      className={cx(style.emptyView__container, className)}
      style={emptyViewStyle}
    >
      {children}
    </div>
  );
}

export function EmptyViewRow({ children }) {
  return <div>{children}</div>;
}
