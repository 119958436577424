import React from "react";
import cx from "classnames";
import style from "./style.module.scss";

export function TagManagerDrawer({ children, slideClassName }) {
  return (
    <div className={cx(style.tagManager__drawer, slideClassName)}>
      {children}
    </div>
  );
}
