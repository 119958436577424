import React, { useEffect, useState } from "react";
import Flexbox from "../../../components/flexbox";
import GuestRow from "../guestRow";
import style from "./guests.module.scss";

function Guests({ disabled, guests, setGuest }) {
  const [renderableGuests, setRenderableGuests] = useState([]);

  useEffect(() => {
    const guestsLength = guests.length;

    if (guestsLength < 4) {
      setRenderableGuests([
        ...guests,
        ...new Array(3 - guestsLength).fill(null),
      ]);
      return;
    }
    setRenderableGuests(guests);
  }, [guests]);

  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.guests} justifyContent="flex-start">
          {renderableGuests.map((guest, index) => (
            <GuestRow
              key={`${guest?.id}-${index + 1}`}
              disabled={disabled}
              guest={guest}
              setGuest={setGuest}
              guestIndex={index}
            />
          ))}
        </Column>
      )}
    </Flexbox>
  );
}

export default Guests;
