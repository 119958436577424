import React, { useMemo } from "react";
import { INTEGRATION_TYPES_LABELS } from "../integrations/props";
import { STAGING_STATUS, toGuestStatusLabel } from "../meetings/props";
import { UnsubscribedChip } from "../meetings/guestSelect/UnsubscribedChip";

function businessObjectToOptions(businessObjects) {
  const { businessObjectToOption } = this;
  try {
    if (businessObjects === null) {
      return [];
    }
    if (Array.isArray(businessObjects)) {
      return businessObjects.map(businessObjectToOption);
    }
    {
      // Could just be a single object that came in
      const businessObject = businessObjects;
      return businessObjectToOption(businessObject);
    }
  } catch (error) {
    return [];
  }
}

function integrationToOption(integration) {
  if (integration.name in INTEGRATION_TYPES_LABELS) {
    return {
      label: INTEGRATION_TYPES_LABELS[integration.name],
      value: integration,
    };
  }

  return {
    label: integration.name,
    value: integration,
  };
}

function tagToOption(tag) {
  return {
    label: tag.name,
    value: tag,
  };
}

export function guestToOption(guest) {
  if (guest === undefined || guest === null) {
    return null;
  }

  return {
    disabled: guest.unsubscribed,
    label: guest.unsubscribed ? (
      <>
        {`${guest.email} `}
        <UnsubscribedChip />
      </>
    ) : (
      guest.email
    ),

    value: {
      email: guest.email,
      firstName: guest.firstName,
      id: guest.id,
      lastName: guest.lastName,
    },
  };
}

export const guestStatusToOption = (status) => {
  if (status === undefined || status === null) {
    return STAGING_STATUS;
  }

  return {
    label: toGuestStatusLabel(status),
    value: status,
  };
};

export function meetingTypeToOption(meetingType) {
  if (
    meetingType === undefined ||
    meetingType === null ||
    meetingType.id === null
  ) {
    return null;
  }

  return {
    label: meetingType.name,
    value: meetingType,
  };
}

export function hostToOption(host) {
  if (host === undefined || host === null) {
    return null;
  }

  return {
    label: host.email,
    value: {
      email: host.email,
      firstName: host.firstName,
      id: host.id,
      lastName: host.lastName,
    },
  };
}

const integrationsToOptions = businessObjectToOptions.bind({
  businessObjectToOption: integrationToOption,
});

export const tagsToOptions = businessObjectToOptions.bind({
  businessObjectToOption: tagToOption,
});

export const guestsToOptions = businessObjectToOptions.bind({
  businessObjectToOption: guestToOption,
});

export const meetingTypesToOptions = businessObjectToOptions.bind({
  businessObjectToOption: meetingTypeToOption,
});

export const hostsToOptions = businessObjectToOptions.bind({
  businessObjectToOption: hostToOption,
});

export function useMeetingTypeOptions(meetingTypes) {
  return useMemo(() => {
    return meetingTypesToOptions(meetingTypes);
  }, [meetingTypes]);
}

export function useIntegrationOptions(integrations) {
  return useMemo(() => {
    return integrationsToOptions(integrations);
  }, [integrations]);
}
