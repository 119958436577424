import React, { useEffect, useState } from "react";
import cx from "classnames";
import style from "./dropdown.module.scss";

const Dropdown = ({
  id,
  className,
  dataCy = null,
  dataGtm = null,
  disabled,
  data,
  name = null,
  onSelect,
  selectItem,
  valueProp,
  labelProp,
  label,
}) => {
  const [selected, setSelected] = useState(selectItem);
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  useEffect(() => {
    setSelected(selectItem);
    return () => {
      setSelected(null);
    };
  }, [selectItem]);

  const onChange = (event) => {
    const {
      currentTarget: { selectedIndex },
    } = event;

    const index = label ? selectedIndex - 1 : selectedIndex;

    if (label && index < 0) {
      setSelected("");
    } else {
      setSelected(data[index]);
    }

    onSelect(data[index]);
  };

  return (
    <select
      aria-label={name}
      name={name}
      onChange={onChange}
      value={(selected && selected !== "" && selected[valueProp]) || selected}
      className={cx(style.dropdown, { [style.disabled]: disabled }, className)}
      disabled={disabled}
      {...dataProps}
    >
      {label && <option value="">{label}</option>}
      {data.map((option) => {
        return (
          <option
            disabled={option.disabled}
            key={`option-${option[labelProp] || option[valueProp]} ${id || ""}`}
            value={option[valueProp]}
          >
            {option[labelProp]}
          </option>
        );
      })}
    </select>
  );
};

export default Dropdown;
