import { mdiAccountOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Grid } from "@mui/material";
import { ActionButton } from "src/componentsV2/ActionButton";
import { PageLayout } from "src/componentsV2/PageLayout";

function KMeImport() {
  return (
    <PageLayout
      title="Import"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Import", route: "/reports/import" },
      ]}
    >
      <Grid
        container
        sx={{ justifyContent: "center", alignItems: "center", gap: 4 }}
      >
        <Grid item>
          <ActionButton
            title="Contacts"
            icon={<Icon path={mdiAccountOutline} size={3} />}
            to="/reports/import/contacts"
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default KMeImport;
