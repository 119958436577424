import React from "react";

import { Role } from "../types";
import { useActingAs } from "../auth";
import ErrorPage from "src/pages/ErrorPage";
import { getUserDetails } from "src/utils/jwtToken";

export function RequireRole({
  permitted,
  children,
}: {
  permitted: Role[];
  children: JSX.Element;
}) {
  const user = getUserDetails();
  const [actingAs] = useActingAs();

  if (
    user &&
    permitted.includes((actingAs ? actingAs.role : user.role) as Role)
  ) {
    return children;
  }

  return <ErrorPage status={404} />;
}
