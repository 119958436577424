import { Stack } from "@mui/material";
import React, { useState } from "react";
import { Team } from "src/types";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { TeamSelectInput } from "../inputs";

import Dialog from "./Dialog";

export function AddToTeamDialog({
  open,
  users,
  onSubmit,
  onClose,
}: {
  open: boolean;
  users: number[];
  onSubmit: (users: number[], team: Team) => void;
  onClose: () => void;
}) {
  const [team, setTeam] = useState<Team | null>(null);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Add To Team"
      actions={
        <>
          <PrimaryButton
            disabled={team === null}
            onClick={() => {
              if (team) {
                onSubmit(users, team);
              }
            }}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ mt: 1, minHeight: 400 }}>
        <TeamSelectInput value={team} onChange={setTeam} />
      </Stack>
    </Dialog>
  );
}

export default AddToTeamDialog;
