import { useRef, useState } from "react";
import {
  List,
  Checkbox,
  ListItemButton,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import FilterButton from "./FilterButton";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

export function FilterSelect<T>(props: {
  label?: string;
  options?: { label: string; value: T }[];
  getOptionId?: (option: T) => string | number;
  getOptionLabel?: (option: T) => string | React.ReactNode;
  getOptionDisabled?: (option: T) => boolean;
  isOptionEqualToValue?: (option: T, value?: T | null) => boolean;
  value?: T | null;
  onChange?: (value: T | null) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const options = props.options || [];

  const compareFn =
    props.isOptionEqualToValue ||
    function (option, value) {
      if (value === undefined || value === null) {
        return false;
      }
      return props.getOptionId
        ? props.getOptionId(option) === props.getOptionId(value)
        : option === value;
    };

  return (
    <div>
      <FilterButton
        dropdown
        ref={buttonRef}
        onClick={() => {
          setOpen(true);
        }}
        active={props.value !== null}
      >
        {props.label}
      </FilterButton>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper>
          <List sx={{ width: "300px" }}>
            {options.map((option) => {
              const key: any = props.getOptionId
                ? props.getOptionId(option.value)
                : option.value;

              return (
                <ListItemButton
                  key={key}
                  selected={compareFn(option.value, props.value)}
                  onClick={() => {
                    if (props.onChange) {
                      if (compareFn(option.value, props.value)) {
                        props.onChange(null);
                      } else {
                        props.onChange(option.value);
                      }
                    }
                  }}
                >
                  <Checkbox
                    size="small"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={compareFn(option.value, props.value)}
                  />
                  <Typography>{option.label}</Typography>
                </ListItemButton>
              );
            })}
          </List>
        </Paper>
      </Popover>
    </div>
  );
}

export default FilterSelect;
