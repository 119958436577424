import React from "react";

import { Input } from "../../components/input";
import { EnvironmentURLInformation } from "./EnvironmentURLInformation";
import { HelpGuide } from "./HelpGuide";
import style from "./style.module.scss";

export function EnvironmentURLInput({ value, onChange, testURL }) {
  return (
    <div className="row">
      <Input
        className={style.env_url_input}
        placeholder="Environment URL"
        type="text"
        name="environmentUrl"
        onChange={onChange}
        value={value}
      />
      <HelpGuide>
        <EnvironmentURLInformation url={testURL} />
      </HelpGuide>
    </div>
  );
}
