import React from "react";
import { useTheme } from "@mui/material";
import Confirmation from "./Confirmation";
import { CheckCircle } from "@mui/icons-material";

export function Accepted({ onOkClicked }: { onOkClicked: () => void }) {
  const theme = useTheme();
  return (
    <Confirmation
      title="Congratulations"
      message="Your meeting has been confirmed. You will receive a calendar invite shortly."
      caption=""
      icon={
        <CheckCircle
          sx={{
            color: theme.palette.success.main,
            height: "5em",
            width: "5em",
          }}
        />
      }
      onOkClicked={onOkClicked}
    />
  );
}
