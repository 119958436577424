import React from "react";
import parse, { Element } from "html-react-parser";
import DOMPurify from "dompurify";
import { Tooltip, useTheme } from "@mui/material";
import "./TipTap/styles.scss";

function DynamicVar({ children }: { children?: string }) {
  const theme = useTheme();
  return (
    <Tooltip title="Dynamic variable" arrow>
      <span
        style={{
          color: theme.palette.text.disabled,
          fontWeight: "bold",
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
}

function RichText({ content }: { content?: string | null }) {
  return content ? (
    <div
      style={{
        lineBreak: "normal",
        overflowWrap: "break-word",
        listStyle: "inside",
      }}
    >
      {parse(DOMPurify.sanitize(content), {
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.attribs) {
            if (domNode.attribs?.class?.includes("dynamic-var")) {
              return (
                <DynamicVar>{(domNode.children[0] as any).data}</DynamicVar>
              );
            }
          }
        },
      })}
    </div>
  ) : (
    <></>
  );
}

export function TipTapPreview({ content }: { content?: string | null }) {
  return content ? (
    <div className="ProseMirror preview">
      {parse(DOMPurify.sanitize(content), {
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.attribs) {
            if (domNode.attribs?.class?.includes("dynamic-var")) {
              return (
                <DynamicVar>{(domNode.children[0] as any).data}</DynamicVar>
              );
            }
          }
        },
      })}
    </div>
  ) : (
    <></>
  );
}

export default RichText;
