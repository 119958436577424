export const DELETE = "DELETE";
export const PATCH = "PATCH";
export const POST = "POST";
export const HTTP_METHODS = {
  DELETE: "DELETE",
  GET: "GET",
  PATCH: "PATCH",
  POST: "POST",
  PUT: "PUT",
};
