import React from "react";

import { formatWordForPossession } from "./util";
import style from "./globallogs.module.scss";

const buildUserCalendarSettingsChangedLog = function* (log) {
  const {
    structuredData: {
      userEmail,
      calendarName,
      calendarUseForAvailability,
      calendarPublicUse,
    },
  } = log;

  if (calendarPublicUse !== undefined) {
    yield (
      <span>
        Calendar
        {` ${calendarName} `}
        {` ${
          calendarPublicUse ? "was made public" : "was removed from public use"
        } `}
      </span>
    );
  }

  if (calendarUseForAvailability !== undefined) {
    yield (
      <span>
        Calendar
        {` ${calendarName} `}
        {` ${
          calendarUseForAvailability ? " was applied to " : " was removed from "
        } `}
        <span className={style.logBuilder__userAttributeChange}>
          {`${formatWordForPossession(userEmail)} `}
        </span>
        availability
      </span>
    );
  }
};

export { buildUserCalendarSettingsChangedLog as default };
