import React, { useMemo } from "react";
import dayjs from "dayjs";
import Flexbox from "../../../components/flexbox";
import { TableCell } from "../../../components/tableV5/GridTable";
import { Caption } from "../../../components/typography";
import { cleanNote } from "../../instanceDetails/util/helpers";
import style from "./tableCells.module.scss";

const LastActivity = ({ meeting }) => {
  const formattedLastActivity = useMemo(() => {
    const { lastActivity } = meeting;
    if (lastActivity) {
      return dayjs(lastActivity).format("h:mma M/D/YYYY");
    }
    return "";
  }, [meeting]);

  const systemNotes = useMemo(() => {
    const { mostRecentSysNote } = meeting;
    if (!mostRecentSysNote || mostRecentSysNote.length < 1) {
      return "";
    }

    // sanity check, the note must be two chars long in order for the below to run
    if (mostRecentSysNote.length < 2) {
      return mostRecentSysNote;
    }

    const cleaned = cleanNote(mostRecentSysNote);
    return `${cleaned.slice(0, 1).toUpperCase()}${cleaned.slice(1)}`;
  }, [meeting]);

  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell className={style.tableCells}>
          <Column className={style.lastActivity}>
            <Caption className={style.lastActivity__text}>
              {formattedLastActivity}
            </Caption>
            <div className={style.lastActivity__systemNotes}>
              <Caption extraSmall>{systemNotes}</Caption>
            </div>
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
};

export default LastActivity;
