import { TokenStatus, TokenStatusOptions } from "src/types";
import FilterDropdown from "./FilterDropdown";

export function MeetingLinkConnectionFilter(props: {
  value?: TokenStatus[];
  onChange?: (value: TokenStatus[]) => void;
}) {
  return (
    <FilterDropdown
      label="Meeting Link Connection"
      value={props.value}
      onChange={props.onChange}
      options={TokenStatusOptions}
    />
  );
}

export default MeetingLinkConnectionFilter;
