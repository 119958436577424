import { TemplateStatus, TemplateStatusOptions } from "src/types";
import FilterSelect from "./FilterSelect";

export function TemplateStatusFilter(props: {
  value?: TemplateStatus | null;
  onChange?: (value: TemplateStatus | null) => void;
}) {
  return (
    <FilterSelect
      label="Template Status"
      value={props.value}
      onChange={props.onChange}
      options={TemplateStatusOptions}
    />
  );
}

export default TemplateStatusFilter;
