import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { North, South } from "@mui/icons-material";

import { MetricCard } from "./MetricsCard";

export function SingleValue({
  title,
  help,
  trend,
  children,
}: {
  title: string;
  help?: string;
  trend?: "up" | "down";
  children?: number | string;
}) {
  const theme = useTheme();
  return (
    <MetricCard title={title} help={help}>
      <Stack
        sx={{ height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2" align="center">
          {trend ? (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                gap: theme.spacing(1),
              }}
            >
              {children}
              {trend === "up" ? (
                <North sx={{ height: "36px", width: "36px" }} />
              ) : (
                <South sx={{ height: "36px", width: "36px" }} />
              )}
            </Box>
          ) : (
            children
          )}
        </Typography>
      </Stack>
    </MetricCard>
  );
}
