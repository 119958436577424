import React, { useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import { P1 } from "../../../components/typography";
import { useIsInitializingPhase } from "../../context";
import GuestSelect from "../../guestSelect";

const GuestInput = ({ guest, setGuest }) => {
  const isInitializingPhase = useIsInitializingPhase();

  const guestEmail = useMemo(() => {
    if (guest === null) {
      return "";
    }
    return guest.email;
  }, [guest]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row justifyContent="flex-start" style={{ padding: "5px" }}>
          {isInitializingPhase && (
            <GuestSelect
              disabled={!isInitializingPhase}
              guest={guest}
              setGuest={setGuest}
            />
          )}

          {!isInitializingPhase && <P1>{guestEmail}</P1>}
        </Row>
      )}
    </Flexbox>
  );
};

export default GuestInput;
