import React from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";

import ImportFooter from "../ImportFooter";
import { ContactUpload } from "./ContactUpload";
import { MeetingDefinition, Tag } from "src/types";

export function Confirmation({
  contacts,
  meetingTemplate,
  tags,
  onPrev,
  onNext,
}: {
  contacts: ContactUpload;
  meetingTemplate: MeetingDefinition | null;
  tags: Tag[];
  onPrev: () => void;
  onNext: () => void;
}) {
  return (
    <>
      <Box sx={{ width: "100%", minHeight: "400px" }}>
        <Stack alignItems="center" spacing={2}>
          <Typography align="center">
            Let's make sure everything looks right
          </Typography>

          <Typography align="center">
            You will be uploading {contacts.length}{" "}
            {contacts.length === 1 ? "contact" : "contacts"}
            {meetingTemplate === null
              ? ". You have not selected a Meeting Template. No new meetings will be created. You may go back if you wish to change this."
              : ` and creating a ${meetingTemplate.name} meeting with them.`}
          </Typography>

          {meetingTemplate !== null && (!tags || tags.length < 1) && (
            <Typography align="center">
              The meetings will not be tagged.
            </Typography>
          )}

          {meetingTemplate !== null && tags && tags.length > 0 && (
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography align="center">
                The meetings will be created with the following tags:
              </Typography>
              <Stack
                direction="row"
                spacing={0.5}
                sx={{ width: "fit-content" }}
              >
                {tags.map((t) => (
                  <Chip size="small" key={t.id} label={t.name} />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
      <ImportFooter
        onLeftButtonClick={onPrev}
        onRightButtonClick={onNext}
        rightButtonText="Submit"
      />
    </>
  );
}

export default Confirmation;
