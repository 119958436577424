import React, { forwardRef } from "react";

import style from "./drawer.module.scss";

export function DrawerContainer({ children }) {
  return <div className={style.drawer__container}>{children}</div>;
}

export function DrawerContent({ children }) {
  return <div className={style.drawer__content}>{children}</div>;
}

export function DrawerSpacing({ children }) {
  return <div className={style.drawer__spacing}>{children}</div>;
}

export const DrawerHeader = forwardRef(({ children }, ref) => {
  return (
    <div className={style.drawer__header} ref={ref}>
      {children}
    </div>
  );
});

export function DrawerFooter({ children }) {
  return <div className={style.drawer__footer}>{children}</div>;
}
