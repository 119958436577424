import { Close, Fullscreen, InfoRounded } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

export function MetricCard({
  title,
  help,
  fullscreen,
  children,
}: {
  title: string;
  help?: string;
  fullscreen?: React.ReactNode;
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={1}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography align="center" variant="h4">
            {title}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: "80vh" }}>{fullscreen}</DialogContent>
      </Dialog>
      <Paper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              gap: theme.spacing(1),
              justifyContent: "flex-start",
              pl: 2,
              pr: 2,
              pt: 2,
            }}
          >
            <Typography variant="h6">{title}</Typography>
            {help && (
              <Tooltip title={help}>
                <InfoRounded
                  sx={{ height: "18px", width: "18px", color: "#63707C" }}
                />
              </Tooltip>
            )}
          </Box>
          {fullscreen && (
            <IconButton onClick={() => setOpen(true)}>
              <Fullscreen />
            </IconButton>
          )}
        </Stack>
        <Box sx={{ height: "180px" }}>{children}</Box>
      </Paper>
    </Grid>
  );
}
