import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import OrgSettingsLogoUpload from "../../../componentsV2/OrgSettingsLogoUpload";

function OrgSettings() {
  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title={<Typography variant="h4">Organization Settings</Typography>}
      />
      <CardContent>
        <OrgSettingsLogoUpload />
      </CardContent>
    </Card>
  );
}

export default OrgSettings;
