import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";

import Dialog from "src/componentsV2/dialogs/Dialog";
import Dropdown from "src/componentsV2/Dropdown";
import Input from "../inputs/Input";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";

export function ProvisioningDialog({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (value: {
    name: string;
    domains: string[];
    preferredClient: "google" | "microsoft";
  }) => void;
}) {
  const [confirm, setConfirm] = useState(false);
  const [name, setName] = useState("");
  const [domains, setDomains] = useState<string[]>([]);
  const [preferredClient, setPreferredClient] = useState("");
  const [nameConfirmation, setNameConfirmation] = useState("");
  const [domainConfirm, setDomainConfirm] = useState<string[]>([]);

  return (
    <Dialog
      title="Create a new Organization"
      open={open}
      onClose={onClose}
      actions={
        <>
          {confirm ? (
            <PrimaryButton
              disabled={
                name !== nameConfirmation ||
                domains.some((d, idx) => d !== domainConfirm[idx])
              }
              onClick={() => {
                if (onSubmit) {
                  onSubmit({
                    name,
                    domains: domains,
                    preferredClient: preferredClient as "google" | "microsoft",
                  });
                }
              }}
            >
              Provision
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() => {
                setConfirm(true);
                setDomains(domains.map((d) => d.trim()));
              }}
              disabled={
                name === "" || domains.length === 0 || preferredClient === ""
              }
            >
              Confirm
            </PrimaryButton>
          )}
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </>
      }
    >
      {confirm ? (
        <Stack spacing={2}>
          <Typography>
            Please re-enter the organization name and the associated domains to
            confirm the provisioning of the new organization.
          </Typography>
          <Input
            label="Name"
            placeholder={name}
            value={nameConfirmation}
            onChange={(e) => setNameConfirmation(e.target.value)}
            error={nameConfirmation.length > 0 && nameConfirmation !== name}
          />
          {domains.map((domain, index) => (
            <Input
              key={domain}
              label={`Domain (${domain})`}
              spellCheck={false}
              placeholder={domain}
              value={domainConfirm[index] || ""}
              onChange={(e) =>
                setDomainConfirm((p) => {
                  const currentDomains = p.slice();
                  currentDomains[index] = e.target.value;
                  return currentDomains;
                })
              }
              error={
                domainConfirm[index]?.length > 0 &&
                domainConfirm[index] !== domains[index]
              }
            />
          ))}
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ pt: 1 }}>
          <Input
            label="Organization Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <Input
            label="Domain(s)"
            spellCheck={false}
            value={domains.join(",")}
            placeholder="kronologic.ai,kronologic.com"
            onChange={(event) => setDomains(event.target.value.split(","))}
          />

          <Dropdown
            label="Preferred Client"
            options={[
              { label: "Google", value: "google" },
              { label: "Microsoft", value: "microsoft" },
            ]}
            value={preferredClient}
            onChange={(event) => setPreferredClient(event.target.value)}
          />
        </Stack>
      )}
    </Dialog>
  );
}
