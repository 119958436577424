import superagent from "superagent";
import * as superagentUse from "superagent-use";
import prefix from "superagent-prefix";

import { useReportingServiceURL } from "./useReportingServiceURL";

export function useReportingService() {
  const url = useReportingServiceURL();
  const service = superagentUse(superagent);
  service.use(prefix(url));
  return service;
}
