import React from "react";
import { useUserService } from "../../services";
import { ResponseError } from "superagent";

interface FinishSignupResponse {
  jwtToken: string;
  email: string;
  error: string;
  done: boolean;
}

function useFinishSignupStatus() {
  const service = useUserService();

  return (stripeSessionId: string): Promise<FinishSignupResponse> =>
    service
      .get(`/api/user/account/setup/status?session_id=${stripeSessionId}`)
      .then((res: Response) => res.body)
      .catch((err: ResponseError) => {
        throw new Error("Error");
      });
}

export default useFinishSignupStatus;
