import React, { createContext, ReactNode, useContext } from "react";
import kronologicLogo from "../assets/K.png";
import { useSessionStorage } from "../hooks";

const LogoUrlContext = createContext<[string, (value: string) => void]>([
  kronologicLogo,
  (value: string) => undefined,
]);

export function useLogoUrl() {
  return useContext(LogoUrlContext);
}

export function LogoUrlProvider({ children }: { children?: ReactNode }) {
  const [logoUrl, setLogoUrl] = useSessionStorage("LOGO_URL", kronologicLogo);

  return (
    <LogoUrlContext.Provider value={[logoUrl, setLogoUrl]}>
      {children}
    </LogoUrlContext.Provider>
  );
}
