import React, { useState } from "react";

import { TextField, Stack } from "@mui/material";

import { validateEmailAddress } from "../../utils/helpers";
import Dialog from "./Dialog";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";

type Contact = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  unsubscribed: boolean;
  account: string;
  customField1: string;
  customField10: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  customField6: string;
  customField7: string;
  customField8: string;
  customField9: string;
  leadScore: string;
  logicField: string;
  phone: string;
  routingField: string;
  state: string;
  zip: string;
};

export function AddContactModal({
  open,
  contact,
  onSubmit,
  onClose,
}: {
  open: boolean;
  contact?: Partial<Contact>;
  onSubmit: (newContact: Contact) => void;
  onClose: () => void;
}) {
  const initialState = {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    unsubscribed: false,
    account: "",
    customField1: "",
    customField10: "",
    customField2: "",
    customField3: "",
    customField4: "",
    customField5: "",
    customField6: "",
    customField7: "",
    customField8: "",
    customField9: "",
    leadScore: "",
    logicField: "",
    phone: "",
    routingField: "",
    state: "",
    zip: "",
  };

  const [form, setForm] = useState<Contact>({ ...initialState, ...contact });
  const [changed, setChanged] = useState(false);

  const resetForm = () => {
    setForm({ ...initialState, ...contact });
    setChanged(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Add Contact"
      actions={
        <>
          <PrimaryButton
            disabled={
              !validateEmailAddress(form.email) ||
              form.firstName === "" ||
              form.lastName === "" ||
              !changed
            }
            onClick={() => onSubmit(form)}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              resetForm();
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack direction="row" spacing={2}>
        <Stack>
          <TextField
            margin="dense"
            size="small"
            label="Email"
            value={form.email}
            onChange={(event) => {
              setForm({
                ...form,
                email: event.target.value,
              });
              setChanged(true);
            }}
            required
          />
          <TextField
            margin="dense"
            size="small"
            label="First Name"
            value={form.firstName}
            onChange={(event) => {
              setForm({
                ...form,
                firstName: event.target.value,
              });
              setChanged(true);
            }}
            required
          />
          <TextField
            margin="dense"
            size="small"
            label="Last Name"
            value={form.lastName}
            onChange={(event) => {
              setForm({
                ...form,
                lastName: event.target.value,
              });
              setChanged(true);
            }}
            required
          />
          <TextField
            margin="dense"
            size="small"
            label="Phone Number"
            value={form.phone}
            onChange={(event) => {
              setForm({
                ...form,
                phone: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Account"
            value={form.account}
            onChange={(event) => {
              setForm({
                ...form,
                account: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Zip Code"
            value={form.zip}
            onChange={(event) => {
              setForm({
                ...form,
                zip: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="State"
            value={form.state}
            onChange={(event) => {
              setForm({
                ...form,
                state: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Logic Field 2"
            value={form.leadScore}
            onChange={(event) => {
              setForm({
                ...form,
                leadScore: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Logic Field 1"
            value={form.logicField}
            onChange={(event) => {
              setForm({
                ...form,
                logicField: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Routing Field"
            value={form.routingField}
            onChange={(event) => {
              setForm({
                ...form,
                routingField: event.target.value,
              });
              setChanged(true);
            }}
          />
        </Stack>
        <Stack>
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 1"
            value={form.customField1}
            onChange={(event) => {
              setForm({
                ...form,
                customField1: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 2"
            value={form.customField2}
            onChange={(event) => {
              setForm({
                ...form,
                customField2: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 3"
            value={form.customField3}
            onChange={(event) => {
              setForm({
                ...form,
                customField3: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 4"
            value={form.customField4}
            onChange={(event) => {
              setForm({
                ...form,
                customField4: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 5"
            value={form.customField5}
            onChange={(event) => {
              setForm({
                ...form,
                customField5: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 6"
            value={form.customField6}
            onChange={(event) => {
              setForm({
                ...form,
                customField6: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 7"
            value={form.customField7}
            onChange={(event) => {
              setForm({
                ...form,
                customField7: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 8"
            value={form.customField8}
            onChange={(event) => {
              setForm({
                ...form,
                customField8: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 9"
            value={form.customField9}
            onChange={(event) => {
              setForm({
                ...form,
                customField9: event.target.value,
              });
              setChanged(true);
            }}
          />
          <TextField
            margin="dense"
            size="small"
            label="Custom Field 10"
            value={form.customField10}
            onChange={(event) => {
              setForm({
                ...form,
                customField10: event.target.value,
              });
              setChanged(true);
            }}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
}
