import React from "react";
import { Slider } from "@mui/material";

export function GapSlider({
  value,
  onChange = () => null,
}: {
  value: number;
  onChange?: (newValue: number) => void;
}) {
  return (
    <Slider
      value={value}
      onChange={(e, v) => onChange(v as number)}
      defaultValue={45}
      step={null}
      min={0}
      max={60}
      valueLabelDisplay="auto"
      marks={[
        {
          label: "0 minutes",
          value: 0,
        },
        {
          label: "15 minutes",
          value: 15,
        },
        {
          label: "30 minutes",
          value: 30,
        },
        {
          label: "45 minutes",
          value: 45,
        },
        {
          label: "1 hour",
          value: 60,
        },
      ]}
    />
  );
}

export function DurationSlider({
  value,
  onChange = () => null,
}: {
  value: number;
  onChange?: (newValue: number) => void;
}) {
  return (
    <Slider
      value={value}
      onChange={(e, v) => onChange(v as number)}
      defaultValue={45}
      step={15}
      min={15}
      max={180}
      valueLabelDisplay="auto"
      marks
    />
  );
}
