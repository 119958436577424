import React from "react";
import useGuestStatusFilterOnClick from "../useGuestStatusFilterOnClick";
import Card from "../../../../components/card/Card";
import Flexbox from "../../../../components/flexbox";
import { H5, P1 } from "../../../../components/typography";
import { LoadingCard } from "../../../../components/genericView/GenericView";
import {
  ACCEPT_PENDING_QUORUM_STATUS,
  AI_NEGOTIATING_STATUS,
  AWAITING_RESPONSE_STATUS,
  HOST_INTERVENED_STATUS,
  REQUIRES_HOST_INTERVENTION_STATUS,
} from "../../../props";
import { ButtonContainer } from "../../../../components/button";
import style from "./guestActive.module.scss";

const GuestActive = ({
  isLoading,
  awaitingResponse,
  requiresHostIntervention,
  acceptPendingQuorum,
  aiNegotiating,
  hostIntervened,
}) => {
  const handleOnAwaitingResponseClick = useGuestStatusFilterOnClick([
    AWAITING_RESPONSE_STATUS,
  ]);
  const handleOnRequiresHostInterventionClick = useGuestStatusFilterOnClick([
    REQUIRES_HOST_INTERVENTION_STATUS,
  ]);
  const handleOnAiNegotiationClick = useGuestStatusFilterOnClick([
    AI_NEGOTIATING_STATUS,
  ]);
  const handleOnHostIntervenedClick = useGuestStatusFilterOnClick([
    HOST_INTERVENED_STATUS,
  ]);

  const handleOnAcceptsPendingQuorumClick = useGuestStatusFilterOnClick([
    ACCEPT_PENDING_QUORUM_STATUS,
  ]);
  return (
    <Flexbox>
      {({ Column, Row }) => (
        <div className={style.guestActive}>
          {isLoading && (
            <LoadingCard
              className={style.guestActive__loadingCard}
              height={160}
            />
          )}

          {!isLoading && (
            <Card className={style.guestActive__card}>
              <Column className={style.guestActive__card_content}>
                <Row className={style.guestActive__card_content_row}>
                  <Column>
                    <P1 className={style.guestActive__text}>
                      Awaiting Response
                    </P1>
                    <ButtonContainer
                      className={style.guestActive__value}
                      name="/instances/dashboard_gs/awaiting_response"
                      onClick={handleOnAwaitingResponseClick}
                      transparent
                    >
                      <H5>{awaitingResponse}</H5>
                    </ButtonContainer>
                  </Column>

                  <Column>
                    <P1 className={style.guestActive__text}>
                      Requires Host Intervention
                    </P1>
                    <ButtonContainer
                      className={style.guestActive__value}
                      name="/instances/dashboard_gs/requires_host_intervention"
                      onClick={handleOnRequiresHostInterventionClick}
                      transparent
                    >
                      <H5>{requiresHostIntervention}</H5>
                    </ButtonContainer>
                  </Column>

                  <Column>
                    <P1 className={style.guestActive__text}>
                      Accept Pending Quorum
                    </P1>
                    <ButtonContainer
                      className={style.guestActive__value}
                      name="/instances/dashboard_gs/accept_pending_quorum"
                      onClick={handleOnAcceptsPendingQuorumClick}
                      transparent
                    >
                      <H5>{acceptPendingQuorum}</H5>
                    </ButtonContainer>
                  </Column>
                </Row>

                <Row>
                  <Column>
                    <P1 className={style.guestActive__text}>AI Negotiating</P1>
                    <ButtonContainer
                      className={style.guestActive__value}
                      name="/instances/dashboard_gs/ai_negotiating"
                      onClick={handleOnAiNegotiationClick}
                      transparent
                    >
                      <H5>{aiNegotiating}</H5>
                    </ButtonContainer>
                  </Column>

                  <Column>
                    <P1 className={style.guestActive__text}>Host Intervened</P1>
                    <ButtonContainer
                      className={style.guestActive__value}
                      name="/instances/dashboard_gs/host_intervened"
                      onClick={handleOnHostIntervenedClick}
                      transparent
                    >
                      <H5>{hostIntervened}</H5>
                    </ButtonContainer>
                  </Column>
                </Row>
              </Column>
            </Card>
          )}
        </div>
      )}
    </Flexbox>
  );
};

export default GuestActive;
