import React from "react";
import { Box } from "@mui/material";

export function CoHostIndicator({ enabled }: { enabled: boolean | null }) {
  const color = enabled ? "primary.main" : "#CDD6DD";

  return (
    <>
      <Box
        sx={{
          borderRadius: 999,
          height: "12px",
          width: "12px",
          backgroundColor: color,
        }}
      ></Box>
    </>
  );
}

export default CoHostIndicator;
