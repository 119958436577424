import React from "react";
import { Stack } from "@mui/material";
import DaySchedule from "./DaySchedule";
import { WeekSchedule, dayList } from "src/types/Availability";

function TemplateAvailability({
  value,
  onChange,
}: {
  value: WeekSchedule;
  onChange: (updatedSchedule: WeekSchedule) => void;
}) {
  return (
    <Stack sx={{ gap: 3 }}>
      <Stack sx={{ flexDirection: "column", fontWeight: "bold" }}>
        {dayList.map((day, dayIndex) => {
          return (
            <DaySchedule
              key={day}
              weekSchedule={value}
              day={day}
              dayIndex={dayIndex}
              onChange={onChange}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

export default TemplateAvailability;
