import superagent from "superagent";
import * as superagentUse from "superagent-use";
import prefix from "superagent-prefix";

import { useProvisioningServiceURL } from "./useProvisioningServiceURL";

export function useProvisioningService() {
  const url = useProvisioningServiceURL();
  const service = superagentUse(superagent);
  service.use(prefix(url));
  return service;
}
