import * as React from "react";
import TextField from "@mui/material/TextField";
import {
  DateTimePicker,
  DateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

const DEFAULT_FORMAT = "MM/DD/YYYY";

function DateInput({
  value,
  label,
  helperText,
  inputFormat = DEFAULT_FORMAT,
  onChange,
}: Omit<DateTimePickerProps<Dayjs, Dayjs>, "renderInput"> & {
  helperText?: string;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField fullWidth {...params} helperText={helperText} />
        )}
        inputFormat={inputFormat}
      />
    </LocalizationProvider>
  );
}

export default DateInput;
