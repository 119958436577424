import { Stack, Typography } from "@mui/material";
import { useDebounce } from "src/hooks";
import { useUsers } from "src/queries";
import { User } from "src/types";

import { FilterAutocomplete } from "./FilterAutocomplete";

export function CreatorFilter(props: {
  search: string;
  onSearchChange: (value: string) => void;
  value?: User[];
  onChange?: (value: User[]) => void;
}) {
  const s = useDebounce(props.search, 750);

  const { data, loading } = useUsers(10, 0, { query: s });

  return (
    <FilterAutocomplete
      label="Creator"
      value={props.value}
      onChange={props.onChange}
      loading={loading}
      search={props.search}
      onSearchChange={props.onSearchChange}
      options={data?.data || []}
      getOptionLabel={(user) => (
        <Stack>
          <Typography>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="caption">{user.email}</Typography>
        </Stack>
      )}
      getOptionId={(user) => user.id}
    />
  );
}

export default CreatorFilter;
