import React from "react";
import { Link } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

export function ActionButton(props: {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  to: string;
}) {
  return (
    <Button
      sx={{ textTransform: "none", width: "175px", height: "175px" }}
      variant="contained"
      color="primary"
      component={Link}
      to={props.to}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.icon}
        <Typography variant="h5">{props.title}</Typography>
        <Typography variant="caption">{props.subtitle}</Typography>
      </Box>
    </Button>
  );
}
