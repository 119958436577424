import React from "react";

import { IntegrationButton } from "./integrationButton";
import style from "./style.module.scss";

export function SandboxButton({ name, onClick, disabled }) {
  return (
    <IntegrationButton
      name={name}
      onClick={onClick}
      disabled={disabled}
      className={style.integrationCard__sandboxButton}
    >
      Sandbox
    </IntegrationButton>
  );
}
