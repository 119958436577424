export const CHANNEL_PAGE_PATHS = {
  import: () => "/channels/import",
  intercept: () => "/channels/intercept",
  redirect: () => "/channels/import",
  update: () => "/channels/update",
};

export const MEETING_TYPES_PAGE_PATHS = {
  import: (id = ":id") => `/meetingTypes/user/${id}/recipients/channels/import`,
  intercept: (id = ":id") =>
    `/meetingTypes/user/${id}/recipients/channels/intercept`,
  redirect: (id = ":id") =>
    `/meetingTypes/user/${id}/recipients/channels/import`,
  update: (id = ":id") => `/meetingTypes/user/${id}/recipients/channels/update`,
};

export const CHANNEL_TYPES = {
  IMPORT: "import",
  INTERCEPT: "intercept",
  UPDATE: "update",
};
