import { useCallback } from "react";
import { useSearchUpdater } from "../../Search";

const useGuestStatusFilterOnClick = (filters) => {
  const searchUpdater = useSearchUpdater();
  return useCallback(() => {
    searchUpdater.setGuestStatusFilter(filters);
  }, [searchUpdater, filters]);
};

export default useGuestStatusFilterOnClick;
