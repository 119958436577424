import React from "react";

function AcceptRateCheck() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#08C804" fillOpacity="0.1" />
      <path
        d="M18.8251 11.3167L13.3334 16.8083L10.3417 13.825L9.16675 15L13.3334 19.1667L20.0001 12.5L18.8251 11.3167ZM15.0001 6.66667C10.4001 6.66667 6.66675 10.4 6.66675 15C6.66675 19.6 10.4001 23.3333 15.0001 23.3333C19.6001 23.3333 23.3334 19.6 23.3334 15C23.3334 10.4 19.6001 6.66667 15.0001 6.66667ZM15.0001 21.6667C11.3167 21.6667 8.33341 18.6833 8.33341 15C8.33341 11.3167 11.3167 8.33334 15.0001 8.33334C18.6834 8.33334 21.6667 11.3167 21.6667 15C21.6667 18.6833 18.6834 21.6667 15.0001 21.6667Z"
        fill="#4CAF50"
      />
    </svg>
  );
}

export default AcceptRateCheck;
