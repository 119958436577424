import React, { useCallback, useMemo, useRef } from "react";
import {
  mdiSortAscending,
  mdiSortClockAscendingOutline,
  mdiSortClockDescendingOutline,
  mdiSortDescending,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  MEETING_ID,
  MEETING_INSTANCE_VALUE,
  MEETING_LAST_ACTIVITY,
  MEETING_START_TIME,
  MEETING_TAGS,
  MEETING_TYPE,
} from "../props";
import {
  InstanceStageFilter,
  ToggleAllMeetings,
} from "../../multiGuestTableCells";
import { MeetingTypeFilterControls } from "../../multiGuestTableCells/MeetingTypeFilterControls";
import { GuestStatusFilter } from "../../multiGuestTableCells/GuestStatusFilter";
import { TagFilterControls } from "../../multiGuestTableCells/FilterTagCell";
import { useSearch, useSearchUpdater } from "../../Search";
import {
  TableHeaderRow,
  TableHeader,
} from "../../../components/tableV5/GridTable";
import { ButtonContainer } from "../../../components/button";
import style from "../style.module.scss";

function MeetingTableHeaderCell({ children, style: newStyle, width }) {
  return (
    <TableHeader
      className={style.meetingTable_header}
      style={{ ...newStyle, width }}
    >
      {children}
    </TableHeader>
  );
}

const isTimeHeader = (id) => {
  return id === MEETING_START_TIME || id === MEETING_LAST_ACTIVITY;
};

const SortableMeetingTableHeader = ({
  children,
  id,
  style: newStyle,
  width,
}) => {
  const search = useSearch();
  const searchUpdater = useSearchUpdater();
  const isSortedHeader = search.sort.field === id;
  const isSortedDesc = isSortedHeader && search.sort.order === "desc";

  const toggleSort = useCallback(() => {
    if (isSortedHeader) {
      searchUpdater.toggleSortOrder();
      return;
    }
    searchUpdater.setSort({
      field: id,
      order: "desc",
    });
  }, [id, isSortedHeader, searchUpdater]);

  const path = useMemo(() => {
    if (isTimeHeader(id)) {
      return isSortedDesc
        ? mdiSortClockDescendingOutline
        : mdiSortClockAscendingOutline;
    }
    return isSortedDesc ? mdiSortDescending : mdiSortAscending;
  }, [id, isSortedDesc]);

  return (
    <TableHeader
      className={style.meetingTable_headerButtonContainer}
      style={useMemo(() => ({ ...newStyle, width }), [newStyle, width])}
    >
      <ButtonContainer
        className={style.meetingTable_headerButton}
        name={`/instances/action_bar/${id}_filter`}
        onClick={toggleSort}
        transparent
      >
        {children}
        {isSortedHeader && (
          <>
            <div style={{ marginLeft: "4px" }} />
            <Icon path={path} size={0.6} />
          </>
        )}
      </ButtonContainer>
    </TableHeader>
  );
};

const MeetingTableHeader = ({ allSelected, onToggleAll }) => {
  const headerRowRef = useRef(null);

  return (
    <TableHeaderRow
      containerClassName={style.meetingTable__headersContainer}
      className={style.meetingTable_headers}
      ref={headerRowRef}
    >
      <MeetingTableHeaderCell id={MEETING_ID}>
        <ToggleAllMeetings checked={allSelected} onToggle={onToggleAll} />
      </MeetingTableHeaderCell>

      <MeetingTableHeaderCell id={MEETING_TYPE}>Active</MeetingTableHeaderCell>

      <SortableMeetingTableHeader
        id={MEETING_START_TIME}
        name="/instances/action_bar/start_time"
      >
        Start Time
      </SortableMeetingTableHeader>

      <MeetingTableHeaderCell>
        <MeetingTypeFilterControls />
      </MeetingTableHeaderCell>

      <div className={style.meetingTable__attendeesHeader}>
        <MeetingTableHeaderCell id={MEETING_TYPE} width="41%">
          <InstanceStageFilter />
        </MeetingTableHeaderCell>

        <MeetingTableHeaderCell id={MEETING_TYPE} width="42%">
          <GuestStatusFilter />
        </MeetingTableHeaderCell>

        <MeetingTableHeaderCell id={MEETING_TYPE} width="17%">
          Sequence
        </MeetingTableHeaderCell>
      </div>

      <SortableMeetingTableHeader id={MEETING_LAST_ACTIVITY}>
        Last Activity
      </SortableMeetingTableHeader>

      <MeetingTableHeaderCell id={MEETING_TAGS}>
        <TagFilterControls />
      </MeetingTableHeaderCell>

      <SortableMeetingTableHeader id={MEETING_INSTANCE_VALUE}>
        Value
      </SortableMeetingTableHeader>
    </TableHeaderRow>
  );
};

export default MeetingTableHeader;
