import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem } from "@mui/material";

export type DropdownProps<T extends string | number = string> = {
  value: T;
  label?: string;
  name?: string;
  disabled?: boolean;
  options: Array<{ label: string; value: T }>;
  onChange: (event: SelectChangeEvent<T>, child: React.ReactNode) => void;
};

function Dropdown<T extends string | number = string>(props: DropdownProps<T>) {
  return (
    <FormControl fullWidth>
      <InputLabel id={`id_${props.label}`}>{props.label}</InputLabel>
      <Select
        value={props.value}
        label={props.label}
        disabled={props.disabled}
        labelId={`id_${props.label}`}
        onChange={props.onChange}
        name={props.name}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
