import React, { useMemo } from "react";
import ReactSelect from "react-select";

import style from "./select.module.scss";

export default function Select({
  components,
  closeMenuOnScroll,
  customTheme = null,
  dataCy = null,
  dataGtm = null,
  disabled = false,
  onMenuOpen = null,
  onMenuClose = null,
  onChange,
  options,
  isClearable = false,
  isLoading = false,
  isMulti = false,
  isSearchable = true,
  menuIsOpen,
  menuPortalTarget,
  name = null,
  noOptionsMessage,
  toOption = null,
  value,
  placeholder,
  menuPosition = null,
  input,
  onInputChange,
}) {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  return (
    <ReactSelect
      aria-label={name}
      components={components}
      closeMenuOnScroll={closeMenuOnScroll}
      isDisabled={disabled}
      menuIsOpen={menuIsOpen}
      menuPortalTarget={menuPortalTarget}
      name={name}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      className={style.selectInputSize}
      isClearable={isClearable}
      isLoading={isLoading}
      isSearchable={isSearchable}
      isMulti={isMulti}
      options={options}
      noOptionsMessage={noOptionsMessage}
      theme={(t) => ({
        ...t,
        borderRadius: 0,
        // why was this here?
        // spacing: 0,
      })}
      styles={useMemo(() => {
        if (customTheme === null) {
          return {};
        }
        return {
          ...customTheme,
        };
      }, [customTheme])}
      value={toOption !== null ? toOption(value) : value}
      onChange={onChange}
      placeholder={placeholder}
      menuPosition={menuPosition}
      {...dataProps}
      input={input}
      onInputChange={onInputChange}
    />
  );
}
