import React, { useMemo } from "react";
import { GuestMessage, SystemNote, UserMessage } from "../messageTypes";

// Cannot remove ai_reponse because of backward compatibility
const AI_RESPONSES = ["ai_reponse", "ai_response", "touch_sent"];
const GUEST_RESPONSES = ["contact_reply"];
const SYSTEM_RESPONSES = ["system_note"];

const Message = ({
  guestStatus,
  occurrence,
  message,
  responseType,
  emailStatus,
  openCount,
}) => {
  const isUserMessage = useMemo(() => {
    return AI_RESPONSES.includes(responseType);
  }, [responseType]);

  const isGuestMessage = useMemo(() => {
    return GUEST_RESPONSES.includes(responseType);
  }, [responseType]);

  const isSystemNote = useMemo(() => {
    return SYSTEM_RESPONSES.includes(responseType);
  }, [responseType]);

  return (
    <>
      {isUserMessage && (
        <UserMessage
          status={guestStatus}
          occurrence={occurrence}
          message={message}
          emailStatus={emailStatus}
          openCount={openCount}
        />
      )}
      {isGuestMessage && (
        <GuestMessage
          status={guestStatus}
          occurrence={occurrence}
          message={message}
        />
      )}
      {isSystemNote && <SystemNote message={message} />}
    </>
  );
};

export default Message;
