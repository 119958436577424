import { useCallback } from "react";

import useGeneralNotifications from "../../hooks/useGeneralNotifications";

export function useDeleteSelectedTags(deleteTags, removeTags, selectedTagIds) {
  const { addError } = useGeneralNotifications();
  return useCallback(async () => {
    try {
      removeTags(selectedTagIds);
    } catch (error) {
      addError(error.message);
    }
  }, [addError, removeTags, selectedTagIds]);
}
