import { useParams } from "react-router-dom";

import { Loading } from "../../../../componentsV2/Loading";
import ErrorPage from "src/pages/ErrorPage";
import { useOrgReporting } from "../../../../queries";
import { MetricTable } from "src/componentsV2/reporting/MetricTable";
import { SingleValue } from "src/componentsV2/reporting/SingleValue";
import { MetricsGrid } from "src/componentsV2/reporting/MetricsGrid";
import { PageLayout } from "../../../../componentsV2/PageLayout";

export default function Page() {
  const { org } = useParams<{ org: string }>();
  const { data, loading, error } = useOrgReporting(200, 0);

  const selected = data?.reports.find((e) => e.accountName === org);

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!selected) {
    return <ErrorPage status={404} />;
  }

  let trend: "up" | "down" | undefined;
  if (
    selected.totals.periodData.current > selected.totals.periodData.previous
  ) {
    trend = "up";
  }
  if (
    selected.totals.periodData.current < selected.totals.periodData.previous
  ) {
    trend = "down";
  }

  return (
    <PageLayout
      title="Reporting"
      breadcrumbs={[
        {
          name: "Global",
          route: "/admin/reporting",
        },
        {
          name: selected.accountName,
          route: `/admin/reporting/${org}'`,
        },
      ]}
    >
      <MetricsGrid>
        <SingleValue
          title="Total Macs"
          help="This is the total number of MACs consumed since inception"
        >
          {selected.totals.macsConsumed}
        </SingleValue>
        <SingleValue
          title="Macs Per Day"
          help="Average number of MACs consumed per day"
        >
          {selected.macsUsedPerDay}
        </SingleValue>
        <SingleValue
          title="Current Mac Period"
          help="Average number of MACs consumed in the most recent static two week period"
          trend={trend}
        >
          {selected.totals.periodData.current}
        </SingleValue>
        <SingleValue
          title="Rolling Mac Period"
          help="14 day rolling average for MACs consumed"
        >
          {selected.totals.periodData.rolling}
        </SingleValue>

        <MetricTable
          title="Accept Rates"
          rows={[
            { label: "Accept Rate", value: selected.acceptRate },
            {
              label: "Hi Rate",
              value: selected.meetingTypePerformances.high.acceptRate,
            },
            {
              label: "Low Rate",
              value: selected.meetingTypePerformances.low.acceptRate,
            },
          ]}
        />

        <MetricTable
          title="Meetings"
          rows={[
            {
              label: "Provisioned",
              value: selected.totals.instancesProvisioned,
            },
            {
              label: "Completed",
              value: selected.totals.instancesInFinalState,
            },
          ]}
        />

        <MetricTable
          title="Meetings On Calendar"
          rows={[
            {
              label: "Current",
              value: selected.totals.periodData.meetingsOnCalendarCurrent,
            },
            {
              label: "14-Day Rolling",
              value: selected.totals.periodData.meetingsOnCalendarRolling,
            },
            {
              label: "Total",
              value: selected.totals.meetingsOnCalendar,
            },
          ]}
        />

        <MetricTable
          title="Channels"
          rows={[
            {
              label: "Import",
              value: selected.totals.importChannels,
            },
            {
              label: "Updated",
              value: selected.totals.updateChannels,
            },
          ]}
        />

        <MetricTable
          title="Users"
          rows={[
            { label: "Total", value: selected.totals.users },
            {
              label: "Expired",
              value: selected.expiredUsers ?? "N/A",
            },
          ]}
        />
      </MetricsGrid>
    </PageLayout>
  );
}
