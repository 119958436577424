import React from "react";
import StatusBanner from "../statusBanner";
import ActionHub from "../actionHub";
import ReminderBanner from "../reminderBanner";
import GuestHub from "../guestHub";
import Flexbox from "../../../components/flexbox/FlexboxV2";
import style from "./meetingDetails.module.scss";

function MeetingDetails({ meeting }) {
  return (
    <Flexbox.Column className={style.meetingDetails}>
      <StatusBanner meeting={meeting} />
      <ActionHub />
      <ReminderBanner />
      <GuestHub />
    </Flexbox.Column>
  );
}

export default MeetingDetails;
