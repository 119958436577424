import React, { useCallback, useMemo, useRef } from "react";
import Flexbox from "../../../components/flexbox";
import { TableCell } from "../../../components/tableV5/GridTable";
import { TagList } from "../../../components/tagList";
import { TagButton } from "../../../components/tagButton";
import { useSize } from "../../../components/size";
import { useModal } from "../../../components/modal";
import { ManageTags } from "../../tags";
import style from "./tableCells.module.scss";

const EMPTY_LIST = [];

const Tags = ({ meeting }) => {
  const containerRef = useRef(null);
  const { width } = useSize(containerRef);
  const [{ close: closeManageTags }, setModal, { DRAWER: type }] = useModal();

  const tags = useMemo(() => {
    if (!meeting || !meeting.tags) {
      return EMPTY_LIST;
    }
    return meeting.tags;
  }, [meeting]);

  const openTagManagement = useCallback(() => {
    setModal({
      blur: false,
      content: (
        <ManageTags
          close={closeManageTags}
          tags={tags}
          instanceId={meeting?.id}
        />
      ),
      type,
    });
  }, [closeManageTags, meeting, setModal, tags, type]);

  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell className={style.tableCells}>
          <Column ref={containerRef}>
            {tags && tags.length > 0 && (
              <TagList
                onClick={openTagManagement}
                tags={tags}
                size={0.4}
                maxCharPerRow={Math.floor(width / 8)}
                maxRows={4}
              />
            )}

            {(!tags || tags.length === 0) && (
              <TagButton
                className={style.tags__button}
                name="/instances/action_bar/add_new_instance/add_tag"
                onClick={openTagManagement}
                size={0.85}
              />
            )}
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
};

export default Tags;
