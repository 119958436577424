import shadows from "./style.module.scss";

export default function useShadows() {
  return {
    DARK_SHADOW_XL: shadows.DARK_SHADOW_XL,
    DARK_SHADOW_LG: shadows.DARK_SHADOW_LG,
    DARK_SHADOW_MD: shadows.DARK_SHADOW_MD,
    DARK_SHADOW_SM: shadows.DARK_SHADOW_SM,
    DARK_SHADOW_XS: shadows.DARK_SHADOW_XS,
    LIGHT_SHADOW_XL: shadows.LIGHT_SHADOW_XL,
    LIGHT_SHADOW_LG: shadows.LIGHT_SHADOW_LG,
    LIGHT_SHADOW_MD: shadows.LIGHT_SHADOW_MD,
    LIGHT_SHADOW_SM: shadows.LIGHT_SHADOW_SM,
    LIGHT_SHADOW_XS: shadows.LIGHT_SHADOW_XS,
  };
}
