import React from "react";
import style from "./style.module.scss";

export function Container({ children }) {
  return (
    <div className={style.tagManagement__container} role="dialog">
      {children}
    </div>
  );
}
