const TransferHostIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
  >
    <g clipPath="url(#a)">
      <path d="M12.435 8.478A3.477 3.477 0 0 0 8.957 5a3.477 3.477 0 0 0-3.479 3.478 3.477 3.477 0 0 0 3.479 3.479 3.477 3.477 0 0 0 3.478-3.479ZM2 17.174v1.739h13.913v-1.74c0-2.312-4.635-3.477-6.956-3.477C6.635 13.696 2 14.86 2 17.174ZM18.53 10.217h-4.356v1.74h4.356v2.608L22 11.087l-3.47-3.478v2.608Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default TransferHostIcon;
