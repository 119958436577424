import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItem, ListItemText } from "@mui/material";

import { useOrganizations } from "../../queries";
import { Organization } from "../../types";

export function OrganizationSelectInput({
  value,
  onChange,
}: {
  value: Organization | null;
  onChange: (v: Organization | null) => void;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const { data, loading } = useOrganizations();

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      inputValue={input}
      onInputChange={(_, v) => {
        setInput(v);
      }}
      onChange={(_, v) => {
        onChange(v);
      }}
      value={value}
      isOptionEqualToValue={(option, v) => option.name === v.name}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem key={option.id} {...props} dense>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      options={data?.data || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Organization"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default OrganizationSelectInput;
