import React from "react";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import style from "./style.module.scss";

const PLEASE_WAIT = "Please wait, retrieving sample data";

const Loader = ({ size = 3 }) => (
  <div className={style.loader_wrapper}>
    <div className={style.loader_content}>{PLEASE_WAIT}</div>
    <div className={style.loader_content}>
      <Icon size={size} path={mdiLoading} spin={4} />
    </div>
  </div>
);

export default Loader;
