import React from "react";
import Select from "../../../inputs/Select";
import LockedDropdown from "../../../components/dropdown/LockedDropdown";
import { useDefaultSelectTheme } from "../../../components/select";

export function IntegrationObjectSelect({
  name,
  value,
  onChange,
  locked,
  disabled,
  options,
}) {
  const [components, theme] = useDefaultSelectTheme();

  return (
    <div>
      {locked ? (
        <LockedDropdown value={value?.label} />
      ) : (
        <Select
          name={name}
          disabled={disabled}
          components={components}
          customTheme={theme}
          onChange={onChange}
          options={options}
          placeholder={<div>Select Object Type</div>}
          value={value}
        />
      )}
    </div>
  );
}
