import React from "react";

import AvailabilitySettings, { Availability } from "../AvailabilitySettings";
import OutlinedInput from "./OutlinedInput";

function AvailabilityInput({
  label,
  value,
  onChange,
}: {
  label: string;
  value: Availability;
  onChange: (v: Availability) => void;
}) {
  return (
    <OutlinedInput label={label}>
      <AvailabilitySettings value={value} onChange={onChange} />
    </OutlinedInput>
  );
}

export default AvailabilityInput;
