import { Box, Stack } from "@mui/material";
import React from "react";

type OneClickBackgroundProps = {
  children: React.ReactNode;
};

export const OneClickBackground = (props: OneClickBackgroundProps) => (
  <Stack
    sx={{
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(112.57deg, #2BAEF9 -0.33%, #8ED4FC 100%)",
      height: "100%",
      px: "20px",
    }}
  >
    <Box
      sx={{
        display: { xs: "none", sm: "block" },
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      <AccentTop />
    </Box>

    {props.children}

    <Box
      sx={{
        display: { xs: "none", sm: "block" },
        position: "absolute",
        bottom: 0,
        left: 0,
      }}
    >
      <AccentBottom />
    </Box>
  </Stack>
);

const AccentTop = () => (
  <svg width={358} height={335} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={0.5}>
      <path
        stroke="#fff"
        strokeWidth={2}
        d="M44 25h142M.69-16.723l44.001 42M184.749 25.669l137.477 164.152M321.46 189.464h157.996"
      />
      <circle
        cx={41.375}
        cy={23.234}
        r={8.734}
        fill="#5CC1FB"
        stroke="#F8FBFF"
      />
      <circle cx={234.254} cy={82.739} r={9.234} fill="#F8FBFF" />
      <circle
        cx={320.434}
        cy={187.386}
        r={8.734}
        fill="#76CBFC"
        stroke="#F8FBFF"
      />
    </g>
  </svg>
);

const AccentBottom = () => (
  <svg width={409} height={243} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={0.5}>
      <path
        stroke="#fff"
        strokeWidth={2}
        d="M-188 11.312H11.034M9.749 11.669l137.477 164.153M146.46 175.463h157.996M303.811 175.415l104.051 144.096"
      />
      <circle cx={59.254} cy={68.739} r={9.234} fill="#F8FBFF" />
      <circle cx={145.434} cy={173.386} r={9.234} fill="#F8FBFF" />
      <circle
        cx={333.234}
        cy={217.234}
        r={8.734}
        fill="#62C3FB"
        stroke="#F8FBFF"
      />
    </g>
  </svg>
);
