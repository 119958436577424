import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import Dialog from "./Dialog";
import {
  DefaultAvailability,
  WeekSchedule,
  WeekScheduleWrapper,
} from "src/types/Availability";
import UserAvailability from "../UserAvailability";

export function UpdateAvailabilityDialog({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: SubmitHandler<WeekScheduleWrapper>;
  onClose: () => void;
}) {
  const { handleSubmit, control } = useForm<WeekScheduleWrapper>();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Update Availability"
      actions={
        <>
          <PrimaryButton
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Controller
        name="weekSchedule"
        defaultValue={DefaultAvailability}
        control={control}
        render={({ field }) => (
          <UserAvailability value={field.value} onChange={field.onChange} />
        )}
      />
    </Dialog>
  );
}

export default UpdateAvailabilityDialog;
