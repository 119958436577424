import React, { useEffect, useState } from "react";
import { InputProps, Input } from "./Input";

export type Validator = (value: string) => boolean;

export interface ValidatedInputProps
  extends Omit<InputProps, "error" | "helperText"> {
  validators: Validator[];
}

function ValidatedInput({
  value,
  validators = [],
  ...rest
}: ValidatedInputProps) {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    setError(false);
    setHelperText("");

    if (value) {
      for (let i = 0; i < validators.length; i += 1) {
        try {
          const r = validators[i](value);
          if (r === false) {
            setError(true);
            break;
          }
        } catch (err) {
          setError(true);
          setHelperText((err as Error).message);
          break;
        }
      }
    }
  }, [value, validators]);

  return (
    <Input value={value} error={error} helperText={helperText} {...rest} />
  );
}

export default ValidatedInput;
