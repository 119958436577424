import { CalendarMonth, Event } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import loaderState1 from "../assets/LoaderState-Loader1.png";
import { useTimeout } from "../components/timeout";
import OneClickLogoImage from "./OneClickLogoImage";

const useStyles = makeStyles(() => ({
  animatedItem: {
    animation: `$rotate 2000ms linear infinite`,
  },
  "@keyframes rotate": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(-90deg)" },
    "50%": { transform: "rotate(-180deg)" },
    "75%": { transform: "rotate(-270deg)" },
    "100%": { transform: "rotate(-360deg)" },
  },
}));

export function OneClickLoading() {
  const [calendarMatched, setCalendarMatched] = useState(false);
  const animationClasses = useStyles();

  // Timeouts requested specifically by product, because the customers complained it was "too fast".
  useTimeout(() => setCalendarMatched(true), 1500);

  return (
    <Box
      sx={{
        marginTop: "5px",
        textAlign: "center",
      }}
    >
      <Box sx={{ marginBottom: "25px" }}>
        <OneClickLogoImage />
      </Box>
      {!calendarMatched && (
        <>
          <Typography variant="h5" align="center" paragraph>
            1-Click is Matching Your Calendar...
          </Typography>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginY: "48px",
            }}
          >
            <img
              style={{ position: "absolute" }}
              src={loaderState1}
              className={animationClasses.animatedItem}
            />
            <CalendarMonth
              color="primary"
              sx={{ height: "5em", width: "5em" }}
            />
          </Stack>
        </>
      )}

      {calendarMatched && (
        <>
          <Typography variant="h5" align="center" paragraph>
            1-Click is Matching Your Schedule...
          </Typography>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginY: "48px",
            }}
          >
            <img
              style={{ position: "absolute" }}
              src={loaderState1}
              className={animationClasses.animatedItem}
            />
            <Event color="primary" sx={{ height: "5em", width: "5em" }} />
          </Stack>
        </>
      )}
    </Box>
  );
}
