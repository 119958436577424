import { useMeetings } from "./useMeetings";

export function useMeeting(id: number) {
  const { data, error, loading } = useMeetings([id]);

  return {
    data: data ? data[0] : undefined,
    error: data === null ? new Error("Not Found") : error,
    loading,
  };
}
