import { Stack } from "@mui/material";
import React, { useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

import { TimezoneSelect } from "../inputs/TimezoneSelect";
import Dialog from "./Dialog";

export function UpdateTimezoneDialog({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: (value: string) => void;
  onClose: () => void;
}) {
  const [timezone, setTimezone] = useState("");
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Update Timezone"
      actions={
        <>
          <PrimaryButton onClick={() => onSubmit(timezone)}>Save</PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ pt: 1, height: 400 }}>
        <TimezoneSelect value={timezone} onChange={(v) => setTimezone(v)} />
      </Stack>
    </Dialog>
  );
}

export default UpdateTimezoneDialog;
