import {
  buildNewUserCreatedLog,
  buildInstanceStatusChangeLog,
  buildUserUpdatedLog,
  buildAppSettingsUpdatedLog,
  buildUserCalendarSettingsChangedLog,
  buildUserCalendarSharingPropertiesChangedLog,
  buildGuestStatusChangeLog,
  buildMeetingStartTimeChangedLog,
  buildMeetingCreatedLog,
} from "./builders/builders";

const LOG_TYPE = {
  GUESTS: {
    STATUS_CHANGE: "guest_status_change",
  },

  INSTANCES: {
    CREATED: "meeting_created",
    START_TIME_CHANGED: "meeting_start_time_change",
    STATUS_CHANGE: "instance_status_change",

    // STATUS_CHANGE_OLD is legacy key (timestream does not allow updates)
    STATUS_CHANGE_OLD: "meeting instance status change",
  },
  USERS: {
    APPLICATION_SETTINGS_UPDATED: "app_settings_update",
    NEW_USER_ADDED: "user_create",
    USER_CALENDAR_SETTINGS_CHANGED: "user_calendar_settings_change",
    USER_CALENDAR_SHARING_PROPERTIES_CHANGED:
      "user_calendar_sharing_properties_change",
    USER_UPDATED: "user_update",
  },
};

const buildLog = (log) => {
  switch (log.customerLogType) {
    case LOG_TYPE.INSTANCES.CREATED:
      return [...buildMeetingCreatedLog(log)];
    case LOG_TYPE.INSTANCES.START_TIME_CHANGED:
      return [...buildMeetingStartTimeChangedLog(log)];
    // status change
    case LOG_TYPE.INSTANCES.STATUS_CHANGE_OLD:
    case LOG_TYPE.INSTANCES.STATUS_CHANGE:
      return [...buildInstanceStatusChangeLog(log)];
    // new user added
    case LOG_TYPE.USERS.NEW_USER_ADDED:
      return [...buildNewUserCreatedLog(log)];
    // new user added
    case LOG_TYPE.USERS.USER_UPDATED:
      return [...buildUserUpdatedLog(log)];
    // application settings updated for a user
    case LOG_TYPE.USERS.APPLICATION_SETTINGS_UPDATED:
      return [...buildAppSettingsUpdatedLog(log)];
    case LOG_TYPE.USERS.USER_CALENDAR_SHARING_PROPERTIES_CHANGED:
      return [...buildUserCalendarSharingPropertiesChangedLog(log)];
    case LOG_TYPE.USERS.USER_CALENDAR_SETTINGS_CHANGED:
      return [...buildUserCalendarSettingsChangedLog(log)];
    case LOG_TYPE.GUESTS.STATUS_CHANGE:
      return [...buildGuestStatusChangeLog(log)];
    default:
      return ["Created"];
  }
};

export { buildLog as default };
