import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItem, ListItemText, Chip } from "@mui/material";

import { useDebounce } from "../../hooks";
import { useContacts } from "../../queries";
import { ContactWithDetails } from "../../types";

export function GuestSelectInput({
  value,
  onGuestSelect,
}: {
  value: ContactWithDetails | null;
  onGuestSelect: (guest: ContactWithDetails | null) => void;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const q = {
    qry: input,
  };

  const query = useDebounce(q, 750);

  const { data, loading } = useContacts(100, 0, query);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      fullWidth
      onInputChange={(_, v) => {
        setInput(v);
      }}
      onChange={(_, v) => {
        onGuestSelect(v);
      }}
      value={value}
      isOptionEqualToValue={(option, value) => option.email === value.email}
      getOptionDisabled={(option) => option.unsubscribed}
      filterOptions={(options, _) => options}
      getOptionLabel={(option) => option.email}
      renderOption={(props, option, _) => (
        <ListItem key={option.id} {...props} dense>
          <ListItemText
            primary={`${option.firstName} ${option.lastName}`}
            secondary={option.email}
          />
          {option.unsubscribed && <Chip size="small" label="unsubscribed" />}
        </ListItem>
      )}
      options={data?.data || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Select A Guest"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}
