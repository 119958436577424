import React from "react";
import { Icon } from "@mui/material";

export function KronologicIcon() {
  return (
    <Icon>
      <img width="100%" src="/favicon-96x96.png" alt="logo" />
    </Icon>
  );
}
