import React from "react";
import Card from "../../../../components/card/Card";
import Flexbox from "../../../../components/flexbox";
import useMeetingStatusFilterOnClick from "../useMeetingStatusFilterOnClick";
import { P1 } from "../../../../components/typography";
import { LoadingCard } from "../../../../components/genericView/GenericView";
import {
  INITIALIZING_FAULT_STAGE,
  INITIALIZING_READY_TO_ACTIVATE_STAGE,
  INITIALIZING_STAGE,
  INSTANCE_STAGE_TO_LABEL,
} from "../../../props";
import { ButtonContainer } from "../../../../components/button";
import style from "./instanceProvisioning.module.scss";

const InstanceProvisioning = ({
  isLoading,
  initializing,
  ready,
  fault,
  total,
}) => {
  const handleOnInitializingClick = useMeetingStatusFilterOnClick([
    INITIALIZING_STAGE,
  ]);

  const handleOnInitializedClick = useMeetingStatusFilterOnClick([
    INITIALIZING_READY_TO_ACTIVATE_STAGE,
  ]);

  const handleOnInitializingFaultClick = useMeetingStatusFilterOnClick([
    INITIALIZING_FAULT_STAGE,
  ]);

  const handleOnTotalClick = useMeetingStatusFilterOnClick([
    INITIALIZING_STAGE,
    INITIALIZING_READY_TO_ACTIVATE_STAGE,
    INITIALIZING_FAULT_STAGE,
  ]);

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <div className={style.instanceProvisioning}>
          {isLoading && (
            <LoadingCard
              className={style.instanceProvisioning__loadingCard}
              height={160}
            />
          )}

          {!isLoading && (
            <Card className={style.instanceProvisioning__card}>
              <Column className={style.instanceProvisioning__card_content}>
                <Row>
                  <P1
                    className={style.instanceProvisioning__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[INITIALIZING_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceProvisioning__value}
                    name="/instances/dashboard_is/initializing"
                    onClick={handleOnInitializingClick}
                    transparent
                  >
                    <P1 bold>{initializing}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1
                    className={style.instanceProvisioning__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[INITIALIZING_FAULT_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceProvisioning__value}
                    name="/instances/dashboard_is/initializing_fault"
                    onClick={handleOnInitializingFaultClick}
                    transparent
                  >
                    <P1 bold>{fault}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1
                    className={style.instanceProvisioning__text}
                    small
                    textAlign="start"
                  >
                    {
                      INSTANCE_STAGE_TO_LABEL[
                        INITIALIZING_READY_TO_ACTIVATE_STAGE
                      ]
                    }
                  </P1>

                  <ButtonContainer
                    className={style.instanceProvisioning__value}
                    name="/instances/dashboard_is/initialized"
                    onClick={handleOnInitializedClick}
                    transparent
                  >
                    <P1 bold>{ready}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1
                    bold
                    className={style.instanceProvisioning__text_total}
                    small
                  >
                    Total Meetings
                  </P1>

                  <ButtonContainer
                    className={style.instanceProvisioning__value}
                    name="/instances/dashboard_is/total_instances_initialized_group"
                    onClick={handleOnTotalClick}
                    transparent
                  >
                    <P1 bold>{total}</P1>
                  </ButtonContainer>
                </Row>
              </Column>
            </Card>
          )}
        </div>
      )}
    </Flexbox>
  );
};

export default InstanceProvisioning;
