// WeekScheduleWrapper for when using a controller with WeekSchedule without
// a parent object
export type WeekScheduleWrapper = {
  weekSchedule: WeekSchedule;
};
export type WeekSchedule = DaySchedule[];

export type DaySchedule = {
  enabled: boolean;
  weekday: number;
  minuteIntervals: Array<{
    from: number;
    to: number;
  }>;
};

type MinuteIntervals = MinuteInterval[];

type MinuteInterval = {
  from: number;
  to: number;
};

export const dayList = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

const defaultMinuteIntervals: MinuteIntervals = [
  {
    from: 9 * 60, // 9:00am
    to: (12 + 3) * 60 + 30, // 3:30pm
  },
];

export const DefaultAvailability: WeekSchedule = [
  {
    weekday: 0, // Sunday
    minuteIntervals: defaultMinuteIntervals,
    enabled: false,
  },
  {
    weekday: 1, // Monday
    minuteIntervals: defaultMinuteIntervals,
    enabled: true,
  },
  {
    weekday: 2, // Tuesday
    minuteIntervals: defaultMinuteIntervals,
    enabled: true,
  },
  {
    weekday: 3, // Wednesday
    minuteIntervals: defaultMinuteIntervals,
    enabled: true,
  },
  {
    weekday: 4, // Thursday
    minuteIntervals: defaultMinuteIntervals,
    enabled: true,
  },
  {
    weekday: 5, // Friday
    minuteIntervals: defaultMinuteIntervals,
    enabled: true,
  },
  {
    weekday: 6, // Saturday
    minuteIntervals: defaultMinuteIntervals,
    enabled: false,
  },
];
