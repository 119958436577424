import { UserStatus, UserStatusOptions } from "src/types";
import FilterDropdown from "./FilterDropdown";

export function AccountStatusFilter(props: {
  value?: UserStatus[];
  onChange?: (value: UserStatus[]) => void;
}) {
  return (
    <FilterDropdown
      label="Account Status"
      value={props.value}
      onChange={props.onChange}
      options={UserStatusOptions}
    />
  );
}

export default AccountStatusFilter;
