import React, { useCallback } from "react";
import "react-dates/initialize";
import {
  mdiMagnify,
  mdiPlus,
  mdiTagPlusOutline,
  mdiTrashCanOutline,
  mdiToggleSwitch,
  mdiUploadOutline,
  mdiAccountArrowLeftOutline,
  mdiDownloadOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Button, ButtonIcon } from "../../../components/button";
import Flexbox from "../../../components/flexbox";
import style from "../menu.module.scss";

export { CalendarFilterControls } from "./CalendarFilterControls";

export function AddButton({ onClick, disabled }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column>
          <Button
            className={style.instanceMenu__actionButton}
            data-rh="Add New Meeting"
            data-rh-at="right"
            key="table-add"
            type="primary"
            icon={mdiPlus}
            name="/instances/action_bar/add_new_instance"
            onClick={onClick}
            disabled={disabled}
            primary
          >
            <ButtonIcon path={mdiPlus} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}

export function UploadCsvBtn({ onClick }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column>
          <Button
            className={style.instanceMenu__actionButton}
            data-rh="Upload CSV"
            data-rh-at="top"
            name="/instances/action_bar/upload_csv"
            onClick={onClick}
            type="primary"
            primary
          >
            <ButtonIcon path={mdiUploadOutline} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}

export function ActivateButton({ disabled, onClick }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.instanceMenu__buttonContainer}>
          <Button
            className={style.instanceMenu__button}
            data-rh="Activate"
            data-rh-at="top"
            disabled={disabled}
            name="/instances/action_bar/activate_selected"
            onClick={onClick}
          >
            <ButtonIcon path={mdiToggleSwitch} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}

export function AddTags({ disabled, onClick }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.instanceMenu__buttonContainer}>
          <Button
            className={style.instanceMenu__button}
            data-rh="Bulk Add Tags"
            data-rh-at="top"
            disabled={disabled}
            name="/instances/action_bar/bulk_add_tags"
            onClick={onClick}
          >
            <ButtonIcon path={mdiTagPlusOutline} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}

export function RouteButton({ onClick = () => {}, disabled = false }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.instanceMenu__buttonContainer}>
          <Button
            className={style.instanceMenu__button}
            data-rh="Route Meeting(s)"
            data-rh-at="top"
            disabled={disabled}
            name="/instances/action_bar/route_meetings"
            onClick={onClick}
          >
            <ButtonIcon path={mdiAccountArrowLeftOutline} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}

export function DeleteButton({ onClick = () => {}, disabled = false }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.instanceMenu__buttonContainer}>
          <Button
            className={style.instanceMenu__button}
            data-rh="Delete Meeting(s)"
            data-rh-at="top"
            disabled={disabled}
            name="/instances/action_bar/delete_meetings"
            onClick={onClick}
          >
            <ButtonIcon path={mdiTrashCanOutline} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}

export function ExportButton({ onClick = () => {} }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.instanceMenu__buttonContainer}>
          <Button
            className={style.instanceMenu__button}
            data-rh="Export Meetings"
            data-rh-at="top"
            name="/instances/action_bar/download_csv"
            onClick={onClick}
            type="primary"
            primary
          >
            <ButtonIcon path={mdiDownloadOutline} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}
