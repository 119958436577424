import { useCallback, useMemo, useState } from "react";
import cx from "classnames";

import style from "./style.module.scss";

export function useSlide(onSlideOut) {
  const [isSlideIn, setIsSlideIn] = useState(true);
  const slideOut = useCallback(() => {
    setIsSlideIn(false);
    setTimeout(() => {
      onSlideOut();
    }, 500);
  }, [onSlideOut, setIsSlideIn]);
  const className = useMemo(() => {
    return cx({
      [style["tagManager--slidein"]]: isSlideIn,
      [style["tagManager--slideout"]]: !isSlideIn,
    });
  }, [isSlideIn]);
  return { className, slideOut };
}
