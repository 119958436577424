import React, { useMemo } from "react";
import { Box } from "@mui/material";
import Flexbox from "../../../components/flexbox";
import Guests from "../guests";
import HostRow from "../hostRow";
import MeetingStatusRow from "../meetingStatusRow";
import { TableCell } from "../../../components/tableV5/GridTable";
import { INITIALIZING_STAGE } from "../../props";
import { useInstanceDetails, useTableRowMeeting } from "../../context";
import style from "./attendees.module.scss";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";

function Attendees({ disabled, setGuest, setHost }) {
  const meeting = useTableRowMeeting();
  const openInstanceDetails = useInstanceDetails(meeting?.id || null);

  const meetingStatus = useMemo(() => {
    if (meeting && meeting.status) {
      return meeting.status;
    }
    return INITIALIZING_STAGE;
  }, [meeting]);

  const host = useMemo(() => {
    if (meeting) {
      if (meeting.host) {
        return meeting.host;
      }

      if (meeting.user) {
        return meeting.user;
      }
    }
    return null;
  }, [meeting]);

  const guests = useMemo(() => {
    if (meeting) {
      if (meeting.guests) {
        return meeting.guests;
      }

      if (meeting.contact) {
        return [meeting.contact];
      }
    }
    return [];
  }, [meeting]);

  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell>
          <Column justifyContent="flex-start">
            <Column
              className={style[`attendees__instanceStage--${meetingStatus}`]}
              height="fit-content"
            >
              <MeetingStatusRow status={meetingStatus} />

              <HostRow
                disabled={disabled}
                host={host}
                setHost={setHost}
                meetingStatus={meetingStatus}
              />
            </Column>
            <Guests disabled={disabled} guests={guests} setGuest={setGuest} />
            <Box className={style.attendees__showDetails}>
              <SecondaryButton nowrap onClick={openInstanceDetails}>
                Show Details
              </SecondaryButton>
            </Box>
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
}

export default Attendees;
