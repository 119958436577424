import React, { useCallback } from "react";
import { Checkbox } from "../tableV4";
import style from "./style.module.scss";

export function CheckBoxCell({ tag, checked, toggleTag }) {
  const onChange = useCallback(() => {
    toggleTag(tag);
  }, [toggleTag, tag]);
  return (
    <div className={style.tagManagement__cell}>
      <Checkbox
        name={`/check/${tag.id}:${tag.name}`}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}
