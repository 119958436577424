import { ROLE_LEVELS, supersedes } from "./roles";
import { useActingAs } from "./useActingAs";
import { getUserDetails } from "src/utils/jwtToken";

function hasSuperAdminPermissions(userRole) {
  return supersedes({
    has: userRole,
    required: ROLE_LEVELS.SUPER_ADMIN,
  });
}

function hasOrgAdminPermissions(userRole) {
  return supersedes({
    has: userRole,
    required: ROLE_LEVELS.ORG_ADMIN,
  });
}

export function useUserEmailAddress() {
  const userDetails = getUserDetails();
  return userDetails?.email;
}

function useUserRole() {
  const user = getUserDetails();
  if (user === null) {
    return null;
  }
  const { role = ROLE_LEVELS.DEFAULT } = user;
  return role;
}

export function useCurrentOrgName() {
  const [actingAs] = useActingAs();
  const userInfoDetails = getUserDetails();
  const { org } = userInfoDetails;
  const { org_key: orgKey } = org;
  let currentOrg = orgKey;
  if (actingAs) {
    currentOrg = actingAs.org;
  }
  return currentOrg;
}

// Users has different scopes of access that correlate to things like whether their oauth provider
// gives us access to their email and the link.
// 'profile' is the bare minimum and gives us access their e-mail, and other user profile values
function useUserScopes() {
  const userDetails = getUserDetails();
  return userDetails.scopes || ["profile"];
}

export function useIsAuthorized() {
  const userScopes = useUserScopes();
  return userScopes.includes("email") && userScopes.includes("calendar");
}

export function useHasSuperAdminPermissions() {
  return hasSuperAdminPermissions(useUserRole());
}

export function useHasOrgAdminPermissions() {
  return hasOrgAdminPermissions(useUserRole());
}

export function useIsDefaultUser() {
  return useUserRole() === ROLE_LEVELS.DEFAULT;
}

export function useActingAsOverrideHeader() {
  const [actingAs] = useActingAs();

  if (actingAs) {
    return JSON.stringify({
      email: actingAs.email,
      id: actingAs.id,
      org: actingAs.org,
      role: actingAs.role,
    });
  }

  return null;
}
