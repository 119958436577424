const formatConditions = {
  import: (conditions) => {
    if (conditions && conditions.length) {
      const cleanConditions = conditions.map((group) => {
        return [
          ...group.filter((c) => {
            return c.field.name !== "" && c.condition !== "";
          }),
        ];
      });

      if (cleanConditions.length && !cleanConditions[0].length) {
        return [];
      }

      return cleanConditions.map((group) => {
        return group.map((condition) => {
          return {
            comparison: {
              key: condition.condition,
            },
            field: {
              name: condition.field.name,
            },
            value: {
              label: condition.value,
            },
          };
        });
      });
    }

    return [];
  },
  intercept: (conditions, contactType) => {
    if (conditions && conditions.length) {
      const cleanConditions = conditions.map((group) => {
        return [
          ...group.filter((c) => {
            return c.value !== "" && c.field.name !== "";
          }),
        ];
      });

      return cleanConditions.map((group) => {
        return group.map((condition) => {
          return {
            event: {
              condition: condition.condition,
              field: condition.field.name,
              object: contactType,
              value: condition.value,
            },
          };
        });
      });
    }

    return [];
  },
  update: (conditions, contactType, meetingStatus) => {
    if (conditions && conditions.length) {
      const cleanConditions = conditions.map((group) => {
        return [
          ...group.filter((c) => {
            return c.value !== "" && c.field.name !== "";
          }),
        ];
      });

      return cleanConditions.map((group) => {
        return group.map((condition) => {
          return {
            action: {
              field: condition.field.name,
              object: contactType,
              value: condition.value,
            },
            event: {
              field: "status",
              object: "meeting",
              values: meetingStatus,
            },
          };
        });
      });
    }

    return [];
  },
};

const validateConditions = {
  import: (conditions) => {
    for (let i = 0; i <= conditions.length - 1; i += 1) {
      for (let j = 0; j <= conditions[i].length - 1; j += 1) {
        const {
          field: { label, name },
          value,
          condition,
        } = conditions[i][j];

        if (!label || !name || !condition || !value) {
          return false;
        }
      }
    }

    return true;
  },
  intercept: (conditions) => {
    for (let i = 0; i <= conditions.length - 1; i += 1) {
      for (let j = 0; j <= conditions[i].length - 1; j += 1) {
        const {
          field: { label, name },
          value,
        } = conditions[i][j];

        if (!label || !name || !value) {
          return false;
        }
      }
    }

    return true;
  },
  update: (conditions) => {
    for (let i = 0; i <= conditions.length - 1; i += 1) {
      for (let j = 0; j <= conditions[i].length - 1; j += 1) {
        const {
          field: { label, name },
          value,
        } = conditions[i][j];

        if (!label || !name || !value) {
          return false;
        }
      }
    }

    return true;
  },
};

export { formatConditions, validateConditions };
