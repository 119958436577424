import React from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

interface Option {
  label: string;
  icon?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

function DropdownMenuButton({
  button,
  options,
}: {
  options: Option[];
  button: React.FunctionComponent<{
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {button({
        onClick: (e) => {
          e.stopPropagation();
          handleClick(e);
        },
      })}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {options.map((o) => (
          <MenuItem
            key={o.label}
            disabled={o.disabled}
            onClick={(e) => {
              e.stopPropagation();
              o.onClick();
              setAnchorEl(null);
            }}
          >
            {o.icon && <ListItemIcon>{o.icon}</ListItemIcon>}
            <ListItemText>{o.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default DropdownMenuButton;
