import React from "react";
import { Autocomplete, TextField } from "@mui/material";

import { useTimezones } from "../../components/timezone";

export function TimezoneSelect({
  label = "Timezone",
  value = "",
  onChange = () => null,
}: {
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
}) {
  const { tmzData } = useTimezones();
  const tzValue = tmzData.find((o) => o.value === value);

  return (
    <Autocomplete
      disablePortal
      options={tmzData}
      renderInput={(params) => <TextField {...params} label={label} />}
      isOptionEqualToValue={(option, v) => option.value === v}
      // The empty string below is there to prevent switching from an uncontrolled state (value === undefined) to a
      // controlled state (value !== undefined)
      value={tzValue || ""}
      onChange={(event, v) => onChange(v?.value || null)}
    />
  );
}
