import React, { useCallback } from "react";
import cx from "classnames";

import style from "./style.module.scss";

export function FilterButton({ value, selected, onClick }) {
  return (
    <button
      type="button"
      className={cx(style.filterButton, {
        [style.filterButton_selected]: selected,
      })}
      onClick={useCallback(() => onClick(), [onClick])}
    >
      {value}
    </button>
  );
}
