import { getUserToken } from "./jwtToken";

import withParameters from "./url";

const request = (url, opts, extraHeaders) => {
  const token = getUserToken();
  const actingAs = JSON.parse(sessionStorage.getItem("ACTING_AS"));

  // eslint-disable-next-line no-undef
  const headers = new Headers({
    ...extraHeaders,
    "JWT-TOKEN": token ? token.replace(/"/g, "") : token,
    ...(actingAs
      ? {
          override: JSON.stringify({
            email: actingAs.email,
            id: actingAs.id,
            org: actingAs.org,
            role: actingAs.role,
          }),
        }
      : {}),
  });

  return fetch(url, {
    headers,
    method: "GET",
    mode: "cors",
    ...opts,
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error(res.status);
    }

    return res;
  });
};

const del = (url, qry = { data: [], params: [] }) => {
  let formattedURL = url;

  if (qry) {
    formattedURL = withParameters(url, qry.params, qry.data);
  }

  return request(formattedURL, {
    method: "DELETE",
  });
};

const get = (url, qryParams = [], data = [], headers) => {
  return request(withParameters(url, qryParams, data), {}, headers);
};

const patch = (url, qry = { data: [], params: [] }, body) => {
  let formattedURL = url;

  if (qry) {
    formattedURL = withParameters(url, qry.params, qry.data);
  }

  return request(formattedURL, {
    body: JSON.stringify(body),
    method: "PATCH",
  });
};

const post = (url, qry = { data: [], params: [] }, body) => {
  let formattedURL = url;

  if (qry) {
    formattedURL = withParameters(url, qry.params, qry.data);
  }

  return request(formattedURL, {
    body: JSON.stringify(body),
    method: "POST",
  });
};

export { del, get, patch, post };
