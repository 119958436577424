import React, { useEffect, useState } from "react";

import TextArea from "../../components/textArea";

import { SalesforceObjectSelect } from "../../channels/Modals/cards/SalesforceObjectSelect";
import { Modal, ModalTitle, ModalContent, ModalButtonArea } from "../Modal";
import { P1 } from "../../components/typography";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { MediumSpacing } from "../../components/spacing";
import style from "./style.module.scss";

function StyledInput({ onChange, value, ...props }) {
  return (
    <Input
      large
      placeholder="Value..."
      type="text"
      name="value"
      className={style.sf_input}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
}

function Label({ children }) {
  return <P1>{children}</P1>;
}

function Spacer() {
  return <div style={{ margin: "1em" }} />;
}

function useObjectTypeQuery(objectType) {
  const queries = {
    campaignMember: `SELECT
    Id,
    FirstName,
    LastName,
    Email,
    Phone,
    Company,
    CompanyOrAccount,
    State,
    PostalCode,
    LastModifiedDate,
    ContactId,
    Contact.Owner.Email,
    LeadId,
    Lead.Owner.Email
FROM campaignMember
`,
    contact: `SELECT
    AssistantPhone,
    Id,
    FirstName,
    LastName,
    Email,
    Phone,
    MailingPostalCode,
    MailingState,
    LastModifiedDate,
    OwnerId, 
    Owner.Email,
    Account.Name
FROM contact
`,
    lead: `SELECT
    Id,
    FirstName,
    LastName,
    Email,
    Phone,
    Company,
    State,
    PostalCode,
    LastModifiedDate,
    OwnerId,
    Owner.Email
FROM lead
`,
    opportunityContactRole: `SELECT
    Id,
    Contact.FirstName,
    Contact.LastName
    Contact.Email,
    Contact.Phone,
    Contact.MailingState,
    Contact.MailingPostalCode,
    Contact.AccountId,
    Opportunity.Account.Name,
    Opportunity.OwnerId,
    Opportunity.Owner.Email,
    Opportunity.LastModifiedDate,
FROM opportunityContactRole
`,
  };

  return queries[objectType] || "";
}

export function ConfigModal({ integration, open, onClose, onSave }) {
  const [filter, setFilter] = useState("");
  const [object, setObject] = useState({
    label: "Select",
    type: undefined,
  });

  const handleSave = () => {
    if (object.type) {
      onSave(object.type, filter);
    }
  };

  const query = useObjectTypeQuery(object.type);

  useEffect(() => {
    const filters = integration?.data?.filters?.import;

    setFilter(filters ? filters[object.type] || "" : "");
  }, [object, setFilter, integration]);

  return (
    <Modal isOpen={open} onRequestClose={onClose}>
      <ModalTitle>Salesforce Configuration</ModalTitle>
      <ModalContent>
        <div className={style.salesforceConfigModal__container}>
          <P1>
            Organization Admins can specify additional filters to be used when
            importing data from Salesforce. These filters are applied globally
            to all Salesforce channels and can be used to limit the data that
            Kronologic will have access to on import. To configure additional
            filters, select a Salesforce object type, and configure the filters
            for that type.
          </P1>
          <MediumSpacing />
          <P1>
            {`Please validate the added SOQL filters by following `}
            <a
              href="https://help.salesforce.com/s/articleView?id=sf.code_dev_console_tab_query_editor_soql_sosl.htm&type=5"
              target="_blank"
              rel="noreferrer"
              className={style.link}
            >
              these instructions
            </a>
            {` to test the query.`}
          </P1>
        </div>
        <Spacer />
        <SalesforceObjectSelect
          value={object}
          onChange={setObject}
          integrationId={integration?.id}
        />
        {object && (
          <>
            <Spacer />
            <Label>SOQL Import Filters</Label>
            <div className={style.salesforceConfigModal__containerParentDiv}>
              <StyledInput
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
                placeholder="Additional Filters"
              />
              <div className={style.salesforceConfigModal__containerChildDiv} />
              <Button primary large onClick={handleSave} disabled={!object}>
                Save
              </Button>
            </div>
            <Spacer />
            <Label>Salesforce Import Query Preview</Label>
            <br />
            <TextArea
              disabled
              rows={5}
              className={style.salesforceConfigModal__textArea}
              // This would be much better if it came from the backend but its just copy and pasted here for now :(
              value={`${query}${filter ? `WHERE ${filter}` : ""}`}
            />
          </>
        )}
      </ModalContent>
      <ModalButtonArea>
        <Button primary large onClick={onClose}>
          Close
        </Button>
      </ModalButtonArea>
    </Modal>
  );
}
