import React from "react";
import cx from "classnames";
import Modal from "react-modal";
import Icon from "@mdi/react";
import { mdiClose, mdiAlert, mdiTextBoxRemoveOutline } from "@mdi/js";
import { confirmable, createConfirmation } from "react-confirm";
import Card from "../card/Card";
import Button from "../button/Button";
import style from "./confirmModel.module.scss";

function ConfirmModal({
  title = "",
  okLabel = "Proceed",
  cancelLabel = "Cancel",
  show = false,
  proceed = () => undefined,
  cancel = () => undefined,
  dismiss = () => undefined,
}) {
  return (
    <Modal
      shouldCloseOnEsc
      isOpen={show}
      onRequestClose={dismiss}
      className="modal--content"
      overlayClassName="modal--overlay"
      shouldCloseOnOverlayClick={false}
    >
      <section className={cx("container is--fluid", style.confirmModal)}>
        <div className="row">
          <div className="col-5 col-offset-4">
            <Card icon={mdiAlert} header="Warning">
              <Icon
                path={mdiClose}
                size={2}
                className={style.close}
                onClick={dismiss}
              />
              <div className={style.modalHeader}>
                <span>{title}</span>
              </div>
              <br />
              <Icon
                path={mdiTextBoxRemoveOutline}
                size={3}
                className={style.delete}
              />
              <div className={cx("row", style.confirmBtns)}>
                <div className={cx("col-6", style.confirmBtn)}>
                  <Button
                    type="primary"
                    onClick={proceed}
                    className={style.proceedButton}
                  >
                    <span>{okLabel}</span>
                  </Button>
                </div>
                <div className={cx("col-6", style.confirmBtn)}>
                  <Button
                    type="primary"
                    onClick={cancel}
                    className={style.cancelButton}
                  >
                    <span>{cancelLabel}</span>
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
    </Modal>
  );
}

export const confirm = createConfirmation(confirmable(ConfirmModal));
