import React from "react";
import style from "./style.module.scss";

// Captures clicks outside of the MeetingTagManagement drawer and discards the window.
export function Overlay({ children, slideOut }) {
  return (
    <div
      className={style.tagManagement__overlay}
      onClick={slideOut}
      role="presentation"
    >
      {children}
    </div>
  );
}
