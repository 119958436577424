import { createContext, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { buildUrl } from "../utils/fetchV2";

export const AddTimelinePageContext = createContext();

const SetTotalContext = createContext();
export const IsFilterMenuIsOpenContext = createContext();
export const TableRowMeetingContext = createContext(null);
export const IsInitializingPhase = createContext(true);

export function useSetTotal() {
  return useContext(SetTotalContext);
}

export function useFilterMenuContext() {
  const [, setIsFilterMenuOpen] = useContext(IsFilterMenuIsOpenContext);
  const setFilterMenuOpen = useCallback(() => {
    setIsFilterMenuOpen(true);
  }, [setIsFilterMenuOpen]);
  const setFilterMenuClosed = useCallback(() => {
    setIsFilterMenuOpen(false);
  }, [setIsFilterMenuOpen]);
  return { setFilterMenuClosed, setFilterMenuOpen };
}

export const useTableRowMeeting = () => useContext(TableRowMeetingContext);

export const useIsInitializingPhase = () => useContext(IsInitializingPhase);

export function useInstanceDetails(meetingTypeId) {
  const history = useHistory();
  return () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (meetingTypeId === null) {
      history.push(buildUrl(`/instances/new`, params.entries()));
      return;
    }
    history.push(buildUrl(`/instances/${meetingTypeId}`, params.entries()));
  };
}
