import React, { useMemo } from "react";

import Flexbox from "../../../components/flexbox/FlexboxV2";
import InitialsIcon from "../../../components/initials";
import HostEmail from "../hostEmail";
import { useMeeting } from "../state";
import { getInitials } from "../util/helpers";

import style from "./host.module.scss";

const Host = () => {
  const [meeting] = useMeeting();
  const initials = useMemo(() => {
    return getInitials(meeting.host);
  }, [meeting]);
  return (
    <Flexbox.Row
      className={style.host}
      height="20%"
      justifyContent="flex-start"
    >
      <InitialsIcon
        width="40px"
        height="40px"
        initials={initials}
        size={{ medium: true }}
      />
      <HostEmail />
    </Flexbox.Row>
  );
};

export default Host;
