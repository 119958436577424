import React from "react";
import { MicrosoftIcon } from "src/icons/MicrosoftIcon";
import SignInButton from "./SignInButton";

export function SignInWithMicrosoftButton({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <SignInButton
      color="rgb(213,85,46)"
      icon={<MicrosoftIcon />}
      disabled={disabled}
      onClick={onClick}
    >
      Sign in with Microsoft
    </SignInButton>
  );
}
