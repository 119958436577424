import { useSWRConfig } from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useUserService } from "../services";
import { useTokenRefreshHandler } from "../hooks";
import { getUserToken } from "../utils/jwtToken";
import { errorHandler } from "src/hooks/errorHandler";

export function useUploadLogo() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const override = useActingAsOverrideHeader();
  const tokenRefreshHandler = useTokenRefreshHandler();

  // for these headers, we specifically must **NOT** specify
  //
  // "Content-Type": "multipart/form-data"
  //
  // the browser will handle the content-type and will add the required file boundary
  // string to the header
  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return async (file: File): Promise<void> => {
    const data = new FormData();
    data.append("file", file);

    return service
      .post(`/api/org/setting/logo`)
      .set(headers)
      .send(data)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: Response) => {
        // @ts-ignore - `keys` is not explicitly declared on type `cache`
        const keys: IterableIterator<string> = cache.keys();

        Array.from(keys)
          .filter((route: string) => route.startsWith("/api/org/setting"))
          .forEach((route: string) => {
            mutate(route);
          });

        return res;
      })
      .catch(errorHandler);
  };
}
