import { getUserToken } from "src/utils/jwtToken";
import { ResponseError } from "superagent";
import { useActingAsOverrideHeader } from "../../auth";
import { useUserService } from "../../services";

interface GetBillingPortalUrlResponse {
  url: string;
}

function useBillingPortal() {
  const service = useUserService();
  const accessToken = getUserToken();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (): Promise<GetBillingPortalUrlResponse> =>
    service
      .get(`/api/app/settings/billing`)
      .set(headers)
      .then((res: Response) => res.body)
      .catch((err: ResponseError) => {
        if (err) {
          switch (err.status) {
            case 401:
              window.location.href = "/logout";
              break;
            default:
              throw new Error("Error");
          }
        }
      });
}

export default useBillingPortal;
