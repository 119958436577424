import { Chip, Stack } from "@mui/material";

import { Tag as TagType } from "../types/Tag";

export function TagList({
  tags,
  onDelete,
}: {
  tags: TagType[];
  onDelete?: (value: TagType) => void;
}) {
  return (
    <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
      {tags.map((tag) => (
        <Chip
          key={tag.id}
          size="small"
          label={tag.name}
          onDelete={
            onDelete &&
            function () {
              onDelete(tag);
            }
          }
        />
      ))}
    </Stack>
  );
}
