export const BLANK_FIELD = {
  condition: "",
  field: { label: "", name: "" },
  value: "",
};

export const IMPORT_CONDITION_TYPES = [
  "contains",
  "does_not_contain",
  "is",
  "is_not",
  "greater_than",
  "less_than",
  "equal_or_greater_than",
  "equal_or_less_than",
];

export const IMPORT_CONDITION_TYPES_MAPPINGS = {
  [IMPORT_CONDITION_TYPES[0]]: "contains",
  [IMPORT_CONDITION_TYPES[1]]: "does not contain",
  [IMPORT_CONDITION_TYPES[2]]: "is",
  [IMPORT_CONDITION_TYPES[3]]: "is not",
  [IMPORT_CONDITION_TYPES[4]]: "greater than",
  [IMPORT_CONDITION_TYPES[5]]: "less than",
  [IMPORT_CONDITION_TYPES[6]]: "greater than or equal to",
  [IMPORT_CONDITION_TYPES[7]]: "less than or equal to",
};

export const INTERCEPT_CONDITION_TYPES = ["is", "is_not"];

export const INTERCEPT_CONDITION_TYPES_MAPPINGS = {
  [INTERCEPT_CONDITION_TYPES[0]]: "is",
  [INTERCEPT_CONDITION_TYPES[1]]: "is not",
};

export const CONTACT_STATUS_TYPES = [
  {
    status: "Initialized",
    value: "initialized",
  },
  {
    status: "Initialized & Activated",
    value: "activated",
  },
];

export const DEFAULT_STATE = {
  advanced: undefined,
  channelStatus: { value: null },
  checkAssociatedObjects: false,
  conditions: [[{ ...BLANK_FIELD }]],
  enabled: false,
  fieldMappings: {
    logicField: { label: "", name: "" },
    routingField: { label: "", name: "" },
  },
  fieldObjectType: { type: null },
  fieldObjectsDropdownData: [],
  isOpen: false,
  meetingStatus: [],
  name: "",
  selectedIntegration: { id: null },
  tagAutomation: {
    tagContacts: [],
    tagMeetings: [],
  },
};

export const MAPPED_FIELD_OBJECT_TYPES = {
  accounts: "Accounts",
  campaignMember: "Campaign Member",
  contact: "Contact",
  contacts: "Contacts",
  lead: "Lead",
  leads: "Leads",
  opportunities: "Opportunities",
  opportunity: "Opportunity Contact",
  opportunityContactRole: "Opportunity Contact",
  prospect: "Prospect",
};

export const SALESFORCE_FIELD_OBJECT_TYPES = {
  CAMPAIGN_MEMBER: "campaignMember",
  CONTACT: "contact",
  LEAD: "lead",
  OPPORTUNITY_CONTACT_ROLE: "opportunityContactRole",
};
