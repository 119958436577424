import React from "react";
import { Button, ButtonProps } from "@mui/material";

export function PrimaryButton({
  disabled,
  nowrap,
  icon,
  size,
  children,
  uppercase,
  onClick,
  ...rest
}: ButtonProps & {
  icon?: React.ReactNode;
  nowrap?: boolean;
  children?: React.ReactNode;
  uppercase?: boolean;
}) {
  return (
    <Button
      size={size}
      variant="contained"
      color="primary"
      startIcon={icon}
      disabled={disabled}
      onClick={onClick}
      style={{
        minWidth: "fit-content",
        textTransform: uppercase ? "uppercase" : "none",
        whiteSpace: nowrap ? "nowrap" : "normal",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
