import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { Redirect } from "react-router-dom";
import useFinishSignupStatus from "src/kronologic-me/mutations/useFinishSignupStatus";
import { useLocalStorage, useUrlQuery } from "../../../hooks";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import useFinishSignupSubmit from "src/kronologic-me/mutations/useFinishSignupSubmit";

type Timer = ReturnType<typeof setInterval>;

export function FinishSignUp() {
  const query = useUrlQuery();
  const { addError } = useGeneralNotifications();
  const finishSignupSubmit = useFinishSignupSubmit();
  const finishSignupStatus = useFinishSignupStatus();

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const stripeSessionId = query.session_id;

  const [_, setNextLoginEmail] = useLocalStorage<string | null>("email", null);

  const interval = useRef<Timer | null>(null);

  useEffect(() => {
    if (!accessToken) {
      finishSignupSubmit(stripeSessionId)
        .then((data) => {
          interval.current = setInterval(() => {
            finishSignupStatus(stripeSessionId)
              .then((data) => {
                if (data.done) {
                  if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                  }

                  if (data.error) {
                    addError(data.error);
                  } else {
                    setAccessToken(data.jwtToken);
                    setNextLoginEmail(data.email);
                  }
                }
              })
              .catch((err) => {
                if (interval.current) {
                  clearInterval(interval.current);
                  interval.current = null;
                }
                addError(err.message);
              });
          }, 3000);
        })
        .catch((err) => {
          addError(err.message);
        });
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [stripeSessionId]);

  return (
    (accessToken && (
      <Redirect
        to={`/?JWT-TOKEN=${accessToken}&redirectUrl=/settings/accounts`}
      />
    )) || (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        Please wait while we are setting up your account...
      </Box>
    )
  );
}

export default FinishSignUp;
