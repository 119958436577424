import React from "react";

import style from "./style.module.scss";

export const TagManagerMenu = function ({ children, menuRef }) {
  return (
    <div className={style.tagManager__menu} ref={menuRef}>
      {children}
    </div>
  );
};
