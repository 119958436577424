import React, { useMemo } from "react";
import Flexbox from "../../../components/flexbox/FlexboxV2";
import InstanceDetailsTagManager from "./Manager";
import { TagButton } from "../../../components/tagButton";
import { P1 } from "../../../components/typography";
import { useModalV2 } from "../../../components/modal";
import { useMeeting } from "../state";
import { TagListItem } from "../../../components/tagList/TagListItem";
import style from "./tags.module.scss";

const Tags = () => {
  const [meeting] = useMeeting();

  const [TagsManager, openTagManager] = useModalV2(InstanceDetailsTagManager);

  // TODO: No need to memoize
  const tags = useMemo(() => meeting?.tags || [], [meeting]);

  return (
    <>
      <TagsManager tags={tags} />
      <Flexbox.Column className={style.tags} width="50%">
        <Flexbox.Row height="15%" justifyContent="start">
          <P1 bold>Tags</P1>
        </Flexbox.Row>
        {tags.length !== 0 && (
          <Flexbox.Row
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
              flexWrap: "wrap",
              overflow: "scroll",
              paddingTop: "10px",
            }}
          >
            {tags.map((tag) => (
              <TagListItem
                key={tag.id}
                onClick={openTagManager}
                size={0.5}
                {...tag}
              />
            ))}
          </Flexbox.Row>
        )}
        <Flexbox.Row
          flex={1}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          {tags.length === 0 && (
            <TagButton
              className={style.tags_body__action}
              name="/instanceDetails/add_tag"
              onClick={openTagManager}
            />
          )}
        </Flexbox.Row>
      </Flexbox.Column>
    </>
  );
};

export default Tags;
