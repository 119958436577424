import React from "react";
import Flexbox from "../../../components/flexbox/FlexboxV2";
import Controls from "../controls";
import Tags from "../tags/Tags";
import Reporting from "../reporting";
import InstanceLogs from "../instanceLogs";
import style from "./actionHub.module.scss";

const ActionHub = () => {
  return (
    <Flexbox.Column className={style.actionHub} height="45%">
      <Flexbox.Row height="50%">
        <Controls />
        <Tags />
      </Flexbox.Row>
      <Flexbox.Row height="50%">
        <Reporting />
        <InstanceLogs />
      </Flexbox.Row>
    </Flexbox.Column>
  );
};

export default ActionHub;
