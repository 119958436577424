import React, { useState } from "react";
import { mdiHelpCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import useClickAnyWhere from "../../hooks/useClickAnywhere";
import style from "./style.module.scss";

export function HelpGuide({ children }) {
  const [openEnvUrlHelp, setOpenEnvUrlHelp] = useState(false);

  useClickAnyWhere(() => setOpenEnvUrlHelp(false));

  return (
    <div style={{ position: "relative" }}>
      <Icon
        className={style.help_icon}
        path={mdiHelpCircleOutline}
        size={0.75}
        onMouseEnter={() => {
          setOpenEnvUrlHelp(true);
        }}
      />
      {openEnvUrlHelp && children}
    </div>
  );
}
