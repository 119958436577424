import { ContactStatus, ContactStatusOptions } from "src/types/ContactStatus";
import FilterSelect from "./FilterSelect";

export function ContactStatusFilter(props: {
  value?: ContactStatus | null;
  onChange?: (value: ContactStatus | null) => void;
}) {
  return (
    <FilterSelect
      label="Contact Status"
      value={props.value}
      onChange={props.onChange}
      options={ContactStatusOptions}
    />
  );
}

export default ContactStatusFilter;
