import React from "react";
import { Stack, Typography } from "@mui/material";

import PrimaryButton from "../buttons/PrimaryButton";

// Base level building block for confirmation components.
export function Confirmation({
  title,
  message,
  icon,
  onOkClicked,
  caption,
}: {
  title: string;
  message: string;
  icon: React.ReactNode;
  onOkClicked: () => void;
  caption?: string | React.ReactNode;
}) {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        maxWidth: "20em",
        minHeight: "30em",
      }}
    >
      <Typography variant="h4" align="center">
        {title}
      </Typography>
      {icon}
      <Typography align="center">{message}</Typography>
      <Typography variant="caption" align="center">
        {caption || " "}
      </Typography>
      <PrimaryButton onClick={onOkClicked}>Ok</PrimaryButton>
    </Stack>
  );
}

export default Confirmation;
