import { DEFAULTS } from "./props";
import { useFeatures } from "./useFeatures";

export function useIsFeatureEnabled(key) {
  const { data: features, error, isLoading } = useFeatures();
  if (isLoading) {
    return false;
  }
  if (error) {
    return false;
  }
  if (features !== null && key in features) {
    return features[key];
  }
  if (key in DEFAULTS) {
    return DEFAULTS[key];
  }
  return false;
}
