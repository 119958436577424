import React from "react";
import { Paper, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Timeline } from "./timeline";
import { MenuAndTable } from "./MenuAndTable";
import Reporting from "./reporting";
import { Search } from "./Search";

export default function MeetingsPage() {
  return (
    <Search>
      <Stack spacing={1}>
        <Accordion
          defaultExpanded
          title="Report"
          TransitionProps={{ unmountOnExit: true }}
          elevation={0}
          disableGutters
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Reporting
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Reporting />
          </AccordionDetails>
        </Accordion>

        <Accordion
          title="Timeline"
          TransitionProps={{ unmountOnExit: true }}
          elevation={0}
          disableGutters
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Timeline
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Timeline />
          </AccordionDetails>
        </Accordion>

        <Paper elevation={0} sx={{}}>
          <MenuAndTable />
        </Paper>
      </Stack>
    </Search>
  );
}
