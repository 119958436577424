import React from "react";
import { Grid, CircularProgress } from "@mui/material";

export function Loading() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CircularProgress color="primary" />
    </Grid>
  );
}

export default Loading;
