import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { AccessToken } from "src/types";

export const clearUserToken = () => {
  Cookies.remove("JWT-TOKEN");
};

export const setUserToken = (token: string) => {
  Cookies.set("JWT-TOKEN", token, { sameSite: "Strict" });
};

export const getUserToken = () => {
  return Cookies.get("JWT-TOKEN");
};

export const getUserDetails = () => {
  const userToken = getUserToken();
  if (!userToken) {
    return null;
  }
  const parsedToken = jwt_decode<AccessToken>(userToken);
  return parsedToken?.user_details;
};
