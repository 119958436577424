import React from "react";
import { Box, Typography } from "@mui/material";
import { Modal } from "../../componentsV2/Modal";
import { Spinner } from "../../components/Spinner";

function Batching() {
  return (
    <Modal open onClose={() => {}}>
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="h6"
      >
        Processing requests
      </Typography>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Spinner />
      </Box>
      <Typography sx={{ width: "300px" }}>
        This may take a few minutes. Please do not refresh the page or close the
        window.
      </Typography>
    </Modal>
  );
}

export default Batching;
