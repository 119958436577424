const REQUIRED_KEYS = [
  "CHANNEL_SAMPLING_SERVICE",
  "ENVIRONMENT",
  "PROVISIONING_SERVICE",
  "REPORTING_SERVICE",
  "USER_SERVICE",
];
const OPTIONAL_KEYS = [
  "APPCUES_ACCOUNT_ID",
  "DATADOG_APPLICATION_ID",
  "DATADOG_CLIENT_ID",
  "DATADOG_SESSION_REPLAY",
  "GTM_CONTAINER_ID",
  "POLLING_INTERVAL",
  "DEDUPE_INTERVAL",
  "SEARCH_DEBOUNCE_INTERVAL",
  "APPLICATION_MODE",
];
module.exports = {
  OPTIONAL_KEYS,
  REQUIRED_KEYS,
};
