import React from "react";
import Flexbox from "../../../components/flexbox";
import Host from "../host";
import GuestHubGuestList from "../guestHubGuestList";

const GuestHub = () => {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column height="45%">
          <Host />
          <GuestHubGuestList />
        </Column>
      )}
    </Flexbox>
  );
};

export default GuestHub;
