import { setUserToken } from "src/utils/jwtToken";
import { Response } from "superagent";

// Returns a handler function that checks a response from the API
// for a new access token and updates the app's token accordingly.
export function useTokenRefreshHandler() {
  return function (res: Response) {
    // The API will set a header in the response whose value is a new access token.
    const token = res.headers["x-jwt-token"];

    // If the header is present the update the global access token.
    //
    // FYI this manual check and set would all be obselete if we stored the access
    // token as a cookie and the API used the SET-COOKIE header to refresh the
    // token. The the browser would do this automatically. Perhaps a future improvement.
    if (token) {
      setUserToken(token);
    }

    return res;
  };
}
