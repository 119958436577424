import React, { useMemo } from "react";
import Attendees from "../attendees";
import { GridRowContext } from "../../../components/tableV5/GridTable";
import { useIsInitializingPhase } from "../../useIsInitializingPhase";
import { INITIALIZING_STAGE, PROVISIONING_STAGES } from "../../props";
import { IsInitializingPhase, TableRowMeetingContext } from "../../context";
import {
  ToggleMeeting,
  ActivateMeeting,
  StartTime,
  MeetingType,
  LastActivity,
  Tags,
  Cost,
} from "../tableCells";

const MeetingTableRow = ({
  selected,
  meeting,
  index,
  activateSwitchDisabled,
  onSelectedToggle,
  onActivationToggle,
  onMeetingTypeChange,
  onGuestChange,
  onHostChange,
}) => {
  const meetingStatus = useMemo(() => {
    if (meeting && meeting.status) {
      return meeting.status;
    }
    return INITIALIZING_STAGE;
  }, [meeting]);

  return (
    <GridRowContext.Provider value={index + 1}>
      <TableRowMeetingContext.Provider value={meeting}>
        <IsInitializingPhase.Provider
          value={useIsInitializingPhase(meetingStatus)}
        >
          <ToggleMeeting
            checked={selected}
            onToggle={onSelectedToggle}
            meeting={meeting}
          />
          <ActivateMeeting
            onToggle={onActivationToggle}
            meeting={meeting}
            disabled={activateSwitchDisabled}
          />
          <StartTime meeting={meeting} />
          <MeetingType
            onMeetingTypeChange={onMeetingTypeChange}
            meeting={meeting}
          />
          <Attendees
            disabled={
              meeting &&
              meeting.status &&
              !PROVISIONING_STAGES.includes(meeting.status)
            }
            setHost={onHostChange}
            setGuest={onGuestChange}
          />
          <LastActivity meeting={meeting} />
          <Tags meeting={meeting} />
          <Cost meeting={meeting} />
        </IsInitializingPhase.Provider>
      </TableRowMeetingContext.Provider>
    </GridRowContext.Provider>
  );
};

export default MeetingTableRow;
