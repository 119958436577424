import ReportingCampaign from "../../componentsV2/ReportingCampaign";
import { PageLayout } from "src/componentsV2/PageLayout";

function CustomerReporting() {
  return (
    <PageLayout title="Reporting">
      <ReportingCampaign />
    </PageLayout>
  );
}

export default CustomerReporting;
