import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import PersonalAccessTokens from "./personalAccessTokens/PersonalAccessTokens";

// API page for org admins to edit their API settings
function APIPage() {
  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader title={<Typography variant="h4">API</Typography>} />
      <CardContent>
        <PersonalAccessTokens />
      </CardContent>
    </Card>
  );
}

export default APIPage;
