/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { User } from "../types";
import dayjs from "dayjs";

export function Appcues(props: {
  accountId: string;
  env: string;
  user: User | null;
}) {
  const history = useHistory();

  // Let app cues know anytime the page changes.
  useEffect(() => {
    const unlisten = history.listen(() => {
      // @ts-ignore
      window.Appcues?.page();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // Let app cues know when the logged in user changes.
  useEffect(() => {
    if (props.user) {
      // TODO(vovan): probably should use internal user UUID instead.
      const key = `${props.env}-${props.user.org.org_key}-${props.user.id}`;
      const signUpTime = dayjs(props.user.signUpDate);

      // 0 if sign up time is zero
      const daysSinceSignUp =
        signUpTime.year() > 0 ? Math.abs(signUpTime.diff(dayjs(), "day")) : 0;

      // @ts-ignore
      window.Appcues?.identify(key, {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        org: props.user.org.org_key,
        role: props.user.role,
        daysSinceSignUp: daysSinceSignUp,
      });
    }
  }, [props.user]);

  return null;
}
