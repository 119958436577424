import React from "react";
import { Icon } from "@mui/material";

export function KMeIcon() {
  return (
    <Icon
      sx={{
        width: "2em",
      }}
    >
      <img width="100%" src="/favicon-me.png" alt="logo" />
    </Icon>
  );
}
