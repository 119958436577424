import { useCallback, useEffect, useState } from "react";
import { useCachedFetch } from "../components/fetch";

/**
 * Query multiple requests simultaneously and resolve individually
 */
export default function useMultiQuery(requests) {
  const { contains, fetch, get, set } = useCachedFetch();
  const [errors, setErrors] = useState([]);
  const [payloads, setPayloads] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const refresh = useCallback(async () => {
    requests.forEach(async (request, index) => {
      if (contains(request)) {
        setPayloads((prev) => {
          prev[index] = get(request);
          return [...prev];
        });
        return;
      }
      try {
        setErrors((prev) => {
          prev[index] = null;
          return [...prev];
        });
        setIsLoading((prev) => {
          prev[index] = true;
          return [...prev];
        });
        setPayloads((prev) => {
          prev[index] = null;
          return [...prev];
        });
        const response = await fetch(request);
        const { status } = response;
        if (status !== 200) {
          throw new Error(await response.text());
        }
        const data = await response.json();
        set(request, data);
        setPayloads((prev) => {
          prev[index] = data;
          return [...prev];
        });
      } catch (error) {
        setErrors((prev) => {
          prev[index] = error;
          return [...prev];
        });
      } finally {
        setIsLoading((prev) => {
          prev[index] = false;
          return [...prev];
        });
      }
    });
  }, [contains, fetch, get, requests, set, setPayloads]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  return {
    errors,
    isLoading,
    payloads,
    refresh,
  };
}
