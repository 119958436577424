import React, { useState } from "react";
import { Stack } from "@mui/material";
import { HostSelectInput, MeetingHost } from "../inputs";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import Dialog from "./Dialog";

export function AssignHostDialog({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose?: () => void;
  onConfirm: (value: { host: MeetingHost }) => void;
}) {
  const [selectedHost, setSelectedHost] = useState<MeetingHost | null>(null);

  return (
    <Dialog
      title="Assign Host"
      open={open}
      onClose={onClose}
      actions={
        <>
          <PrimaryButton
            onClick={() => {
              if (selectedHost) {
                onConfirm({
                  host: selectedHost,
                });
              }
            }}
            disabled={!selectedHost}
          >
            Assign
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ pt: 1, minHeight: "5rem" }}>
        <HostSelectInput
          host={selectedHost}
          onHostSelect={(host) => {
            setSelectedHost(host);
          }}
        />
      </Stack>
    </Dialog>
  );
}
