import {
  Autocomplete,
  Checkbox,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  DefaultUserRole,
  OrgAdminRole,
  Role,
  roleToString,
  TeamAdminRole,
} from "src/types";

export function RoleFilter({
  value,
  onChange,
}: {
  value?: Role[];
  onChange?: (value: Role[]) => void;
}) {
  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      value={value}
      options={[DefaultUserRole, TeamAdminRole, OrgAdminRole]}
      onChange={(event, newValue) => onChange && onChange(newValue as Role[])}
      getOptionLabel={(option) => {
        return roleToString(option as Role);
      }}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} dense>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography>{roleToString(option as Role)}</Typography>
        </ListItem>
      )}
      renderInput={(params) => <TextField {...params} label="Role" />}
    />
  );
}

export default RoleFilter;
