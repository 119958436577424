import { mdiAccount, mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import AddNewAccount from "src/pages/settings/accounts/add";
import { useHasOrgAdminPermissions } from "../../auth";
import { useAPIPage } from "../../features";
import Accounts from "./accounts";
import ManageAccount from "./accounts/[slug]";
import SettingsBuild from "./build";
import OrgSettings from "./org-settings";
import APIPage from "./api/APIPage";

export default function Settings() {
  const theme = useTheme();
  const apiPageEnabled = useAPIPage();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Card>
            <CardHeader title={"Settings"} />
            <CardContent>
              <section>
                <Stack sx={{ gap: 2 }}>
                  <Link
                    component={NavLink}
                    to="/settings/accounts"
                    style={{
                      textDecoration: "none",
                      color: theme.palette.text.primary,
                    }}
                    activeStyle={{ color: theme.palette.primary.main }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Icon path={mdiAccount} size={1.3} />
                      <Typography>Accounts</Typography>
                    </Stack>
                  </Link>
                  <Link
                    component={NavLink}
                    to="/settings/build"
                    style={{
                      textDecoration: "none",
                      color: theme.palette.text.primary,
                    }}
                    activeStyle={{ color: theme.palette.primary.main }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Icon path={mdiInformation} size={1.3} />
                      <Typography>Build Information</Typography>
                    </Stack>
                  </Link>

                  {hasOrgAdminPermissions && (
                    <Link
                      component={NavLink}
                      to="/settings/org-settings"
                      style={{
                        textDecoration: "none",
                        color: theme.palette.text.primary,
                      }}
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <BusinessIcon
                          sx={{
                            fontSize: "30px",
                          }}
                        />
                        <Typography>Organization Settings</Typography>
                      </Stack>
                    </Link>
                  )}

                  {apiPageEnabled && hasOrgAdminPermissions && (
                    <Link
                      component={NavLink}
                      to="/settings/api"
                      style={{
                        textDecoration: "none",
                        color: theme.palette.text.primary,
                      }}
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <ElectricalServicesIcon
                          sx={{
                            fontSize: "30px",
                          }}
                        />
                        <Typography>API</Typography>
                      </Stack>
                    </Link>
                  )}
                </Stack>
              </section>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <Switch>
            <Route
              path="/settings/accounts/add"
              render={() => <AddNewAccount />}
            />
            <Route
              path="/settings/accounts/:userId"
              render={() => <ManageAccount />}
            />
            <Route path="/settings/accounts" render={() => <Accounts />} />
            <Route path="/settings/build" render={() => <SettingsBuild />} />
            {hasOrgAdminPermissions && (
              <Route
                path="/settings/org-settings"
                render={() => <OrgSettings />}
              />
            )}

            {hasOrgAdminPermissions && (
              <Route path="/settings/api" render={() => <APIPage />} />
            )}
            <Route
              path="*"
              render={() => <Redirect to="/settings/accounts" />}
            />
          </Switch>
        </Grid>
      </Grid>
    </Container>
  );
}
