import React from "react";

import { Stack, Typography } from "@mui/material";

import { Breadcrumb, Breadcrumbs } from "./Breadcrumbs";

function PageHeader({
  title,
  breadcrumbs,
  actions,
}: {
  title: string;
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactNode | React.ReactNode[];
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ mb: "35px" }}
    >
      <Stack spacing={1}>
        <Typography variant="h1" sx={{ fontSize: "40px", fontWeight: "bold" }}>
          {title}
        </Typography>
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      </Stack>
      <Stack direction="row" spacing={1}>
        {actions}
      </Stack>
    </Stack>
  );
}

export default PageHeader;
