import React from "react";
import { Stack, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";

import { BorderBox } from "../../componentsV2/BorderBox";
import { Accepted } from "../../componentsV2/confirmation";

export default function Page() {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Stack alignItems="center" sx={{ paddingTop: theme.spacing(4) }}>
      <BorderBox>
        <Accepted onOkClicked={() => history.push("/")} />
      </BorderBox>
    </Stack>
  );
}
