import React, { useState, useEffect } from "react";

import style from "./style.module.scss";
import { TagListItem } from "./TagListItem";

const EMPTY_LIST = [];

function findLastRow(rows) {
  const copy = Array.from(rows);
  return copy.reverse().find((row) => row.length > 0);
}

function lastRowHasRoom(rows, maxCharPerRow) {
  if (rows.length === 0) {
    return true;
  }
  const lastRow = findLastRow(rows);
  const lastRowLengths = lastRow.map((t) => t.name.length);
  const lastRowTotalChar = lastRowLengths.reduce((a, b) => a + b, 0);
  return lastRowTotalChar + 15 < maxCharPerRow;
}

export function TagList({
  onClick,
  maxCharPerRow = 28,
  maxRows = 1,
  size = 1,
  tags = EMPTY_LIST,
  disabled = false,
}) {
  const [renderedTags, setRenderedTags] = useState(null);
  useEffect(() => {
    let offset = 0;
    const rows = [];
    for (let rowIndex = 0; rowIndex < maxRows; rowIndex += 1) {
      const row = [];
      let currentNumberOfCharactersOnRow = 0;
      for (let i = offset; i < tags.length; i += 1) {
        offset = i;
        currentNumberOfCharactersOnRow += tags[i].name.length;
        if (currentNumberOfCharactersOnRow > maxCharPerRow) {
          // If it will flow to the next row
          if (row.length > 0) {
            break;
          }
          // If it will be the only item on the row
          if (row.length === 0) {
            row.push(tags[i]);
            offset += 1;
            break;
          }
          break;
        }
        row.push(tags[i]);
        offset += 1;
        // Two characters for each tag
        currentNumberOfCharactersOnRow += 2;
      }
      row.key = `row-${rowIndex}`;
      rows.push(row);
    }
    const invisibleCount = tags.length - offset;
    if (invisibleCount > 0) {
      if (lastRowHasRoom(rows, maxCharPerRow)) {
        const lastRow = findLastRow(rows);
        lastRow.push({
          id: "last-one",
          name: `${invisibleCount} more...`,
        });
      } else {
        const lastRow = findLastRow(rows);
        lastRow.splice(lastRow.length - 1, 1, {
          id: "last-one",
          name: `${invisibleCount + 1} more...`,
        });
      }
    }
    setRenderedTags(rows);
  }, [maxCharPerRow, maxRows, tags]);
  return (
    <div className={style.tagList}>
      {renderedTags !== null &&
        renderedTags.map((row) => {
          return (
            <div className={style.tagList__row} key={row.key}>
              {row.map((tag) => {
                return (
                  <TagListItem
                    key={tag.id}
                    {...tag}
                    onClick={onClick}
                    size={size}
                    disabled={disabled}
                  />
                );
              })}
            </div>
          );
        })}
    </div>
  );
}
