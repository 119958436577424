import { useLayoutEffect, useState } from "react";

const PIXEL_REGEX = /(?<value>\d+)px/;

function extractPixels(pixels) {
  try {
    const result = pixels.match(PIXEL_REGEX);
    if (result === null) {
      return 0;
    }
    const { groups } = result;
    return parseInt(groups.value || "0");
  } catch (error) {
    return 0;
  }
}

const EMPTY_DEPENDENCIES = [];

export function useSize(elementRef, dependencies = EMPTY_DEPENDENCIES) {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let i = 0; i < entries.length; i += 1) {
        const entry = entries[i];
        // Different browsers implement borderBoxSize as an array vs an object
        if (entry.borderBoxSize) {
          const borderBoxSize = Array.isArray(entry.borderBoxSize)
            ? entry.borderBoxSize[0]
            : entry.borderBoxSize;
          setDimensions({
            height: borderBoxSize.blockSize,
            width: borderBoxSize.inlineSize,
          });
          return;
        }
        const { target } = entry;
        const style = window.getComputedStyle(target);
        const paddingVertical =
          extractPixels(style["padding-top"]) +
          extractPixels(style["padding-bottom"]);
        const borderVertical =
          extractPixels(style["border-top-width"]) +
          extractPixels(style["border-bottom-width"]);
        const paddingHorizontal =
          extractPixels(style["padding-left"]) +
          extractPixels(style["padding-right"]);
        const borderHorizontal =
          extractPixels(style["border-left-width"]) +
          extractPixels(style["border-right-width"]);
        // Safari does not have borderBoxSize
        if (entry.contentRect) {
          const { contentRect } = entry;
          if (contentRect.height && contentRect.width) {
            setDimensions({
              height: contentRect.height + paddingVertical + borderVertical,
              width: contentRect.width + borderHorizontal + paddingHorizontal,
            });
          }
        }
      }
    });
    const element = elementRef?.current || null;
    if (element === null) {
      return;
    }
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, [elementRef, dependencies]);
  return dimensions;
}
