import React from "react";

import Dropdown, { DropdownProps } from "src/componentsV2/Dropdown";

export function UserCSVHeaderDropdown({
  value,
  onChange,
}: {
  value: string;
  onChange: DropdownProps["onChange"];
}) {
  return (
    <Dropdown
      value={value || "select"}
      options={[
        {
          label: "Select",
          value: "select",
        },
        // {
        //   label: "First Name",
        //   value: "firstName",
        // },
        // {
        //   label: "Last Name",
        //   value: "lastName",
        // },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Role",
          value: "role",
        },
      ]}
      onChange={onChange}
    />
  );
}

export default UserCSVHeaderDropdown;
