import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { API } from "../props";
import { fetchWithErrorHandling } from "../utils/fetchV2";
import { clearUserToken } from "src/utils/jwtToken";

export const MANUAL_LOGOUT_KEY = "MANUAL_LOGOUT";

export function Logout() {
  const { search } = useLocation();
  const [logoutResponse, setLogoutResponse] = useState(null);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  useEffect(() => {
    async function run() {
      try {
        setLogoutResponse({
          success: await fetchWithErrorHandling(API.auth.logout),
        });
      } catch (error) {
        setLogoutResponse({ error });
      }
      clearUserToken();
    }

    run();
  }, [setIsLoggedOut]);

  useEffect(() => {
    if (logoutResponse === null) {
      return;
    }
    clearUserToken();
    setIsLoggedOut(true);
  }, [logoutResponse, setIsLoggedOut]);

  if (isLoggedOut) {
    const params = new URLSearchParams(search);
    const to = params.get("to") || "/login";

    return <Redirect to={to} />;
  }

  return <div>Logging out...</div>;
}
