export const NO_OPTIONS = {
  guests: "No guests found",
  hosts: "No hosts found",
  meetingTypes: "No meeting templates found",
  orgs: "No orgs found",
  tags: "No tags found",
  teams: "No teams found",
  types: "No types found",
};
export const CHANNELS_TEXT = {
  FAILED_TO_LOAD: "Failed to fetch data for dropdown.",
  INTERNAL_ERROR: "Internal Error: Please try again.",
  LOADING: "Loading...",
  NO_INTERGRATION:
    "No integrations found or the connected integrations are not supported for this channel",
};
