import React from "react";
import style from "./globallogs.module.scss";

const buildNewUserInfo = (structuredData) => {
  const { userEmail, userFirstName, userLastName } = structuredData;

  if (userEmail?.length > 0) {
    return userEmail;
  }

  if (userFirstName?.length > 0 && userLastName?.length > 0) {
    return `${userFirstName} ${userLastName}`;
  }

  return "[missing new user info]";
};

const buildNewUserCreatedLog = function* (log) {
  // sample log structure
  /*
    XX/XX/XXXX XX:XX am/pm - A new user was created: [User Name].
  */

  const { structuredData } = log;

  const userInfo = buildNewUserInfo(structuredData);

  yield (
    <span>
      <span>A</span>
      <span className={style.logBuilder__bold}>{` new user `}</span>
      <span>was created:</span>
      <span className={style.logBuilder__bold}>{` ${userInfo}`}</span>
    </span>
  );
};

export { buildNewUserCreatedLog as default };
