import React from "react";
import { useTheme } from "@mui/material";

export function Logo({
  width,
  color,
  spacing,
}: {
  width?: string | number;
  color?: "white" | "black";
  spacing?: number;
}) {
  const theme = useTheme();

  return (
    <img
      width={width}
      src={
        color === "white"
          ? "/kronologic-logo-white.png"
          : "/kronologic-logo-black.png"
      }
      alt="logo"
      style={{ margin: theme.spacing(spacing || 0) }}
    />
  );
}

export default Logo;
