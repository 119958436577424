import { ResponseError } from "superagent";
import { useUserService } from "../../services";

function useFinishSignupSubmit() {
  const service = useUserService();

  return (stripeSessionId: string): Promise<void> =>
    service
      .get(`/api/user/stripe/checkout/success?session_id=${stripeSessionId}`)
      .then((res: Response) => res.body)
      .catch((err: ResponseError) => {
        throw new Error("Error");
      });
}

export default useFinishSignupSubmit;
