import React, { useMemo } from "react";
import dayjs from "dayjs";
import Flexbox from "../../../components/flexbox";
import buildLog from "../../../globalLogs/LogBuilder";
import { P1 } from "../../../components/typography";
import style from "./log.module.scss";

const Log = ({ log }) => {
  const renderableLog = useMemo(() => {
    if (log) {
      return buildLog(log);
    }
    return [];
  }, [log]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row className={style.log} height="fit-content">
          <Row width="fit-content" alignItems="flex-start">
            <P1 xSmall textAlign="start">
              {dayjs(log.timestamp).format("MM/DD/YYYY hh:mma")}
            </P1>
          </Row>
          <Row height="fit-content" justifyContent="flex-start">
            <P1 xSmall textAlign="start">
              {renderableLog.length &&
                renderableLog.map((l) => <div key={log.timestamp}>{l}</div>)}
            </P1>
          </Row>
        </Row>
      )}
    </Flexbox>
  );
};

export default Log;
