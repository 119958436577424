import React from "react";
import { mdiTrashCan } from "@mdi/js";

import { ButtonIcon } from "../../components/button/ButtonV2";
import style from "./style.module.scss";

export function DeleteButton({ name, onClick, disabled }) {
  return (
    <ButtonIcon
      name={name}
      className={style.integrationCard__deleteButton}
      size={1}
      path={mdiTrashCan}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
