import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import AcceptRateCheck from "../icons/AcceptRateCheck";
import AutomatedResponsesCircle from "../icons/AutomatedResponsesCircle";
import AverageTouchesPointer from "../icons/AverageTouchesPointer";
import RedCircleX from "../icons/RedCircleX";
import NoResponseInfo from "../icons/NoResponseInfo";
import ResponseRateBubble from "../icons/ResponseRateBubble";
import { AggregateData } from "../queries/useReportingCampaign";

function CampaignReportingStats({
  data,
}: {
  data: { total?: number; aggregateData: AggregateData };
}) {
  return (
    <Stack sx={{ flexDirection: "column", gap: "24px", marginBottom: "48px" }}>
      <Stack direction="row" sx={{ gap: "20px", width: "100%" }}>
        <TotalCampaigns total={data?.total ? data.total : 0} />
        <TotalMeetings data={data.aggregateData} />
      </Stack>
      <Stack direction="row" sx={{ gap: "20px", width: "100%" }}>
        <AcceptRate data={data.aggregateData} />
        <Declined data={data.aggregateData} />
      </Stack>
      <Stack direction="row" sx={{ width: "100%", gap: "20px" }}>
        <NoResponse data={data.aggregateData} />
        <RespondedUnknownIntent data={data.aggregateData} />
      </Stack>
      <Stack direction="row" sx={{ gap: "20px", width: "100%" }}>
        <Cancel data={data.aggregateData} />
      </Stack>
      <Stack direction="row" sx={{ gap: "20px", width: "100%" }}>
        <ResponseRate data={data.aggregateData} />
        <AutomatedResponses data={data.aggregateData} />
        <AverageTouches data={data.aggregateData} />
      </Stack>
      <Stack direction="row" sx={{ width: "100%" }}>
        <OutboundInbound data={data.aggregateData} />
      </Stack>
    </Stack>
  );
}

function AcceptRate({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack direction="row" sx={{ gap: "20px" }}>
        <Stack direction="column" sx={{ gap: "10px", width: "57%" }}>
          <Stack direction="row" sx={{ gap: "5px", alignItems: "center" }}>
            <AcceptRateCheck />
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Accepted Meetings
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              {Math.round(data.acceptRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                marginLeft: "10px",
                marginRight: "10px",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {data.acceptCount}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Stack direction="column">
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Average Time To Accept
              </Typography>
              <Typography sx={{ color: "#00000099" }}>
                {data.avgAcceptTime.toFixed(2)} days
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            color: "#0000001f",
            height: "130px",
            width: "1px",
          }}
        />
        <Stack direction="column" sx={{ width: "43%" }}>
          <Stack direction="row" sx={{ gap: "20px" }}>
            <Stack direction="column">
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Hard Accepts
              </Typography>
              <Typography sx={{ color: "#00000099" }}>
                {Math.round(data.hardAcceptRate * 100)}% |{" "}
                {data.hardAcceptCount}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Soft Accepts
              </Typography>
              <Typography sx={{ color: "#00000099" }}>
                {Math.round(data.softAcceptRate * 100)}% |{" "}
                {data.softAcceptCount}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="column" sx={{ marginTop: "10px" }}>
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              Tentative Accepts
            </Typography>
            <Typography sx={{ color: "#00000099" }}>
              {Math.round(data.tentativeAcceptRate * 100)}% |{" "}
              {data.tentativeAcceptCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function AutomatedResponses({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack direction="row">
        <Stack direction="column" sx={{ width: "57%", gap: "10px" }}>
          <Stack direction="row" sx={{ gap: "5px", alignItems: "center" }}>
            <AutomatedResponsesCircle />
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Automated Responses
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "10px", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              {Math.round(data.automatedResponseRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {data.automatedResponseCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function AverageTouches({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack sx={{ gap: "10px" }}>
        <Stack direction="row" sx={{ gap: "5px", alignItems: "center" }}>
          <AverageTouchesPointer />
          <Typography
            sx={{
              color: "#73778b",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Average Meeting Attempts
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: "600",
          }}
        >
          {data.avgTouches.toFixed(2)}
        </Typography>
      </Stack>
    </Card>
  );
}

function Cancel({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack direction="row">
        <Stack direction="column" sx={{ width: "30%", gap: "10px" }}>
          <Stack direction="row" sx={{ alignItems: "center", gap: "5px" }}>
            <RedCircleX />
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Cancelled Meetings
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "10px", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              {Math.round(data.cancelRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {data.cancelledCount}
            </Typography>
          </Stack>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            color: "#0000001f",
            height: "72px",
            marginRight: "20px",
            width: "1px",
          }}
        />
        <Stack
          direction="column"
          sx={{ width: "23%", justifyContent: "center", gap: "5px" }}
        >
          <Typography
            sx={{
              color: "#73778b",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Undeliverable
          </Typography>
          <Typography
            sx={{
              color: "#00000099",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {data.undeliverableCount}
          </Typography>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            color: "#0000001f",
            height: "72px",
            marginRight: "20px",
            width: "1px",
          }}
        />
        <Stack
          direction="column"
          sx={{ marginBottom: "auto", marginTop: "auto", width: "23%" }}
        >
          <Typography
            sx={{
              color: "#73778b",
              fontSize: "16px",
              fontWeight: "400",
              marginBottom: "5px",
            }}
          >
            Intercepted by Channel
          </Typography>
          <Typography
            sx={{
              color: "#00000099",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {data.cancelledAutomaticallyCount}
          </Typography>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            color: "#0000001f",
            height: "72px",
            marginRight: "20px",
            marginTop: "auto",
            marginBottom: "auto",
            width: "1px",
          }}
        />
        <Stack
          direction="column"
          sx={{ marginBottom: "auto", marginTop: "auto", width: "23%" }}
        >
          <Typography
            sx={{
              color: "#73778b",
              fontSize: "16px",
              fontWeight: "400",
              marginBottom: "5px",
            }}
          >
            Cancelled Manually
          </Typography>
          <Typography
            sx={{
              color: "#00000099",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {data.cancelledManuallyCount}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

function Declined({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack direction="row" sx={{ gap: "20px" }}>
        <Stack direction="column" sx={{ width: "57%", gap: "10px" }}>
          <Stack direction="row" sx={{ alignItems: "center", gap: "5px" }}>
            <RedCircleX />
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Declined Meetings
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "10px" }}>
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              {Math.round(data.declineRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {data.declineCount}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Stack direction="column" sx={{ marginTop: "10px" }}>
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Average Time To Decline
              </Typography>
              <Typography sx={{ color: "#00000099" }}>
                {data.avgDeclineTime.toFixed(2)} days
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            color: "#0000001f",
            height: "130px",
            width: "1px",
          }}
        />
        <Stack direction="column" sx={{ width: "43%" }}>
          <Stack direction="row">
            <Stack direction="column">
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Hard Declines
              </Typography>
              <Typography sx={{ color: "#00000099" }}>
                {Math.round(data.hardDeclineRate * 100)}% |{" "}
                {data.hardDeclineCount}
              </Typography>
            </Stack>
            <Box sx={{ minWidth: "20px" }} />
            <Stack direction="column">
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Soft Declines
              </Typography>
              <Typography sx={{ color: "#00000099" }}>
                {Math.round(data.softDeclineRate * 100)}% |{" "}
                {data.softDeclineCount}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            sx={{
              backgroundColor: "#ECECEC",
              marginTop: "10px",
              padding: "5px",
              width: "85px",
            }}
          >
            <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>
              Unsubscribes
            </Typography>
            <Typography sx={{ color: "#00000099", fontSize: "10px" }}>
              {Math.round(data.unsubscribeRate * 100)}% |{" "}
              {data.unsubscribeCount}
            </Typography>
            <Stack direction="row">
              <Stack direction="column">
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                  }}
                >
                  Hard
                </Typography>
                <Typography sx={{ color: "#00000099", fontSize: "10px" }}>
                  {Math.round(data.hardUnsubscribeRate * 100)}% |{" "}
                  {data.hardUnsubscribeCount}
                </Typography>
              </Stack>

              <Box sx={{ width: "18px" }} />
              <Stack direction="column">
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                  }}
                >
                  Soft
                </Typography>
                <Typography sx={{ color: "#00000099", fontSize: "10px" }}>
                  {Math.round(data.softUnsubscribeRate * 100)}% |{" "}
                  {data.softUnsubscribeCount}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function NoResponse({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack direction="column" sx={{ width: "57%", gap: "10px" }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <NoResponseInfo />
          <Typography
            sx={{
              color: "#73778b",
              fontSize: "16px",
              fontWeight: "400",
              whiteSpace: "nowrap",
            }}
          >
            No Response
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ gap: "10px", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: "600",
            }}
          >
            {Math.round(data.noResponseRate * 100)}%
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              flexShrink: 0,
              color: "#0000001f",
              marginTop: 0,
              width: "1px",
              height: "32px",
            }}
          />
          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "400",
            }}
          >
            {data.noResponseCount}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

function RespondedUnknownIntent({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack direction="row">
        <Stack direction="column" sx={{ width: "57%", gap: "10px" }}>
          <Stack direction="row" sx={{ alignItems: "center", gap: "5px" }}>
            <NoResponseInfo />
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
                whiteSpace: "nowrap",
              }}
            >
              Responses With Unknown Intent
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "10px", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              {Math.round(data.respondedUnknownIntentRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {data.respondedUnknownIntentCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function OutboundInbound({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <Stack direction="column" sx={{ gap: "10px" }}>
          <Stack direction="row">
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
                margin: "auto",
                whiteSpace: "nowrap",
              }}
            >
              Outbound Meetings
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
                marginBottom: "auto",
                marginTop: "auto",
              }}
            >
              {Math.round(data.outboundRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                marginLeft: "10px",
                marginRight: "10px",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
                marginBottom: "auto",
                marginTop: "auto",
              }}
            >
              {data.outboundCount}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="column">
          <Stack direction="row">
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
                margin: "auto",
                whiteSpace: "nowrap",
              }}
            >
              Inbound Meetings
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ marginTop: "10px" }}>
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
                marginBottom: "auto",
                marginTop: "auto",
              }}
            >
              {Math.round(data.inboundRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                marginLeft: "10px",
                marginRight: "10px",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
                marginBottom: "auto",
                marginTop: "auto",
              }}
            >
              {data.inboundCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function ResponseRate({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack direction="row">
        <Stack direction="column" sx={{ width: "57%", gap: "10px" }}>
          <Stack direction="row" sx={{ gap: "5px", alignItems: "center" }}>
            <ResponseRateBubble />
            <Typography
              sx={{
                color: "#73778b",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Manual Responses
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ alignItems: "center", gap: "10px" }}>
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              {Math.round(data.responseRate * 100)}%
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                color: "#0000001f",
                width: "1px",
                height: "33px",
              }}
            />
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {data.responseCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function TotalCampaigns({ total }: { total: number }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack>
        <Typography sx={{ color: "#73778B" }}>
          Total Meeting Templates
        </Typography>
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: "600",
            marginTop: "10px",
          }}
        >
          {total}
        </Typography>
      </Stack>
    </Card>
  );
}

function TotalMeetings({ data }: { data: AggregateData }) {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: "24px",
      }}
    >
      <Stack>
        <Typography sx={{ color: "#73778B" }}>Total Meetings</Typography>
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: "600",
            marginTop: "10px",
          }}
        >
          {data.meetingsCount}
        </Typography>
      </Stack>
    </Card>
  );
}

export default CampaignReportingStats;
