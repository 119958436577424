import React from "react";
import { Stack, Typography } from "@mui/material";
import { WeekSchedule, dayList } from "src/types/Availability";
import DaySchedule from "./DaySchedule";

function UserAvailability({
  value,
  onChange,
}: {
  value: WeekSchedule;
  onChange: (updatedSchedule: WeekSchedule) => void;
}) {
  return (
    <Stack sx={{ gap: 3, mb: 10 }}>
      <Typography variant="h5" fontWeight="bold">
        User Availability
      </Typography>

      {dayList.map((day, dayIndex) => {
        return (
          <DaySchedule
            key={day}
            weekSchedule={value}
            day={day}
            dayIndex={dayIndex}
            onChange={onChange}
          />
        );
      })}
    </Stack>
  );
}

export default UserAvailability;
