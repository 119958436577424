import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

const SnackbarContext = createContext<[(message: string) => void, () => void]>([
  () => undefined,
  () => undefined,
]);

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [message, setMessage] = useState<string | null>(null);

  const handleClose = useCallback(() => setMessage(null), [setMessage]);

  const button = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <Close fontSize="small" />
    </IconButton>
  );

  return (
    <SnackbarContext.Provider
      value={[
        useCallback((msg) => setMessage(msg), [setMessage]),
        useCallback(() => setMessage(null), [setMessage]),
      ]}
    >
      {children}
      <Snackbar
        open={message !== null}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={button}
      />
    </SnackbarContext.Provider>
  );
}
