import React from "react";
import { GoogleIcon } from "src/icons/GoogleIcon";
import SignInButton from "./SignInButton";

export function SignInWithGoogleButton({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <SignInButton
      color="#5886d4"
      icon={<GoogleIcon />}
      disabled={disabled}
      onClick={onClick}
    >
      Sign in with Google
    </SignInButton>
  );
}
