import React, { useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import { TableCell } from "../../../components/tableV5/GridTable";
import { useIsInitializingPhase } from "../../useIsInitializingPhase";
import MeetingTypeSelect from "../../meetingTypeSelect";
import { P1 } from "../../../components/typography";
import style from "./tableCells.module.scss";

const MeetingType = ({ meeting, onMeetingTypeChange }) => {
  const isInitializingPhase = useIsInitializingPhase(meeting?.status);

  const meetingType = useMemo(() => {
    return meeting.meetingType || null;
  }, [meeting]);

  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell className={style.tableCells}>
          <Column style={{ padding: "15px" }}>
            {isInitializingPhase && (
              <MeetingTypeSelect
                meetingType={meetingType}
                setMeetingType={onMeetingTypeChange}
              />
            )}

            {!isInitializingPhase && meetingType === null && (
              <P1 className={style["meetingType__container--disabled"]} bold>
                N/A
              </P1>
            )}

            {!isInitializingPhase && meetingType !== null && (
              <P1 bold style={{ lineHeight: "16px" }}>
                {meetingType.name}
              </P1>
            )}

            {meeting.meetingType && !meetingType.active && (
              <P1 xSmall>*This meeting template is currently disabled</P1>
            )}
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
};

export default MeetingType;
