import React, { useEffect, createRef } from "react";
import NProgress from "nprogress";

import style from "../style.module.scss";

function LoadingBar({ loading }) {
  const meetingTableLoadingBar = createRef();

  useEffect(() => {
    try {
      if (!meetingTableLoadingBar.current) {
        return;
      }

      NProgress.configure({
        parent: `#meetingTableLoadingBar`,
        showSpinner: false,
      });
    } catch (error) {
      // Only happens in development when components disappear due to hot-reload
    }
  }, [meetingTableLoadingBar]);

  useEffect(() => {
    try {
      if (!meetingTableLoadingBar.current) {
        return;
      }
      if (loading) {
        NProgress.start(0.7);
      } else if (NProgress.isStarted()) {
        NProgress.done();
      }
    } catch (error) {
      // Only happens in development when components disappear due to hot-reload
    }
  }, [loading, meetingTableLoadingBar]);

  return (
    <section
      className={style.meetingTable__loadingBar}
      key="meetingTableLoadingBar"
      ref={meetingTableLoadingBar}
    >
      <div>
        <div id="meetingTableLoadingBar" style={{ height: "2px" }} />
      </div>
    </section>
  );
}

export default LoadingBar;
