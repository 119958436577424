import React from "react";
import Dialog from "./Dialog";

import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";

export function ConfirmationDialog({
  open,
  title,
  message,
  onClose,
  onConfirm,
  ...rest
}: {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      actions={
        <>
          <PrimaryButton onClick={onConfirm}>Confirm</PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </>
      }
      {...rest}
    >
      {message}
    </Dialog>
  );
}
