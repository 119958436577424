export type UserStatus = "active" | "pending" | "deactivated";

export interface UserStatusOption {
  label: string;
  value: UserStatus;
}

export const UserStatusOptions: UserStatusOption[] = [
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Deactivated", value: "deactivated" },
];
