import React from "react";
import ReactModal from "react-modal";

import style from "./style.module.scss";

export function Modal({ children, ...rest }) {
  return (
    <ReactModal
      {...rest}
      className="modal--content"
      overlayClassName="modal--overlay"
    >
      <div className={style.modal__parentDiv}>
        <div className={style.modal__childDiv}>{children}</div>
      </div>
    </ReactModal>
  );
}

export function ModalButtonArea({ children }) {
  return <div className={style.modal__buttonArea}>{children}</div>;
}

export function ModalTitle({ children }) {
  return <h3 style={{ order: 0, padding: "1em" }}>{children}</h3>;
}

export function ModalContent({ children }) {
  return <div className={style.modal__content}>{children}</div>;
}
