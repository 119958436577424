import { CancelRounded } from "@mui/icons-material";
import { Link, useTheme } from "@mui/material";
import { Confirmation } from "./Confirmation";

export function Error({
  referenceId,
  message = "Oops! Something went wrong!",
  onOkClicked,
}: {
  onOkClicked: () => void;
  referenceId?: string;
  message?: string;
}) {
  const theme = useTheme();
  return (
    <Confirmation
      title="Error"
      message={message}
      caption={
        <>
          We apologize for the inconvenience. If you need immediate assistance
          please email us at:{" "}
          <Link href="mailto:support@kronologic.ai">support@kronologic.ai</Link>
          .
          <br />
          <br />
          {referenceId && <>Ref Id: {referenceId}</>}
        </>
      }
      icon={
        <CancelRounded
          sx={{
            color: theme.palette.error.main,
            height: "5em",
            width: "5em",
          }}
        />
      }
      onOkClicked={onOkClicked}
    />
  );
}
