import React from "react";
import Flexbox from "../../../components/flexbox";
import { NewFilterTags } from "../../multiGuestTableCells/FilterTags";
import { useTableRowMeeting } from "../../context";
import style from "./newTableCells.module.scss";

const Tags = ({ setTags }) => {
  const { tags } = useTableRowMeeting();

  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.cell}>
          <NewFilterTags tags={tags} setTags={setTags} />
        </Column>
      )}
    </Flexbox>
  );
};

export default Tags;
