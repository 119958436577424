import React from "react";

function RedCircleX() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#D32F2F" fillOpacity="0.08" />
      <path
        d="M20.8334 10.3417L19.6584 9.16667L15.0001 13.825L10.3417 9.16667L9.16675 10.3417L13.8251 15L9.16675 19.6583L10.3417 20.8333L15.0001 16.175L19.6584 20.8333L20.8334 19.6583L16.1751 15L20.8334 10.3417Z"
        fill="#EF5350"
      />
    </svg>
  );
}

export default RedCircleX;
