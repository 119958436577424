import React from "react";
import { mdiLock } from "@mdi/js";
import Icon from "@mdi/react";
import cx from "classnames";

import { Input } from "../input";

import style from "./dropdown.module.scss";

const LockedDropdown = ({ className, name, value }) => (
  <div className={cx(className, style.locked_dropdown)}>
    <Input
      className={style.locked_dropdown__input}
      large
      value={value}
      disabled
      aria-label={name}
    />

    <div className={style.locked_dropdown__icon}>
      <Icon path={mdiLock} size={1} />
    </div>
  </div>
);

export default LockedDropdown;
