import { Box, Paper, Popover, Stack, TextField } from "@mui/material";
import {
  LocalizationProvider,
  DateRangePicker as MuiDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Dayjs } from "dayjs";
import React, { useRef, useState } from "react";
import { FilterButton } from "./FilterButton";

export function DateRangeFilterForReporting(props: {
  value: [Dayjs | null, Dayjs | null];
  onChange: (value: [Dayjs | null, Dayjs | null]) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const value = props.value || [null, null];

  return (
    <div>
      <FilterButton
        ref={buttonRef}
        onClick={() => {
          setOpen(true);
        }}
        active={(value[0] || value[1]) !== null}
        dropdown
      >
        Date Range
      </FilterButton>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper>
          <Stack sx={{ py: 2, px: 1, width: "390px" }} spacing={2}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{
                start: "Start",
                end: "End",
              }}
            >
              <MuiDateRangePicker
                calendars={1}
                value={value}
                onChange={props.onChange}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Paper>
      </Popover>
    </div>
  );
}

export default DateRangeFilterForReporting;
