import useOpenAiCompletion from "../../../mutations/useOpenAiCompletion";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { Input } from "../../inputs/Input";
import { Stack } from "@mui/material";
import SecondaryButton from "../../buttons/SecondaryButton";

export const OpenAiTextInput = ({
  onAiResponse,
}: {
  onAiResponse: (v: string) => void;
}) => {
  const fetchOpenAiCompletion = useOpenAiCompletion();
  const { addError } = useGeneralNotifications();
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    fetchOpenAiCompletion(value)
      .then((data) => {
        onAiResponse(data.text);
      })
      .catch((err) => {
        addError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const maxPromptLength = 512;

  return (
    <Stack spacing={1}>
      <Input
        multiline
        label="Enter instructions and AI will write your message (BETA)"
        type="text"
        value={value}
        placeholder="Write your prompt here"
        error={value.length > maxPromptLength}
        helperText={
          value.length > maxPromptLength
            ? `Text is too long, please limit to ${maxPromptLength} symbols`
            : ""
        }
        onChange={(e) => setValue(e.target.value)}
      />
      {isLoading ? (
        <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
          <Icon size={1} path={mdiLoading} spin={4} />
        </div>
      ) : (
        <SecondaryButton disabled={value.length < 1} onClick={onClick}>
          Generate Message
        </SecondaryButton>
      )}
    </Stack>
  );
};
