import React from "react";
import { Box } from "@mui/material";

import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";

export function ImportFooter({
  leftButtonDisabled = false,
  onLeftButtonClick = () => null,
  rightButtonDisabled = false,
  rightButtonText = "Next",
  onRightButtonClick = () => null,
}: {
  leftButtonDisabled?: boolean;
  onLeftButtonClick?: () => void;
  rightButtonDisabled?: boolean;
  rightButtonText?: string;
  onRightButtonClick?: () => void;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        py: 2,
        width: "100%",
      }}
    >
      <PrimaryButton onClick={onLeftButtonClick} disabled={leftButtonDisabled}>
        Prev
      </PrimaryButton>
      <PrimaryButton
        onClick={onRightButtonClick}
        disabled={rightButtonDisabled}
      >
        {rightButtonText}
      </PrimaryButton>
    </Box>
  );
}

export default ImportFooter;
