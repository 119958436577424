import React from "react";

import dynamicsImg from "../../assets/dynamics.png";
import style from "./style.module.scss";

export function Logo() {
  return (
    <header>
      <img src={dynamicsImg} alt="dynamics" className={style.logo} />
    </header>
  );
}
