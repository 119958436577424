import React from "react";
import { Button } from "@mui/material";
import { ButtonSize } from "./ButtonProps";

function HighEmphasisButton({
  disabled,
  nowrap,
  icon,
  children,
  onClick,
  size,
  ...rest
}: {
  disabled?: boolean;
  icon?: React.ReactNode;
  nowrap?: boolean;
  children?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: ButtonSize;
}) {
  return (
    <Button
      size={size}
      variant="outlined"
      color="error"
      startIcon={icon}
      disabled={disabled}
      onClick={onClick}
      style={{
        minWidth: "fit-content",
        textTransform: "none",
        whiteSpace: nowrap ? "nowrap" : "normal",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default HighEmphasisButton;
