import React from "react";

function ResponseRateBubble() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#E8EAF6" />
      <path
        d="M9.47925 18.75H18.4584V20H10.0001L6.66675 23.3333V7.91666C6.66675 7.59722 6.79175 7.30903 7.04175 7.05208C7.29175 6.79514 7.58341 6.66666 7.91675 6.66666H22.0834C22.4029 6.66666 22.6911 6.79514 22.948 7.05208C23.2049 7.30903 23.3334 7.59722 23.3334 7.91666V13.75H22.0834V7.91666H7.91675V20.3125L9.47925 18.75ZM7.91675 18.75V20.3125V7.91666V18.75ZM20.9584 24.1667V20H19.7084V15H22.7292L21.5209 18.5417H23.3334L20.9584 24.1667Z"
        fill="#3F51B5"
      />
    </svg>
  );
}

export default ResponseRateBubble;
