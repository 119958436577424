import React, { useMemo } from "react";
import style from "./style.module.scss";

const SAMPLE_IMPORTS = "*Sample data from previous three days";
const EXPECTED_IMPORTS = "*Expected next group of imports";

// when a new channel is sampled, we do not have a last processed time, so we default to 3 days
const PLEASE_NOTE_CREATION_DATE =
  "Please note, only contacts added/modified after channel creation date and while channel is enabled will be imported";

// an existing channel does have a last processed time, so we only show contacts that pass filters since that time
const PLEASE_NOTE_LAST_PROCESS =
  "Please note, only contacts added/modified since last process time and while channel is enabled will be imported";

const NO_NEW_RECORDS = "No new records";

// MAX_TEST_CONTACTS_DISPLAY is the maximum number of sample contacts we will display
const MAX_TEST_CONTACTS_DISPLAY = 100;

// MAX_CONTACT_COUNT is the maximum total sample contacts we will count
const MAX_CONTACT_COUNT = 500;

function contactInfo(contact) {
  return {
    email: contact?.email || "",
    firstName: contact?.firstName || "",
    lastName: contact?.lastName || "",
    logicField: contact?.logicField || "",
    routingField: contact?.routingField || "",
  };
}

function SampleImportData({ importData, isNewChannel = false }) {
  const { data, total } = importData;

  const recordString = useMemo(() => {
    if (total < MAX_TEST_CONTACTS_DISPLAY) {
      return `Matching records: ${total}*`;
    }

    if (total < MAX_CONTACT_COUNT) {
      return `Matching records: showing 100 of ${total}*`;
    }

    return `Matching records: showing 100 of 500 or more matches*`;
  }, [total]);

  return (
    <>
      <div className={style.sampleImportData}>
        <div className={style.sampleImport__topBar}>
          <span className={style.sampleImport__topBar__item}>Last Name</span>
          <span className={style.sampleImport__topBar__item}>First Name</span>
          <span className={style.sampleImport__topBar__item}>Email</span>
          <span className={style.sampleImport__topBar__item}>
            Routing Field
          </span>
          <span className={style.sampleImport__topBar__item}>Logic Field</span>
        </div>
        <div className={style.sampleImport__contacts}>
          {data.map((contact, i) => {
            const { email, firstName, lastName, routingField, logicField } =
              contactInfo(contact);

            return (
              <div
                key={`test-contact-${i + 1}`}
                className={style.sampleImport__contacts__row}
              >
                <span className={style.sampleImport__contacts__row__item}>
                  {lastName}
                </span>
                <span className={style.sampleImport__contacts__row__item}>
                  {firstName}
                </span>
                <span className={style.sampleImport__contacts__row__item}>
                  {email}
                </span>
                <span className={style.sampleImport__contacts__row__item}>
                  {routingField}
                </span>
                <span className={style.sampleImport__contacts__row__item}>
                  {logicField}
                </span>
              </div>
            );
          })}
          {!data?.length && <div>{NO_NEW_RECORDS}</div>}
        </div>
      </div>
      <div className={style.totalCount}>
        {recordString}

        {isNewChannel ? (
          <div>{`${SAMPLE_IMPORTS} - ${PLEASE_NOTE_CREATION_DATE}`}</div>
        ) : (
          <>
            <div>{`${EXPECTED_IMPORTS} - ${PLEASE_NOTE_LAST_PROCESS}`}</div>
          </>
        )}
      </div>
    </>
  );
}

export default SampleImportData;
