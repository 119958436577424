import { CancelRounded } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import full from "src/assets/kronologic.me/full.svg";
import kronologicMeBG from "src/assets/kronologic.me/kronologicMeBG.png";
import { useSignUpUrl } from "src/kronologic-me/queries/useSignUpUrl";
import SignInButton from "../../../componentsV2/buttons/SignInButton";
import { GoogleIcon } from "src/icons/GoogleIcon";
import { MicrosoftIcon } from "src/icons/MicrosoftIcon";

function KMeSignup() {
  const theme = useTheme();
  const history = useHistory();

  const { data: authURLs, error } = useSignUpUrl();

  return (
    <CardMedia image={kronologicMeBG} sx={{ height: "100%", width: "100%" }}>
      <Stack
        direction="column"
        sx={{
          backdropFilter: "blur(18px)",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 100%)",
          color: "white",
          height: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          minWidth: "fit-content",
          width: "45%",
        }}
      >
        <CardMedia
          image={full}
          sx={{
            minHeight: "35px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "100px",
            width: "404px",
          }}
        />

        <Typography align="center" variant="caption" marginTop="10px">
          Learn more about{" "}
          <Link href="https://www.kronologic.com/" target="_blank">
            Kronologic
          </Link>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "40px",
            fontWeight: "700",
            lineHeight: "72px",
            letterSpacing: "-0.5px",
            marginTop: "85px",
            textAlign: "center",
          }}
        >
          Get Started
        </Typography>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "100",
            lineHeight: "5px",
            marginTop: "5px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          No Credit Card Required
        </Typography>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "100",
            lineHeight: "5px",
            marginTop: "5px",
            textAlign: "center",
          }}
        >
          14 Day Free Trial - Cancel Anytime
        </Typography>
        <>
          {error && (
            <Stack alignItems="center" spacing={1}>
              <CancelRounded sx={{ color: theme.palette.error.main }} />
              <Typography variant="caption" color="red">
                Internal Error
              </Typography>
            </Stack>
          )}
          {authURLs && (
            <Stack
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "50px",
                width: "300px",
              }}
            >
              <SignInButton
                color="#5886d4"
                icon={<GoogleIcon />}
                onClick={() => {
                  window.location.href = authURLs.google;
                }}
              >
                Sign up with Google
              </SignInButton>
              <Box sx={{ marginBottom: "1em" }} />

              <SignInButton
                color="rgb(213,85,46)"
                icon={<MicrosoftIcon />}
                onClick={() => {
                  window.location.href = authURLs.microsoft;
                }}
              >
                Sign up with Microsoft
              </SignInButton>
            </Stack>
          )}
          <Typography
            component="span"
            sx={{
              fontWeight: "200",
              fontSize: "16px",
              lineHeight: "25.6px",
              letterSpacing: "0.15px",
              marginTop: "30px",
              textAlign: "center",
            }}
          >
            Already have an account?{" "}
            <Typography
              component="span"
              sx={{
                color: "#2BAEF9",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/login");
              }}
            >
              Log in
            </Typography>
          </Typography>

          <Typography align="center" variant="caption" marginTop="10px">
            By creating a Kronologic account you agree to Kronologic's{" "}
            <Link href="https://www.kronologic.com/eula">Terms of Service</Link>{" "}
            and{" "}
            <Link href="https://www.kronologic.com/privacy">
              Privacy Policy
            </Link>
          </Typography>
        </>
      </Stack>
    </CardMedia>
  );
}

export default KMeSignup;
