import React from "react";
import Guest from "../guest";
import Flexbox from "../../../components/flexbox";
import GuestStatus from "../guestStatus";
import GuestAttempts from "../guestAttempts";
import style from "./guestRow.module.scss";

function GuestRow({ disabled, guest, setGuest, guestIndex }) {
  return (
    <Flexbox>
      {({ Row }) => (
        <Row className={style.guestRow} justifyContent="flex-start">
          <Guest
            disabled={disabled}
            guest={guest}
            setGuest={setGuest}
            guestIndex={guestIndex}
          />

          {guest !== null && <GuestStatus guest={guest} />}

          {guest !== null && <GuestAttempts />}
        </Row>
      )}
    </Flexbox>
  );
}

export default GuestRow;
