import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Availability } from "./AvailabilitySettings";
import dayjs from "dayjs";
import { WeekSchedule } from "src/types/Availability";

function Day({ checked, children }: { checked?: boolean; children: string }) {
  const theme = useTheme();
  return (
    <Typography
      variant="caption"
      sx={{
        color: checked
          ? theme.palette.primary.main
          : theme.palette.text.disabled,
      }}
    >
      {children}
    </Typography>
  );
}

export function AvailabilityPreview({
  weekSchedule,
}: {
  weekSchedule: WeekSchedule;
}) {
  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: 0.5,
        }}
      >
        <Day checked={weekSchedule?.[0]?.enabled || false}>Su</Day>
        <Day checked={weekSchedule?.[1]?.enabled || false}>M</Day>
        <Day checked={weekSchedule?.[2]?.enabled || false}>T</Day>
        <Day checked={weekSchedule?.[3]?.enabled || false}>W</Day>
        <Day checked={weekSchedule?.[4]?.enabled || false}>Th</Day>
        <Day checked={weekSchedule?.[5]?.enabled || false}>F</Day>
        <Day checked={weekSchedule?.[6]?.enabled || false}>S</Day>
      </Box>
    </Box>
  );
}
