import React from "react";

import { IntegrationButton } from "./integrationButton";

export function ComingSoon({ name }) {
  return (
    <IntegrationButton name={name} disabled>
      Coming Soon
    </IntegrationButton>
  );
}
