const MeetingEmptyStateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={210} height={220} fill="none">
    <path
      fill="#CDD6DD"
      d="M98.306 183.25H22.25c-5.913 0-10.976-2.103-15.19-6.31-4.207-4.214-6.31-9.277-6.31-15.19V43.5c0-5.913 2.103-10.972 6.31-15.179C11.274 24.107 16.337 22 22.25 22H33V.5h21.5V22H119V.5h21.5V22h10.75c5.913 0 10.976 2.107 15.19 6.321 4.207 4.207 6.31 9.266 6.31 15.179v65.306a37.074 37.074 0 0 0-5.235-.677A109.93 109.93 0 0 0 162 108c-1.971 0-3.805.043-5.504.129a37.073 37.073 0 0 0-5.246.677V75.75h-129v86h76.056a37.046 37.046 0 0 0-.677 5.246 109.275 109.275 0 0 0-.129 5.504c0 1.971.043 3.805.129 5.504.093 1.706.319 3.454.677 5.246Z"
    />
    <path
      fill="#CDD6DD"
      d="M170.288 171.854a36.733 36.733 0 0 0 4.901-9.803c1.226-3.541 1.839-7.285 1.839-11.234 0-4.97-.953-9.633-2.86-13.991-1.838-4.357-4.391-8.17-7.659-11.438-3.268-3.267-7.081-5.821-11.438-7.659-4.357-1.906-9.021-2.859-13.991-2.859-4.97 0-9.633.953-13.99 2.859-4.358 1.838-8.17 4.392-11.438 7.659-3.268 3.268-5.855 7.081-7.762 11.438-1.838 4.358-2.757 9.021-2.757 13.991 0 4.97.919 9.634 2.757 13.991 1.907 4.357 4.494 8.17 7.762 11.438 3.268 3.268 7.08 5.855 11.438 7.761 4.357 1.838 9.02 2.757 13.99 2.757 3.949 0 7.694-.578 11.234-1.736a39.159 39.159 0 0 0 9.804-5.004l34.517 39.42 13.072-13.072-39.419-34.518Zm-29.208 5.107c-3.608 0-7.012-.681-10.212-2.043a26.555 26.555 0 0 1-8.272-5.617 26.56 26.56 0 0 1-5.617-8.272c-1.361-3.199-2.042-6.604-2.042-10.212 0-3.608.681-6.978 2.042-10.11 1.362-3.2 3.234-5.991 5.617-8.374a26.572 26.572 0 0 1 8.272-5.617c3.2-1.362 6.604-2.042 10.212-2.042 3.609 0 6.979.68 10.111 2.042 3.199 1.362 5.991 3.234 8.374 5.617 2.382 2.383 4.255 5.174 5.616 8.374 1.362 3.132 2.043 6.502 2.043 10.11 0 3.608-.681 7.013-2.043 10.212a26.558 26.558 0 0 1-5.616 8.272c-2.383 2.383-5.175 4.256-8.374 5.617-3.132 1.362-6.502 2.043-10.111 2.043Z"
    />
  </svg>
);

export default MeetingEmptyStateIcon;
