import React from "react";
import Icon from "@mdi/react";
import { mdiMinus } from "@mdi/js";

import { IntegrationButton } from "./integrationButton";
import style from "./style.module.scss";

export function DisconnectButton({ onClick, disabled, name }) {
  return (
    <IntegrationButton
      className={style.integrationCard__disconnectButton}
      name={name}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon size={1} path={mdiMinus} />
      Disconnect
    </IntegrationButton>
  );
}
