import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import SelectAMeeting from "./SelectAMeeting";
import EditMessage from "./EditMessage";
import AddGuest from "./AddGuest";
import PreviewAndLaunch from "./PreviewAndLaunch";
import { State } from "./State";
import { getUserDetails } from "src/utils/jwtToken";
import { useActingAs } from "src/auth";
import { DefaultUserRole } from "../../../types";

export function QuickLaunchDialog(props: {
  dialog?: boolean;
  open: boolean;
  onClose: () => void;
}) {
  const user = getUserDetails();
  const [actingAs] = useActingAs();
  const actingAsUser = actingAs || user;

  const initialState = {
    guest: null,
    host: actingAsUser,
    meetingType: null,
    overrides: {
      duration: 30,
      dayRangeFrom: 1,
      dayRangeTo: 30,
      gap: 30,
      bufferDurationMinutes: 180,
      emailSubjectTemplate: null,
      emailBodyTemplate: null,
      inviteTitleTemplate: null,
      inviteBodyTemplate: null,
    },
    startTime: null,
    coHost: null,
    preferredTimeType: "scheduling range",
    actingAsUserRole: actingAsUser?.role || DefaultUserRole,
  } as const;

  const [state, setState] = useState<State>(initialState);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeStep, setActiveStep] = useState(0);

  const handlePrevious = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  const handleNext = () => {
    setActiveStep(Math.min(activeStep + 1, 3));
  };

  return (
    <Dialog open={props.open} fullScreen={fullScreen} disablePortal fullWidth>
      <DialogTitle sx={{ p: 0, m: 0 }}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            py: 1.5,
            px: 1.5,
            width: "100%",
          }}
        >
          <Stack alignItems="center">
            <Typography variant="h5">Launcher</Typography>
            <Typography>Create a meeting</Typography>
          </Stack>
          {props.onClose && (
            <IconButton
              sx={{
                position: "absolute",
                top: 1,
                right: 1,
              }}
              onClick={() => {
                setActiveStep(0);
                setState(initialState);
                props.onClose();
              }}
            >
              <Close
                sx={{
                  color: theme.palette.primary.contrastText,
                }}
              />
            </IconButton>
          )}
        </Paper>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ my: 2 }}>
          <Step>
            <StepLabel>Select A Meeting</StepLabel>
          </Step>
          <Step>
            <StepLabel>Edit Message</StepLabel>
          </Step>
          <Step>
            <StepLabel>Add Guest</StepLabel>
          </Step>
          <Step>
            <StepLabel>Preview And Launch</StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>
      {activeStep === 0 && (
        <SelectAMeeting state={state} setState={setState} next={handleNext} />
      )}
      {activeStep === 1 && (
        <EditMessage
          state={state}
          setState={setState}
          next={handleNext}
          previous={handlePrevious}
        />
      )}
      {activeStep === 2 && (
        <AddGuest
          state={state}
          setState={setState}
          next={handleNext}
          previous={handlePrevious}
        />
      )}
      {activeStep === 3 && (
        <PreviewAndLaunch
          state={state}
          setState={setState}
          previous={handlePrevious}
          close={() => {
            setActiveStep(0);
            setState(initialState);
            props.onClose();
          }}
        />
      )}
    </Dialog>
  );
}
