import React from "react";
import { SCHEDULING_MODES, SchedulingMode } from "src/types";
import Dropdown from "./Dropdown";

function SchedulingModeDropdown({
  schedulingMode,
  onChange,
}: {
  schedulingMode: SchedulingMode;
  onChange: (schedulingMode: SchedulingMode) => void;
}) {
  return (
    <Dropdown
      label="Availability"
      options={[
        { label: "Default", value: SCHEDULING_MODES.DEFAULT },
        { label: "Custom", value: SCHEDULING_MODES.CUSTOM },
      ]}
      value={schedulingMode}
      onChange={(event) => {
        onChange(event.target.value as SchedulingMode);
      }}
    />
  );
}

export default SchedulingModeDropdown;
