import { useMemo } from "react";
import {
  mdiAccountGroupOutline,
  mdiAccountOutline,
  mdiAccountSupervisorOutline,
  mdiAccountStarOutline,
  mdiEyeOutline,
} from "@mdi/js";
import { capitalize } from "../globalLogs/builders/util";

export const ROLE_LEVELS = {
  DEFAULT: 0,
  ORG_ADMIN: 2,
  SUPER_ADMIN: 3,
  SUPER_ADMIN_RO: 4,
  TEAM_ADMIN: 1,
};

const ROLE_LABELS = {
  DEFAULT: "Default",
  ORG_ADMIN: "Organization Admin",
  SUPER_ADMIN: "Super Admin",
  SUPER_ADMIN_RO: "Super Admin Read Only",
  TEAM_ADMIN: "Team Admin",
};

// used in dropdown
const ROLE_OPTIONS = [
  { id: ROLE_LEVELS.DEFAULT, value: ROLE_LABELS.DEFAULT },
  { id: ROLE_LEVELS.TEAM_ADMIN, value: ROLE_LABELS.TEAM_ADMIN },
  { id: ROLE_LEVELS.ORG_ADMIN, value: ROLE_LABELS.ORG_ADMIN },
  {
    id: ROLE_LEVELS.SUPER_ADMIN_RO,
    value: ROLE_LABELS.SUPER_ADMIN_RO,
  },
  { id: ROLE_LEVELS.SUPER_ADMIN, value: ROLE_LABELS.SUPER_ADMIN },
];

const LEVEL_LABEL = {
  [ROLE_LEVELS.DEFAULT]: ROLE_LABELS.DEFAULT,
  [ROLE_LEVELS.ORG_ADMIN]: ROLE_LABELS.ORG_ADMIN,
  [ROLE_LEVELS.SUPER_ADMIN]: ROLE_LABELS.SUPER_ADMIN,
  [ROLE_LEVELS.SUPER_ADMIN_RO]: ROLE_LABELS.SUPER_ADMIN_RO,
  [ROLE_LEVELS.TEAM_ADMIN]: ROLE_LABELS.TEAM_ADMIN,
};

const LABEL_LEVEL = {
  [ROLE_LABELS.DEFAULT]: ROLE_LEVELS.DEFAULT,
  [ROLE_LABELS.ORG_ADMIN]: ROLE_LEVELS.ORG_ADMIN,
  [ROLE_LABELS.SUPER_ADMIN]: ROLE_LEVELS.SUPER_ADMIN,
  [ROLE_LABELS.SUPER_ADMIN_RO]: ROLE_LEVELS.SUPER_ADMIN_RO,
  [ROLE_LABELS.TEAM_ADMIN]: ROLE_LEVELS.TEAM_ADMIN,
};

const LEVEL_ICONS = {
  [ROLE_LEVELS.DEFAULT]: mdiAccountOutline,
  [ROLE_LEVELS.ORG_ADMIN]: mdiAccountGroupOutline,
  [ROLE_LEVELS.SUPER_ADMIN]: mdiAccountStarOutline,
  [ROLE_LEVELS.SUPER_ADMIN_RO]: mdiEyeOutline,
  [ROLE_LEVELS.TEAM_ADMIN]: mdiAccountSupervisorOutline,
};

// ROLE_TYPE_HIERARCHY is an object of key: role/val: list of roles
// the KEY would be a given role, and the VAL would be a list of roles that the given role
// has permission of
// ex:
//	a TeamAdmin has all the permissions of a TeamAdmin and a Default, but NOT the permissions of an OrgAdmin
const ROLE_TYPE_HIERARCHY = {
  [ROLE_LEVELS.DEFAULT]: [ROLE_LEVELS.DEFAULT],
  [ROLE_LEVELS.ORG_ADMIN]: [
    ROLE_LEVELS.ORG_ADMIN,
    ROLE_LEVELS.TEAM_ADMIN,
    ROLE_LEVELS.DEFAULT,
  ],
  [ROLE_LEVELS.SUPER_ADMIN]: [
    ROLE_LEVELS.SUPER_ADMIN,
    ROLE_LEVELS.SUPER_ADMIN_RO,
    ROLE_LEVELS.ORG_ADMIN,
    ROLE_LEVELS.TEAM_ADMIN,
    ROLE_LEVELS.DEFAULT,
  ],
  [ROLE_LEVELS.SUPER_ADMIN_RO]: [
    ROLE_LEVELS.SUPER_ADMIN_RO,
    ROLE_LEVELS.ORG_ADMIN,
    ROLE_LEVELS.TEAM_ADMIN,
    ROLE_LEVELS.DEFAULT,
  ],
  [ROLE_LEVELS.TEAM_ADMIN]: [ROLE_LEVELS.TEAM_ADMIN, ROLE_LEVELS.DEFAULT],
};

function validRole(role) {
  const validRoles = Object.values(ROLE_LEVELS);

  for (let i = 0; i < validRoles.length; i += 1) {
    if (role === validRoles[i]) {
      return true;
    }
  }

  return false;
}

// standardizedRole takes a role, which may be an int or a string, and standardizes it to be an int
function standardizedRole(role) {
  if (typeof role === "number") {
    if (!validRole(role)) {
      return null;
    }

    return role;
  }

  if (typeof role === "string" && role.length > 0) {
    let titled = role
      .split(" ")
      .map((r) => capitalize(r.toLowerCase()))
      .join(" ");

    if (titled === "Org Admin") {
      titled = ROLE_LABELS.ORG_ADMIN;
    }
    const asInt = LABEL_LEVEL[titled];

    if (asInt === undefined || !validRole(asInt)) {
      return null;
    }

    return asInt;
  }

  return null;
}

// these are for safety when checking perms. we will always fallback the user's role to lowest on failure to find their role,
// and we will always fallback the required role to highest on failure to find the required role
const FALLBACK_LOWEST_ROLE = ROLE_LEVELS.DEFAULT;
const FALLBACK_HIGHEST_ROLE = ROLE_LEVELS.SUPER_ADMIN;

// supersedes returns whether or not the user's role meets and/or exceeds the required role
export function supersedes({
  has = FALLBACK_LOWEST_ROLE,
  required = FALLBACK_HIGHEST_ROLE,
} = {}) {
  const hasRole = standardizedRole(has);
  const requiredRole = standardizedRole(required);

  if (hasRole === null || requiredRole === null) {
    return false;
  }

  const allowedPerms = ROLE_TYPE_HIERARCHY[hasRole];

  for (let i = 0; i < allowedPerms.length; i += 1) {
    if (requiredRole === allowedPerms[i]) {
      return true;
    }
  }

  return false;
}

// rolesEqual determines whether or not two roles are equal by standardizing to their respective int values and then comparing
export function rolesEqual(r1, r2) {
  return standardizedRole(r1) === standardizedRole(r2);
}

export function getIconFromRole(r) {
  const role = standardizedRole(r);
  if (role === null) {
    return null;
  }
  return LEVEL_ICONS[role];
}

export function getLabelFromRole(r) {
  const role = standardizedRole(r);
  if (role === null) {
    return null;
  }

  return LEVEL_LABEL[role];
}

function roleBasedPermissions(r = FALLBACK_LOWEST_ROLE) {
  return ROLE_TYPE_HIERARCHY[standardizedRole(r)];
}

// useRoleOptions hook returns the roles a user is allowed to "see" given a highestOption
// the highestOption can be the user's role (in determining selectable roles for one
// user updating another user's role) or maybe a specific highest role, such as for inviting
// users to the platform
// we default to lowest perm
export function useRoleOptions({ highestOption = FALLBACK_LOWEST_ROLE }) {
  const selectableRoles = useMemo(() => {
    const roleOptions = [];

    const allowedPerms = roleBasedPermissions(highestOption);

    for (let i = 0; i < ROLE_OPTIONS.length; i += 1) {
      if (allowedPerms.indexOf(ROLE_OPTIONS[i].id) > -1) {
        roleOptions.push(ROLE_OPTIONS[i]);
      }
    }

    return roleOptions;
  }, [highestOption]);

  return {
    selectableRoles,
  };
}
