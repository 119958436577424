import React, { useEffect, useState } from "react";
import { ClickAwayListener, Typography, useTheme } from "@mui/material";

function OutlinedInput({
  label,
  children,
}: {
  label: string;

  children?: React.ReactNode;
}) {
  const theme = useTheme();

  const [active, setActive] = useState(false);
  const [borderColor, setBorderColor] = useState(theme.palette.text.secondary);

  useEffect(() => {
    if (active) setBorderColor(theme.palette.primary.main);
    else setBorderColor(theme.palette.text.secondary);
  }, [active, theme]);

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <fieldset
        style={{
          marginTop: "4px",
          borderColor,
          borderRadius: "5px",
          borderStyle: "solid",
          borderWidth: active ? "2px" : "1px",
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(1.25),
          paddingLeft: theme.spacing(1.25),
          paddingRight: theme.spacing(1.25),
        }}
        onMouseEnter={() => {
          if (!active) setBorderColor(theme.palette.text.primary);
        }}
        onMouseLeave={() => {
          if (!active) setBorderColor(theme.palette.text.secondary);
        }}
        onMouseDownCapture={() => {
          setActive(true);
        }}
      >
        <legend>
          <Typography
            variant="caption"
            sx={{
              color: active
                ? theme.palette.primary.main
                : theme.palette.text.secondary,

              px: 0.5,
            }}
          >
            {label}
          </Typography>
        </legend>

        {children}
      </fieldset>
    </ClickAwayListener>
  );
}

export default OutlinedInput;
