import React, { useState } from "react";
import { NotificationCenterControllers } from "./NotificationCenterControllers";
import style from "./style.module.scss";

const NotificationCenter = ({ children }) => {
  const [notifications, setNotifications] = useState(new Map([]));

  return (
    <>
      <NotificationCenterControllers setNotifications={setNotifications}>
        {children}
      </NotificationCenterControllers>

      <div className={style.notifications__wrapper}>
        {[...notifications.values()].map(({ Wrapper, body, props }) => (
          <Wrapper
            key={props.id}
            setNotifications={setNotifications}
            {...props}
          >
            {body}
          </Wrapper>
        ))}
      </div>
    </>
  );
};

export default NotificationCenter;
