import React, { useCallback } from "react";

import Card from "../../components/card/Card";
import style from "./style.module.scss";

export function EnvironmentURLInformation({ url }) {
  const openLink = useCallback(() => {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) {
      newTab.opener = null;
    }
  }, [url]);

  return (
    <>
      <Card
        contentClassName={style.env_url_content}
        className={style.env_url_content_style}
      >
        <div className={style.triangleDown} />
        <span className={style.env_url_body}>
          Environment URL is needed to connect with MS Dynamics. Typical format
          of environment URL: [org].crm[number].dynamics.com. If the URL is
          unknown, you can log in
          <a href="# " onClick={openLink}>
            here
          </a>
          and find the environment.
        </span>
      </Card>
    </>
  );
}
