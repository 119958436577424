import React from "react";

function AverageTouchesPointer() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#8497A9" fillOpacity="0.1" />
      <path
        d="M14.6874 5.41666C15.9513 5.41666 17.0312 5.63714 17.927 6.07811C18.8228 6.51909 19.2708 7.05555 19.2708 7.68749C19.2708 8.0486 19.1145 8.38888 18.802 8.70832C18.4895 9.02777 18.0485 9.2986 17.4791 9.52082H16.7708V8.99999C17.1735 8.84027 17.4826 8.64582 17.6978 8.41666C17.9131 8.18749 18.0208 7.94443 18.0208 7.68749C18.0208 7.22916 17.6944 6.84027 17.0416 6.52082C16.3888 6.20138 15.6041 6.04166 14.6874 6.04166C13.7708 6.04166 12.986 6.20138 12.3333 6.52082C11.6805 6.84027 11.3541 7.22916 11.3541 7.68749C11.3541 7.94443 11.4617 8.18749 11.677 8.41666C11.8923 8.64582 12.2013 8.84027 12.6041 8.99999V9.74999C11.8263 9.5486 11.2152 9.26388 10.7708 8.89582C10.3263 8.52777 10.1041 8.12499 10.1041 7.68749C10.1041 7.05555 10.552 6.51909 11.4478 6.07811C12.3437 5.63714 13.4235 5.41666 14.6874 5.41666ZM13.8958 14.1667C13.6596 14.1667 13.4374 14.1441 13.2291 14.0989C13.0208 14.0538 12.8402 13.993 12.6874 13.9167L8.39575 11.7708L9.56242 11.1667C9.75686 11.0694 9.97561 10.9948 10.2187 10.9427C10.4617 10.8906 10.7152 10.8819 10.9791 10.9167L12.6041 11.1042V7.70832C12.6041 7.41666 12.8055 7.17013 13.2083 6.96874C13.611 6.76735 14.1041 6.66666 14.6874 6.66666C15.2708 6.66666 15.7638 6.76735 16.1666 6.96874C16.5694 7.17013 16.7708 7.41666 16.7708 7.70832V9.49999H17.3124C17.3819 9.49999 17.4444 9.50693 17.4999 9.52082C17.5555 9.53471 17.618 9.5486 17.6874 9.56249L20.7708 10.3125C21.1041 10.3889 21.3506 10.5121 21.5103 10.6823C21.6701 10.8524 21.7152 11.0278 21.6458 11.2083L20.8958 13.4792C20.8263 13.6805 20.6319 13.8455 20.3124 13.9739C19.993 14.1024 19.6319 14.1667 19.2291 14.1667H13.8958ZM13.7291 13.5417H19.5833L20.4791 10.9479L16.6666 9.99999H15.5208V7.70832C15.5208 7.58332 15.4444 7.48263 15.2916 7.40624C15.1388 7.32985 14.9374 7.29166 14.6874 7.29166C14.4374 7.29166 14.236 7.32985 14.0833 7.40624C13.9305 7.48263 13.8541 7.58332 13.8541 7.70832V11.8646L10.6458 11.5208L10.1666 11.7604L13.7291 13.5417Z"
        fill="#424242"
      />
    </svg>
  );
}

export default AverageTouchesPointer;
