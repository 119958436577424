import React, { useMemo } from "react";
import Icon from "@mdi/react";
import cx from "classnames";
import { mdiCircle } from "@mdi/js";
import Flexbox from "../../../components/flexbox";
import { P1 } from "../../../components/typography";
import { useTableRowMeeting } from "../../context";
import style from "./guestAttempts.module.scss";

function GuestAttempts() {
  const meeting = useTableRowMeeting();

  const attempts = useMemo(() => {
    if (!meeting) {
      return [];
    }

    const { attempts: meetingAttempts = null } = meeting;

    if (!meetingAttempts || meetingAttempts.length === 0) {
      return null;
    }

    const { current = 0, total = 1 } = meetingAttempts;

    return [...Array(total).keys()].map((attempt) => {
      return {
        id: attempt,
        isAttempted: attempt < current,
      };
    });
  }, [meeting]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row width="17%">
          {attempts &&
            attempts.length < 4 &&
            attempts.map(({ id: attemptId, isAttempted }) => (
              <Icon
                className={cx({
                  [style.guestAttempts_attempted]: isAttempted,
                  [style.guestAttempts_attempt]: !isAttempted,
                })}
                key={attemptId}
                path={mdiCircle}
                size={0.5}
              />
            ))}

          {attempts && attempts.length >= 4 && (
            <P1>{`${meeting?.attempts?.current} of ${meeting?.attempts?.total}`}</P1>
          )}
        </Row>
      )}
    </Flexbox>
  );
}

export default GuestAttempts;
