import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import { Box, TextField } from "@mui/material";

import { useDatePicker } from "../../../components/datepicker";
import Dropdown from "src/componentsV2/Dropdown";

export type TimeFilterBy = "lastActivity" | "meetingTime";

export function TimeFilter({
  range = "all_time",
  onRangeChange,
  filterBy = "lastActivity",
  onFilterByChange,
  value = [null, null],
  onChange,
}: {
  range?: string;
  onRangeChange?: (value: string) => void;
  filterBy?: TimeFilterBy;
  onFilterByChange?: (value: TimeFilterBy) => void;
  value: DateRange<Dayjs>;
  onChange?: (newValue: DateRange<Dayjs>) => void;
}) {
  const { getDateRangeWithKey } = useDatePicker();

  return (
    <>
      <Dropdown
        label="Filter By"
        value={filterBy}
        options={[
          { label: "Last Activity", value: "lastActivity" },
          { label: "Start Time", value: "meetingTime" },
        ]}
        onChange={(event) =>
          onFilterByChange?.(event.target.value as TimeFilterBy)
        }
      />
      <Dropdown
        label="Time Range"
        value={range}
        options={[
          { value: "all_time", label: "All time" },

          { value: "custom", label: "Custom" },

          { value: "past", label: "Past" },
          { value: "future", label: "Future" },

          { value: "this_year", label: "This Year" },
          { value: "next_year", label: "Next Year" },
          { value: "last_year", label: "Last Year" },
          { value: "year_to_date", label: "Year to Date" },

          { value: "this_quarter", label: "This Quarter" },
          { value: "next_quarter", label: "Next Quarter" },
          { value: "last_quarter", label: "Last Quarter" },
          { value: "quarter_to_date", label: "Quarter to Date" },

          { value: "this_month", label: "This Month" },
          { value: "next_month", label: "Next Month" },
          { value: "last_month", label: "Last Month" },
          { value: "month_to_date", label: "Month to Date" },

          { value: "this_week", label: "This Week" },
          { value: "next_week", label: "Next Week" },
          { value: "last_week", label: "Last Week" },
          { value: "week_to_date", label: "Week to Date" },

          { value: "today", label: "Today" },
          { value: "tomorrow", label: "Tomorrow" },
          { value: "yesterday", label: "Yesterday" },
          { value: "last_24_hours", label: "Last 24 Hours" },
        ]}
        onChange={(event) => {
          if (onChange) {
            if (onRangeChange) {
              onRangeChange(event.target.value);
            }
            const { start, end } = getDateRangeWithKey(event.target.value);
            onChange([dayjs(start?.toISOString()), dayjs(end?.toISOString())]);
          }
        }}
      />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{
          start: "Start",
          end: "End",
        }}
      >
        <DateRangePicker
          calendars={1}
          value={value}
          onChange={
            onChange ||
            function () {
              return;
            }
          }
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default TimeFilter;
