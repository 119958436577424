import { useMemo } from "react";

import {
  PRIMARY_BLUE,
  BLACK,
  WHITE,
  NEUTRAL_500,
} from "../../../../components/colors";
import {
  ClearIndicator,
  DropdownIndicator,
} from "../../../../components/select";
import { useTheme } from "../../../../components/theme";

export function useConditionSelectTheme({
  left: isLeft = false,
  right: isRight = false,
} = {}) {
  const { useThemeShadows } = useTheme();
  const { MD } = useThemeShadows();
  const borderRadius = useMemo(() => {
    if (isLeft) {
      return "4px 0 0 4px";
    }
    if (isRight) {
      return "0 4px 4px 0";
    }
    return "0px";
  }, [isLeft, isRight]);

  return useMemo(() => {
    return [
      { ClearIndicator, DropdownIndicator },
      {
        container: (base, { isDisabled }) => ({
          ...base,
          border: `1px solid ${NEUTRAL_500}`,
          backgroundColor: WHITE,
          borderRadius,
          maxWidth: isRight ? null : "225px",
          minWidth: "225px",
          opacity: isDisabled ? "0.4" : null,
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          backgroundColor: WHITE,
          border: 0,
          borderRadius,
          height: "38px",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          marginBottom: "10px",
          marginTop: "10px",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: null,
          boxShadow: MD,
          marginTop: "10px",
        }),
        menuList: (base) => ({
          ...base,
          backgroundColor: WHITE,
          borderRadius: "4px",
          boxShadow: "none",
          padding: "0px",
          width: "fit-content",
        }),
        option: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isFocused ? PRIMARY_BLUE : WHITE,
          color: isFocused ? WHITE : BLACK,
          cursor: "pointer",
          overflow: "hidden",
          padding: "7px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
        placeholder: (base) => ({
          ...base,
          color: NEUTRAL_500,
        }),
        singleValue: (base) => ({
          ...base,
          color: BLACK,
          width: "100%",
        }),
        valueContainer: (base) => ({
          ...base,
          justifyContent: "center",
          margin: "0 0 0 0.6em",
        }),
      },
    ];
  }, [borderRadius, isRight, MD]);
}
