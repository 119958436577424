import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "@mdi/react";
import style from "./style.module.scss";

const EmptyView = ({ view, icon, actions }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className={style.emptyView}>
          <Icon path={icon} size={10} color={null} />
          <div>
            <span className={style.viewName}>{view}</span>
            <span>has no data available.</span>
          </div>
          <div className={style.actions}>{actions}</div>
        </div>
      </div>
    </div>
  );
};

EmptyView.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.element),
  icon: PropTypes.string,
  view: PropTypes.string,
};

EmptyView.defaultProps = {
  actions: null,
  icon: "",
  view: "",
};

const LoadingTable = ({ className }) => {
  return (
    <div className={cx("container is--fluid", className)}>
      <div className="row">
        <div className="col-12">
          <div className={style.skeleton} />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className={style.skeleton} />
        </div>
        <div className="col-4">
          <div className={style.skeleton} />
        </div>
        <div className="col-4">
          <div className={style.skeleton} />
        </div>
      </div>
    </div>
  );
};

const LoadingCard = ({ height, className }) => (
  <div className={cx(style.skeleton, className)} style={{ height }} />
);

LoadingCard.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LoadingCard.defaultProps = {
  height: "100%",
};

export { EmptyView, LoadingCard, LoadingTable };
