import React from "react";
import { mdiFilterRemoveOutline } from "@mdi/js";
import { Button, ButtonIcon } from "../../../components/button";
import Flexbox from "../../../components/flexbox";
import style from "../menu.module.scss";

export function ClearFiltersButton({ disabled, onClick }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column
          className={style.instanceMenu__clearFiltersButtonBorder}
          width="40px"
          height="40px"
        >
          <Button
            className={style.instanceMenu__clearFiltersButton}
            data-rh="Clear Filters"
            data-rh-at="top"
            disabled={disabled}
            name="/instances/action_bar/clear_filters"
            onClick={onClick}
          >
            <ButtonIcon path={mdiFilterRemoveOutline} />
          </Button>
        </Column>
      )}
    </Flexbox>
  );
}
