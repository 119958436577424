import React from "react";
import salesforceImg from "../../assets/salesforce.svg";
import style from "./style.module.scss";

export function Logo() {
  return (
    <header>
      <img src={salesforceImg} alt="salesforce" className={style.logo} />
    </header>
  );
}
