import React from "react";
import Flexbox from "../../../components/flexbox";
import { TableCell } from "../../../components/tableV5/GridTable";
import { MeetingActivationSwitch } from "../../../components/meetingActivationSwitch";
import style from "./tableCells.module.scss";

function ActivateMeeting({ onToggle, meeting, disabled }) {
  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell className={style.tableCells}>
          <Column>
            <MeetingActivationSwitch
              meeting={meeting}
              handleToggle={onToggle}
              name="/instances/action_bar/add_new_instance/activate"
              disabled={disabled}
            />
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
}

export default ActivateMeeting;
