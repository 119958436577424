import React, { useMemo } from "react";
import cx from "classnames";

import style from "./style.module.scss";

export function TagListItem({ name, onClick, size = 1, disabled = false }) {
  const sizeStyle = useMemo(() => {
    return {
      fontSize: `${size * 1.5}rem`,
    };
  }, [size]);
  return (
    <div className={style.tagListItem__wrapper}>
      <button
        type="button"
        className={cx(style.tagListItem, {
          [style.tagListItem__disabled]: disabled,
        })}
        onClick={onClick}
        style={sizeStyle}
        disabled={disabled}
      >
        <div className={style.tagListItem__nameText}>{name}</div>
      </button>
    </div>
  );
}
