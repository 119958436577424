import React from "react";
import { Checkbox } from "../../components/checkbox";
import style from "./style.module.scss";

export function ToggleAllMeetings({ checked, onToggle }) {
  return (
    <Checkbox
      className={style.toggleAllMeetings__checkbox}
      name="/instances/action_bar/select_all"
      checked={checked}
      toggle={() => onToggle && onToggle(!checked)}
      iconSize={2}
    />
  );
}
