import { mdiAccountKeyOutline, mdiAccountOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Grid } from "@mui/material";
import { ActionButton } from "../../../componentsV2/ActionButton";
import { PageLayout } from "../../../componentsV2/PageLayout";
import { useIsDefaultUser } from "src/auth";

function Import() {
  const isDefaultUser = useIsDefaultUser();

  return (
    <PageLayout
      title="Import"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Import", route: "/reports/import" },
      ]}
    >
      <Grid
        container
        sx={{ justifyContent: "center", alignItems: "center", gap: 4 }}
      >
        <Grid item>
          <ActionButton
            title="Contacts"
            icon={<Icon path={mdiAccountOutline} size={3} />}
            to="/reports/import/contacts"
          />
        </Grid>
        {!isDefaultUser && (
          <Grid item>
            <ActionButton
              title="Users"
              icon={<Icon path={mdiAccountKeyOutline} size={3} />}
              to="/reports/import/users"
            />
          </Grid>
        )}
      </Grid>
    </PageLayout>
  );
}

export default Import;
