import React from "react";
import cx from "classnames";

import style from "./style.module.scss";

function TextArea({ placeholder, disabled, onInput, className, ...rest }) {
  return (
    <textarea
      className={cx(style.textarea, className)}
      disabled={disabled}
      onInput={onInput}
      placeholder={placeholder}
      {...rest}
    />
  );
}

export default TextArea;
