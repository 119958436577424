import React from "react";

import Card from "../components/card/Card";
import { H5 } from "../components/typography";
import style from "./style.module.scss";

export function IntegrationCard({ children, enabled }) {
  return (
    <Card
      type="dark"
      className={[style.integration, !enabled ? style.disabled : ""]}
    >
      <div className={style.content}>{children}</div>
    </Card>
  );
}

export function IntegrationCardTitle({ children }) {
  return <H5 className={style.title}>{children}</H5>;
}

export function IntegrationCardButtonArea({ children }) {
  return <footer>{children}</footer>;
}
