import React from "react";
import InstanceDetailsPage from "./InstanceDetails";
import StateManager from "./state";

const InstanceDetails = (props) => (
  <StateManager>
    <InstanceDetailsPage {...props} />
  </StateManager>
);

export default InstanceDetails;
