import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useDebounce } from "../../../hooks";
import { useMeetingTemplates, SkinnyMeetingDefinition } from "../../../queries";
import { Checkbox, ListItem, Typography } from "@mui/material";

function MeetingTemplateFilter({
  value,
  onChange,
  label = "Meeting Template",
}: {
  value: SkinnyMeetingDefinition[];
  onChange: (value: SkinnyMeetingDefinition[]) => void;
  label?: string | undefined;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const query = useDebounce(input, 750);

  const filters = {
    templateName: query,
  };

  const { data, loading } = useMeetingTemplates(100, 0, filters);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      onInputChange={(_, value) => {
        setInput(value);
      }}
      onChange={(_, value) => {
        onChange(value);
      }}
      multiple
      value={value}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => !option.active}
      options={data?.data || []}
      loading={loading}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} dense>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography>{option.name}</Typography>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>{loading && <CircularProgress color="inherit" size={20} />}</>
            ),
          }}
        />
      )}
    />
  );
}

export default MeetingTemplateFilter;
