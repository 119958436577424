import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

export function BorderBox({ children }: { children?: React.ReactNode }) {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: mobile
          ? "transparent"
          : theme.palette.background.default,
        border: 16,
        borderColor: mobile ? "transparent" : theme.palette.background.paper,
        height: "fit-content",
        padding: theme.spacing(2),
      }}
    >
      {children}
    </Box>
  );
}

export default BorderBox;
