import React, { useEffect, useState } from "react";
import cx from "classnames";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { P1 } from "../../components/typography";
import {
  GuestProvisioning,
  GuestActive,
  GuestFinal,
  InstanceProvisioning,
  InstanceActive,
  InstanceFinal,
} from "./cards";
import style from "./reporting.module.scss";
import { XLargeSpacing, TinySpacing } from "../../components/spacing";
import { useMeetingStats } from "../../queries";
import { useSearch } from "../Search";

const DEFAULT_STATS = {
  final: {
    cancelled: 0,
    completed: 0,
    noQuorum: 0,
  },
  guests: {
    acceptPendingQuorum: 0,
    accepted: 0,
    aiNegotiating: 0,
    awaitingResponse: 0,
    declined: 0,
    hostIntervened: 0,
    noResponse: 0,
    requiresHostIntervention: 0,
    respondedUnknownIntent: 0,
  },
  initializing: {
    fault: 0,
    initializing: 0,
    ready: 0,
    total: 0,
  },
  scheduling: {
    queued: 0,
    scheduled: 0,
    scheduling: 0,
  },
};

const Reporting = () => {
  const [stats, setStats] = useState(DEFAULT_STATS);

  const search = useSearch();

  const { data: resources, loading } = useMeetingStats(search, {
    tags: [],
  });

  const areResultsLoaded = !loading;

  useEffect(() => {
    if (resources !== null) {
      setStats((prev) => ({
        ...prev,
        ...resources,
      }));
    } else {
      setStats(DEFAULT_STATS);
    }
  }, [resources]);

  return (
    <Flexbox.Column key="report">
      <TinySpacing />
      <Flexbox.Row className={cx(style.header)}>
        {/* Hidden and used for spacing */}
        <XLargeSpacing horizontal />
        <Flexbox.Column flex={1} alignItems="center">
          <P1 className={style.header__small} extraSmall>
            PROVISIONING
          </P1>
        </Flexbox.Column>
        <Flexbox.Column flex={2} alignItems="center">
          <P1 className={style.header__large} extraSmall>
            ACTIVE
          </P1>
        </Flexbox.Column>
        <Flexbox.Column flex={1} alignItems="center">
          <P1 className={style.header__small} extraSmall>
            FINAL
          </P1>
        </Flexbox.Column>
      </Flexbox.Row>

      <Flexbox.Row alignItems="center">
        <TinySpacing />
        <P1 className={style.row_title} extraSmall>
          GUEST STATUSES
        </P1>

        <GuestProvisioning isLoading={!areResultsLoaded} {...stats.guests} />
        <GuestActive isLoading={!areResultsLoaded} {...stats.guests} />
        <GuestFinal isLoading={!areResultsLoaded} {...stats.guests} />
      </Flexbox.Row>

      <Flexbox.Row alignItems="center">
        <TinySpacing />
        <P1 className={style.row_title} extraSmall>
          MEETING STAGES
        </P1>

        <InstanceProvisioning
          isLoading={!areResultsLoaded}
          {...stats.initializing}
        />
        <InstanceActive isLoading={!areResultsLoaded} {...stats.scheduling} />
        <InstanceFinal isLoading={!areResultsLoaded} {...stats.final} />
      </Flexbox.Row>
    </Flexbox.Column>
  );
};

export default Reporting;
