import React from "react";
import { ButtonContainer } from "../../../../components/button";
import style from "./guestFinal.module.scss";

export function DrillDownButton({ text, name, onClick, disabled }) {
  return (
    <ButtonContainer
      name={name}
      onClick={onClick}
      disabled={disabled}
      secondary
      small
      transparent
      className={style.final_button}
    >
      {text}
    </ButtonContainer>
  );
}
