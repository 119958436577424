import React, { useState } from "react";
import {
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  ListItem,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { MeetingStatusOption, MeetingStatusOptions } from "../../../types";

function MeetingStatusFilter({
  value,
  onChange,
}: {
  value?: MeetingStatusOption[];
  onChange?: (v: MeetingStatusOption[]) => void;
}) {
  const [input, setInput] = useState("");

  return (
    <Autocomplete
      fullWidth
      multiple
      disablePortal
      inputValue={input}
      onInputChange={(_, newValue) => {
        setInput(newValue);
      }}
      value={value}
      onChange={(_, newValue) => {
        if (value && onChange) {
          onChange(newValue);
        }
      }}
      options={MeetingStatusOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} dense>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography>{option.label}</Typography>
        </ListItem>
      )}
      renderInput={(params) => <TextField {...params} label="Meeting Status" />}
    />
  );
}

export default MeetingStatusFilter;
