import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

export function MetricsGrid({ children }: { children?: React.ReactNode }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid
      container
      spacing={2}
      columns={lg ? 4 : md ? 3 : sm ? 2 : 1}
      // https://github.com/mui/material-ui/issues/29266#issuecomment-1001989031
      style={{ marginLeft: "-16px" }}
    >
      {children}
    </Grid>
  );
}
