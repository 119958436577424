import React from "react";
import Icon from "@mdi/react";
import { mdiRefresh } from "@mdi/js";

import { IntegrationButton } from "./integrationButton";

export function ReauthorizeButton({ name, onClick, disabled }) {
  return (
    <IntegrationButton name={name} onClick={onClick} disabled={disabled}>
      <Icon size={1} path={mdiRefresh} />
      Reauthorize
    </IntegrationButton>
  );
}
