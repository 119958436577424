import React from "react";
import { Stack, Typography, Link, useTheme, Card, Box } from "@mui/material";
import loaderSolid from "../../assets/LoaderSolid.png";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { OneClickBackground } from "../../componentsV2/OneClickBackground";
import { useLocation } from "react-router-dom";
import OneClickLogoImage from "../../componentsV2/OneClickLogoImage";

export default function Page() {
  const theme = useTheme();
  const url = useLocation<{ url: string }>().state?.url;

  return (
    <OneClickBackground>
      <Card
        sx={{
          zIndex: 10,
          px: "50px",
          py: "30px",
          maxWidth: "448px",
          width: "100%",
          minHeight: "440px",
        }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Box sx={{ marginBottom: "25px" }}>
            {url ? (
              <a href={url}>
                <OneClickLogoImage />
              </a>
            ) : (
              <OneClickLogoImage />
            )}
          </Box>
          <Typography variant="h4" align="center" sx={{ marginBottom: "10px" }}>
            Pending
          </Typography>
          <Typography align="center" sx={{ marginBottom: "20px" }}>
            Your meeting is already being scheduled!
          </Typography>

          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <img
              height="139px"
              width="139px"
              style={{ position: "absolute" }}
              src={loaderSolid}
            />
            <PriorityHighIcon
              sx={{ height: "79px", width: "79px", marginY: "44px" }}
              htmlColor={theme.palette.primary.dark}
            />
          </Stack>

          <Typography variant="caption" align="center">
            We apologize for the inconvenience. If you need immediate assistance
            please email us at:{" "}
            <Link href="mailto:support@kronologic.ai">
              support@kronologic.ai
            </Link>
          </Typography>
        </Stack>
      </Card>
    </OneClickBackground>
  );
}
