import React, { createContext } from "react";
import { useFeatureFlags } from "src/queries";

export const FeaturesContext = createContext();

export function OrgFeatures({ children }) {
  const { data: features, error, loading: isLoading } = useFeatureFlags();

  return (
    <FeaturesContext.Provider
      value={{
        data: features,
        error,
        isLoading,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
}

export function Features({ children }) {
  return <OrgFeatures>{children}</OrgFeatures>;
}
