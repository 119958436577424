import React from "react";

import outreachImg from "../../assets/outreach.svg";
import style from "./style.module.scss";

export function Logo() {
  return (
    <header>
      <img src={outreachImg} alt="outreach" className={style.logo} />
    </header>
  );
}
