import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/button/Button";

import style from "./style.module.scss";

function GoToButton({ text, url }) {
  return (
    <div className={style.linkButtonContainer}>
      <Button type="tertiary">
        <Link className={style.channels__link} to={url}>
          <span>{text}</span>
        </Link>
      </Button>
    </div>
  );
}

export function GoToMeetingTypes() {
  return <GoToButton url="/meetingTypes" text="Create Meeting Template" />;
}

export function GoToIntegrations() {
  return <GoToButton url="/integrations" text="Create Integration" />;
}
