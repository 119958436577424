import { RocketLaunch } from "@mui/icons-material";
import { useAtom } from "jotai";
import { showQuickLaunchAtom } from "src/componentsV2/QuickLaunch";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import { QuickLaunchDialog } from "./dialogs/QuickLaunchDialog";

function KMeQuickLaunch({ disabled }: { disabled?: boolean }) {
  const [showQuickLaunch, setShowQuickLaunch] = useAtom(showQuickLaunchAtom);

  return (
    <>
      <PrimaryButton
        icon={<RocketLaunch />}
        disabled={disabled}
        onClick={() => setShowQuickLaunch(true)}
      >
        Launcher
      </PrimaryButton>

      <QuickLaunchDialog
        dialog
        open={showQuickLaunch}
        onClose={() => setShowQuickLaunch(false)}
      />
    </>
  );
}

export default KMeQuickLaunch;
