import { useUserService } from "../services";

export function useUnsubscribe() {
  const service = useUserService();

  return (org: string, meeting: string, contact: string): Promise<void> =>
    service
      .post(`/api/contact/unsubscribe`)
      .send({ c: contact, m: meeting, o: org })
      .then((res: Response) => res.body);
}
