import React from "react";

import { Error } from "./Error";

export function InvalidUrlError({
  referenceId,
  onOkClicked,
}: {
  onOkClicked: () => void;
  referenceId?: string;
}) {
  return (
    <Error
      message="Hmm... that URL doesn't look valid."
      referenceId={referenceId}
      onOkClicked={onOkClicked}
    />
  );
}
