import { useDebounce } from "src/hooks";
import { useTags } from "src/queries";
import { Tag } from "src/types";

import { FilterAutocomplete } from "./FilterAutocomplete";

export function TagFilter(props: {
  label?: string | undefined;
  search: string;
  onSearchChange: (value: string) => void;
  value?: Tag[];
  onChange?: (value: Tag[]) => void;
}) {
  const s = useDebounce(props.search, 750);

  const { data, loading } = useTags(10, 0, s);

  return (
    <FilterAutocomplete
      label={props.label || "Tag"}
      value={props.value}
      onChange={props.onChange}
      loading={loading}
      search={props.search}
      onSearchChange={props.onSearchChange}
      options={data?.data || []}
      total={data?.total}
      getOptionLabel={(tag) => tag.name}
      getOptionId={(tag) => tag.id}
    />
  );
}

export default TagFilter;
