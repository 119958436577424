import React from "react";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";

import { Close } from "@mui/icons-material";

function Dialog({
  open,
  title,
  actions,
  fullScreen,
  customHeader,
  maxWidth,
  minHeight,
  onClose,
  children,
}: {
  open: boolean;
  title: string;
  actions?: React.ReactNode;
  fullScreen?: boolean;
  customHeader?: React.ReactNode;
  maxWidth?: "sm" | "md" | "lg" | "xl";
  minHeight?: number | string;
  onClose?: () => void;
  children?: React.ReactNode;
}) {
  const theme = useTheme();

  return (
    // Update the z-index for any pop up menus so that they appear on top of the dialog.
    <ThemeProvider
      theme={{
        ...theme,
        components: {
          ...theme.components,
          MuiAutocomplete: {
            styleOverrides: {
              popper: {
                zIndex: 1000000,
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              root: {
                zIndex: 1000000,
              },
            },
          },
        },
      }}
    >
      <MuiDialog
        open={open}
        onClose={onClose}
        disablePortal
        fullScreen={fullScreen}
        fullWidth
        maxWidth={maxWidth}
        PaperProps={{
          sx: {
            minHeight,
          },
        }}
      >
        {customHeader ? (
          customHeader
        ) : (
          <DialogTitle
            sx={{
              alignItems: "center",
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              pb: 4,
            }}
          >
            <Typography variant="h5">{title}</Typography>

            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        {actions && <DialogActions sx={{ p: 3 }}>{actions}</DialogActions>}
      </MuiDialog>
    </ThemeProvider>
  );
}

export default Dialog;
