import React from "react";
import cx from "classnames";

import { ButtonContainer } from "../../../components/button/ButtonV2";
import style from "./style.module.scss";

export function IntegrationButton({
  className,
  children,
  name,
  disabled,
  onClick,
}) {
  return (
    <ButtonContainer
      className={cx(style.integrationCard__button, className)}
      primary
      large
      onClick={onClick}
      name={name}
      disabled={disabled}
    >
      {children}
    </ButtonContainer>
  );
}
