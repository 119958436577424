import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Role } from "src/types";
import { useSessionStorage } from "../hooks";

export type ActingAsInfo = {
  firstName: string;
  lastName: string;
  org: string;
  role: Role;
  id: number;
  email: string;
} | null;

const ActingAsContext = createContext<
  [ActingAsInfo, (value: ActingAsInfo) => void]
>([null, () => null]);

export function useActingAs() {
  return useContext(ActingAsContext);
}

export function ActingAsProvider({ children }: { children?: ReactNode }) {
  const [actingAs, setActingAs] = useSessionStorage("ACTING_AS", null);

  const [value, setValue] = useState<ActingAsInfo>(actingAs);

  useEffect(() => {
    setActingAs(value);
  }, [value, setActingAs]);

  return (
    <ActingAsContext.Provider value={[value, setValue]}>
      {children}
    </ActingAsContext.Provider>
  );
}
