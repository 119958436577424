import React from "react";
import { mdiClose, mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import Flexbox from "../../../components/flexbox";
import { NEUTRAL_700 } from "../../../components/colors";
import { ButtonContainer, ButtonIcon } from "../../../components/button";
import style from "./newTableCells.module.scss";

const SaveMeeting = ({ isSaving, closeNewInstanceMenu }) => {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.cell}>
          {!isSaving && (
            <ButtonContainer
              data-rh="Close"
              data-rh-at="top"
              name="/instances/action_bar/add_new_instance/close"
              onClick={closeNewInstanceMenu}
              small
              transparent
            >
              <ButtonIcon color={NEUTRAL_700} path={mdiClose} />
            </ButtonContainer>
          )}

          {isSaving && <Icon path={mdiLoading} size={1} spin={2} />}
        </Column>
      )}
    </Flexbox>
  );
};

export default SaveMeeting;
