import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useHasSuperAdminPermissions } from "src/auth";

export default function SettingsBuild() {
  const hasSuperAdminPermissions = useHasSuperAdminPermissions();
  return (
    <Card>
      <CardHeader
        title={<Typography variant="h4">Web UI Version</Typography>}
      />
      <CardContent>
        <Stack sx={{ gap: 3 }}>
          {hasSuperAdminPermissions && (
            <Stack sx={{ gap: 2, flexDirection: "row" }}>
              <Typography fontWeight="bold">Branch:</Typography>
              <Typography>{BRANCH || "president"}</Typography>
            </Stack>
          )}
          <Stack sx={{ gap: 2, flexDirection: "row" }}>
            <Typography fontWeight="bold">Build Date:</Typography>
            <Typography>{DATE || "08-04-1961"}</Typography>
          </Stack>
          <Stack sx={{ gap: 2, flexDirection: "row" }}>
            <Typography fontWeight="bold">Commit ID:</Typography>
            <Typography>{COMMIT || "1"}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
