import React, { useMemo } from "react";
import cx from "classnames";
import { P1 } from "../../components/typography";
import Flexbox from "../../components/flexbox";
import style from "./icons.module.scss";

const WIDTH = "30px";
const HEIGHT = "30px";

export const QuorumIcon = ({
  className,
  type,
  width = WIDTH,
  height = HEIGHT,
  size = { small: true },
  ...props
}) => {
  const label = useMemo(() => {
    switch (type) {
      case "guest":
        return "G";
      default:
        throw new Error(`Type: ${type} is not a valid quorum type`);
    }
  }, [type]);

  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.quorumIcon} width="fit-content">
          <Column
            className={cx(style.quorumIcon__icon, className)}
            width={width}
            height={height}
            {...props}
          >
            <P1 className={style.quorumIcon__text} small={size.small}>
              {label}
            </P1>
          </Column>
        </Column>
      )}
    </Flexbox>
  );
};
