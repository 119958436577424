import React from "react";
import Flexbox from "../../../components/flexbox";
import { Switch } from "../../../components/switch";
import style from "./newTableCells.module.scss";

function ActivateMeeting() {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.cell}>
          <Switch isOn={false} disabled />
        </Column>
      )}
    </Flexbox>
  );
}

export default ActivateMeeting;
