import React from "react";
import cx from "classnames";
import { mdiPageFirst, mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import shortid from "shortid";

import pagination from "../../utils/table";
import { ButtonContainer } from "../../components/button";
import Dropdown from "../../components/dropdown/Dropdown";
import { usePagination } from "../../components/pagination";
import style from "./pagination.module.scss";

const NO_PAGE_SIZE_OPTIONS = [];

const BUTTON_SIZE = 0.7;

export function Pagination({
  disabled = false,
  total,
  offset,
  setOffset,
  pageSize,
  pageSizeOptions = NO_PAGE_SIZE_OPTIONS,
  setPageSize = null,
}) {
  const {
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPage,
    goToPreviousPage,
    hasNextPage,
    hasPreviousPage,
    pageIndex,
    pageOptions,
  } = usePagination(total, offset, setOffset, pageSize);
  return (
    <div className={style.pagination}>
      <div className={style.controls}>
        <ButtonContainer
          className={style.firstPage__button}
          secondary
          onClick={goToFirstPage}
          disabled={!hasPreviousPage || disabled}
        >
          <Icon path={mdiPageFirst} size={BUTTON_SIZE} />
        </ButtonContainer>

        <ButtonContainer
          className={style.prevPage__button}
          secondary
          onClick={goToPreviousPage}
          disabled={!hasPreviousPage || disabled}
        >
          <Icon path={mdiChevronLeft} size={BUTTON_SIZE} />
        </ButtonContainer>

        <div className={style.pages}>
          {pagination(pageIndex + 1, pageOptions.length).map((i) => {
            if (i !== "...") {
              return (
                <ButtonContainer
                  transparent
                  key={`page-${i}`}
                  className={cx(style.pageNumbers, {
                    [style["pageNumbers--active"]]: pageIndex === i - 1,
                  })}
                  disabled={disabled}
                  type="tertiary"
                  onClick={() => goToPage(i - 1)}
                >
                  {i}
                </ButtonContainer>
              );
            }

            return (
              <span
                className={style.elipsis}
                key={`page-${i}-${shortid.generate()}`}
              >
                {i}
              </span>
            );
          })}
        </div>

        <ButtonContainer
          className={style.nextPage__button}
          secondary
          onClick={goToNextPage}
          disabled={!hasNextPage || disabled}
        >
          <Icon path={mdiChevronLeft} rotate={180} size={BUTTON_SIZE} />
        </ButtonContainer>

        <ButtonContainer
          className={style.lastPage__button}
          secondary
          onClick={goToLastPage}
          disabled={!hasNextPage || disabled}
        >
          <Icon path={mdiPageFirst} rotate={180} size={BUTTON_SIZE} />
        </ButtonContainer>
      </div>
      {pageSizeOptions.length > 1 && setPageSize !== null && (
        <Dropdown
          labelProp="value"
          valueProp="value"
          onSelect={(item) => setPageSize(item.value)}
          data={pageSizeOptions}
          selectItem={pageSize}
        />
      )}
    </div>
  );
}
