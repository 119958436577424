const formatWordForPossession = (word) => {
  if (word.length < 1) {
    return word;
  }

  if (word[word.length - 1] === "s") {
    return `${word}'`;
  }

  return `${word}'s`;
};

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

export { formatWordForPossession, capitalize };
