export type TokenStatus = "current" | "expired" | "disabled";

export interface TokenStatusOption {
  label: string;
  value: TokenStatus;
}

export const TokenStatusOptions: TokenStatusOption[] = [
  { label: "Connected", value: "current" },
  { label: "Expired", value: "expired" },
  { label: "Not Connected", value: "disabled" },
];

export const tokenStatusList: TokenStatus[] = [
  "current",
  "expired",
  "disabled",
];
