import React, { useState } from "react";
import { Box, Container, Step, StepLabel, Stepper } from "@mui/material";

import { Upload } from "./Upload";
import { Validation } from "./Validation";

import { Confirmation } from "./Confirmation";
import { Submit } from "./Submit";
import { PageLayout } from "../../../componentsV2/PageLayout";
import { UserUpload } from "./UserUpload";

export function ImportUsers() {
  const [activeStep, setActiveStep] = useState(0);

  const [users, setUsers] = useState<UserUpload>([]);

  const prev = () => setActiveStep(activeStep - 1);
  const next = () => setActiveStep(activeStep + 1);

  const steps = [
    {
      id: "upload",
      title: "Upload",
      component: () => (
        <Upload
          onNext={(users) => {
            setUsers(users);
            next();
          }}
        />
      ),
    },
    {
      id: "validate",
      title: "Validate",
      component: () => (
        <Validation
          users={users}
          onNext={(users) => {
            setUsers(users);
            next();
          }}
          onPrev={prev}
        />
      ),
    },
    {
      id: "confirmation",
      title: "Confirmation",
      component: () => (
        <Confirmation
          users={users}
          onPrev={prev}
          onNext={() => {
            next();
          }}
        />
      ),
    },
    {
      id: "submit",
      title: "Submit",
      component: () => <Submit users={users} />,
    },
  ];

  const step = steps?.[activeStep];

  return (
    <PageLayout
      title="Import Users"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Import", route: "/reports/import" },
        { name: "Users", route: "/reports/import/users" },
      ]}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((s) => (
          <Step key={s.id}>
            <StepLabel>{s.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {step?.component && (
        <Box sx={{ width: "100%", height: "100%", minHeight: "400px", p: 4 }}>
          <step.component />
        </Box>
      )}
    </PageLayout>
  );
}

export default ImportUsers;
