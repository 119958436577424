import React from "react";
import cx from "classnames";

import style from "./table.module.scss";

export function TableHeader({ children, className, ...props }) {
  return (
    <th className={cx(style.table_header, className)} {...props}>
      {children}
    </th>
  );
}
