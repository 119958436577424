import { useEffect } from "react";

export function blur() {
  document
    .getElementById("kronologic-ai-app")
    .classList.add("kronologic--blurred");
}

export function deblur() {
  document
    .getElementById("kronologic-ai-app")
    .classList.remove("kronologic--blurred");
}

export function useDeblur() {
  useEffect(() => {
    // Remove the blur when component unmounts.
    return () => {
      deblur();
    };
  }, []);
  return deblur;
}
