import { useEffect, useState } from "react";

import { API } from "../props";
import { get } from "../utils/fetch";

export function useSalesforceIntegrationObjects(integrationId, type) {
  const [objects, setObjects] = useState([]);

  useEffect(() => {
    if (integrationId) {
      get(API.integrations.salesforce.oFields(integrationId, type))
        .then((response) => response.json())
        .then(setObjects);
    }
  }, [integrationId, type]);

  return objects;
}
