export type TemplateStatus = "active" | "inactive";

export interface TemplateStatusOption {
  label: string;
  value: TemplateStatus;
}

export const TemplateStatusOptions: TemplateStatusOption[] = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];
