import React from "react";

import { Caption } from "../../components/typography";
import style from "./style.module.scss";

export function UnsubscribedChip() {
  return (
    <div className={style.unsubscribedChip__container}>
      <Caption extraSmall>unsubscribed</Caption>
    </div>
  );
}
