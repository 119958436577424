import React from "react";
import Flexbox from "../../../components/flexbox";
import { TableCell } from "../../../components/tableV5/GridTable";
import { Checkbox } from "../../../components/checkbox";
import { P1 } from "../../../components/typography";
import style from "./tableCells.module.scss";

const ToggleMeeting = ({ checked, onToggle, meeting }) => {
  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell className={style.tableCells}>
          <Column>
            <Checkbox
              className={style.toggleMeeting__checkbox}
              name="/instances/action_bar/select_one"
              checked={checked}
              toggle={onToggle}
              iconSize={2}
            />

            <P1>{meeting.id}</P1>
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
};

export default ToggleMeeting;
