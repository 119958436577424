import React from "react";

import {
  DefaultUserRole,
  OrgAdminRole,
  Role,
  SuperAdminRole,
  SuperAdminRORole,
  TeamAdminRole,
} from "../../types";
import Dropdown from "../Dropdown";

export function RoleSelectInput({
  disabled,
  enableSuperAdmin,
  value,
  onChange,
}: {
  disabled?: boolean;
  enableSuperAdmin?: boolean;
  value: Role;
  onChange?: (v: Role) => void;
}) {
  const options: { value: Role; label: string }[] = [
    { value: DefaultUserRole, label: "Default" },
    { value: TeamAdminRole, label: "Team Admin" },
    { value: OrgAdminRole, label: "Org Admin" },
  ];

  if (enableSuperAdmin) {
    options.push({ value: SuperAdminRORole, label: "Super Admin (Read-Only)" });
    options.push({ value: SuperAdminRole, label: "Super Admin" });
  }

  return (
    <Dropdown
      disabled={disabled}
      label="Role"
      options={options}
      value={value}
      onChange={(event) => onChange && onChange(event.target.value as Role)}
    />
  );
}

export default RoleSelectInput;
