import React from "react";
import { ButtonLink } from "../button";
import { TableCell } from "../tableV4";
import style from "./style.module.scss";

export function NewTagForm({
  createNewTag,
  hideNewTagForm,
  isValidTagName,
  tagNameInput,
}) {
  return (
    <tr>
      <TableCell colSpan={2}>
        <div className={style.tagManager__input}>{tagNameInput}</div>
      </TableCell>
      <TableCell>
        <div className={style.tagManager__text}>
          <ButtonLink name="/tagmanager/create/cancel" onClick={hideNewTagForm}>
            Cancel
          </ButtonLink>
          <span> | </span>
          <ButtonLink
            name="/tagmanager/create/add"
            disabled={!isValidTagName}
            onClick={createNewTag}
          >
            Add New Tag
          </ButtonLink>
        </div>
      </TableCell>
    </tr>
  );
}
