import { useUserService } from "../services";

export function useGuestAccept() {
  const service = useUserService();

  return (contactId: string, meetingId: string, orgId: string): Promise<void> =>
    service
      .post(`/api/meeting/guest/action`)
      .send({
        a: "accept",
        c: contactId,
        m: meetingId,
        o: orgId,
      })
      .then((res: Response) => res.body);
}
