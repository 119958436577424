import React from "react";
import cx from "classnames";
import style from "./table.module.scss";

export function TableCell({ className, colSpan = 1, children }) {
  return (
    <td className={cx(style.table_cell, className)} colSpan={colSpan}>
      {children}
    </td>
  );
}
