import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";

import Flexbox from "../../../components/flexbox";
import InitialsIcon from "../../../components/initials";
import GuestHubGuestListGuestEmail from "../guestHubGuestListGuestEmail";
import GuestHubGuestListGuestAttempts from "../guestHubGuestListGuestAttempts";
import { QuorumIcon } from "../../icons";
import GuestHubGuestListItemStatus from "../guestHubGuestListItemStatus";
import { getInitials } from "../util/helpers";

import style from "./guestHubGuestListGuest.module.scss";

const GuestHubGuestListGuest = ({ guest = null, index = 0 }) => {
  const [showGuestInfo, setShowGuestInfo] = useState(false);
  const initials = useMemo(() => {
    return getInitials(guest);
  }, [guest]);

  const status = useMemo(() => {
    if (guest !== null) {
      return guest.status;
    }
    return "Staging";
  }, [guest]);

  useEffect(() => {
    if (guest !== null) {
      setShowGuestInfo(true);
    }
  }, [guest]);
  return (
    <Flexbox>
      {({ Row }) => (
        <Row
          className={style.guestListItem}
          height="fit-content"
          justifyContent="flex-start"
        >
          <QuorumIcon
            className={cx({
              [style["guestListItem__icons--hidden"]]: !showGuestInfo,
            })}
            type="guest"
          />

          <Row width="fit-content">
            <InitialsIcon
              className={cx({
                [style["guestListItem__icons--hidden"]]: !showGuestInfo,
              })}
              initials={initials}
            />
          </Row>

          <GuestHubGuestListGuestEmail guest={guest} index={index} />
          {showGuestInfo && (
            <>
              <GuestHubGuestListItemStatus guest={guest} status={status} />
              <GuestHubGuestListGuestAttempts />
            </>
          )}
        </Row>
      )}
    </Flexbox>
  );
};

export default GuestHubGuestListGuest;
