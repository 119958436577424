import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { getUserDetails } from "src/utils/jwtToken";

export function RequireAuth({ children }: { children: any }) {
  const user = getUserDetails();

  const location = useLocation();

  if (user) {
    return children;
  }
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          from: {
            pathname: location.pathname,
            search: location.search,
            state: location.state,
          },
        },
      }}
    />
  );
}
