import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Stack, Typography } from "@mui/material";

import { DefaultUserRole, Role } from "../../types";
import { RoleSelectInput } from "../inputs";
import Dialog from "./Dialog";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import ValidatedInput from "../inputs/ValidatedInput";
import { validateEmailAddress } from "src/utils/helpers";
import { useUsers } from "src/queries";
import { useDebounce } from "src/hooks";

export function InviteUserDialog({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: (user: { email: string; role: Role }) => void;
  onClose: () => void;
}) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<Role>(DefaultUserRole);

  const debouncedEmail = useDebounce(email.trim(), 750);

  const { data, loading } = useUsers(10, 0, { query: debouncedEmail });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Invite User"
      actions={
        <>
          <PrimaryButton
            disabled={
              !validateEmailAddress(email) ||
              loading ||
              data?.data
                ?.map((user) => user.email.toLowerCase().trim())
                .includes(email.toLowerCase().trim())
            }
            onClick={() => onSubmit({ email, role })}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ minWidth: 300 }}>
        <ValidatedInput
          label="Email"
          required
          spellCheck={false}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          validators={[
            (v: string) => {
              if (!validateEmailAddress(v)) {
                throw new Error("must be a valid email");
              }

              return true;
            },
            (v) => {
              if (v.length < 1) {
                return true;
              }

              if (
                data?.data
                  ?.map((user) => user.email.toLowerCase().trim())
                  .includes(v)
              ) {
                throw new Error("user already exists");
              }

              return true;
            },
          ]}
        />
        <RoleSelectInput value={role} onChange={setRole} />
        <Typography>
          Note: If you would like to invite multiple users, try importing them
          in bulk{" "}
          <Link component={RouterLink} to="/reports/import/users">
            here
          </Link>
          .
        </Typography>
      </Stack>
    </Dialog>
  );
}

export default InviteUserDialog;
