import { ResponseError } from "superagent";

export function errorHandler(err: ResponseError) {
  if (err) {
    if (err.status === 401) {
      window.location.href = "/logout";
    } else {
      throw err;
    }
  }
}
