import React, { useState } from "react";
import { Box, Container, Step, StepLabel, Stepper } from "@mui/material";

import { Confirmation } from "./Confirmation";
import { ContactUpload } from "./ContactUpload";
import { MeetingDefinition, Tag } from "src/types";
import { Options } from "./Options";
import { PageLayout } from "src/componentsV2/PageLayout";
import { Submit } from "./Submit";
import { Upload } from "./Upload";
import { Validation } from "./Validation";

export function ImportContacts() {
  const [activeStep, setActiveStep] = useState(0);

  const [contacts, setContacts] = useState<ContactUpload>([]);
  const [meetingTemplate, setMeetingTemplate] =
    useState<MeetingDefinition | null>(null);
  const [tags, setTags] = useState<Tag[]>([]);

  const prev = () => setActiveStep(activeStep - 1);
  const next = () => setActiveStep(activeStep + 1);

  const steps = [
    {
      id: "upload",
      title: "Upload",
      component: () => (
        <Upload
          onNext={(contacts) => {
            setContacts(contacts);
            next();
          }}
        />
      ),
    },
    {
      id: "validate",
      title: "Validate",
      component: () => (
        <Validation
          contacts={contacts}
          onPrev={prev}
          onNext={(contacts) => {
            setContacts(contacts);
            next();
          }}
        />
      ),
    },
    {
      id: "options",
      title: "Options",
      component: () => (
        <Options
          initialMeetingTemplate={meetingTemplate}
          initialTags={tags}
          onPrev={prev}
          onNext={({ meetingTemplate, tags }) => {
            setMeetingTemplate(meetingTemplate);
            setTags(tags);
            next();
          }}
        />
      ),
    },
    {
      id: "confirm",
      title: "Confirm",
      component: () => (
        <Confirmation
          contacts={contacts}
          meetingTemplate={meetingTemplate}
          tags={tags}
          onPrev={prev}
          onNext={() => {
            next();
          }}
        />
      ),
    },
    {
      id: "submit",
      title: "Submit",
      component: () => {
        return (
          <Submit
            contacts={contacts}
            meetingTemplate={meetingTemplate}
            tags={tags}
          />
        );
      },
    },
  ];

  const step = steps?.[activeStep];

  return (
    <PageLayout
      title="Import Contacts"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Import", route: "/reports/import" },
        { name: "Contacts", route: "/reports/import/contacts" },
      ]}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((s) => (
          <Step key={s.id}>
            <StepLabel>{s.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {step?.component && (
        <Box sx={{ width: "100%", height: "100%", minHeight: "400px", p: 4 }}>
          <step.component />
        </Box>
      )}
    </PageLayout>
  );
}

export default ImportContacts;
