import { GuestStatus, GuestStatusOptions } from "src/types";
import FilterDropdown from "./FilterDropdown";

export function GuestStatusFilter(props: {
  value?: GuestStatus[];
  onChange?: (value: GuestStatus[]) => void;
}) {
  return (
    <FilterDropdown
      label="Guest Status"
      value={props.value}
      onChange={props.onChange}
      options={GuestStatusOptions}
    />
  );
}

export default GuestStatusFilter;
