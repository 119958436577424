import React from "react";
import dayjs from "dayjs";
import { Box, Slider, Stack, Typography } from "@mui/material";
import { WeekSchedule } from "src/types/Availability";
import DayCheckbox from "./DayCheckbox";

function formatTime(mins: number): string {
  return dayjs().startOf("day").minute(mins).format("h:mm a");
}

function DaySchedule({
  weekSchedule,
  day,
  dayIndex,
  onChange,
}: {
  weekSchedule: WeekSchedule;
  day: string;
  dayIndex: number;
  onChange: (weekSchedule: WeekSchedule) => void;
}) {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
      <DayCheckbox
        checked={weekSchedule[dayIndex].enabled}
        onChange={() => {
          weekSchedule[dayIndex].enabled = !weekSchedule[dayIndex].enabled;

          onChange(weekSchedule);
        }}
      >
        {day}
      </DayCheckbox>
      <Box width="250px" marginTop="auto" marginBottom="auto" marginLeft="5px">
        {weekSchedule[dayIndex].enabled ? (
          <Typography>
            {formatTime(weekSchedule[dayIndex].minuteIntervals[0].from)} -{" "}
            {formatTime(weekSchedule[dayIndex].minuteIntervals[0].to)}
          </Typography>
        ) : (
          <Typography color="GrayText">Unavailable</Typography>
        )}
      </Box>
      <Box width="50%">
        <Slider
          disabled={!weekSchedule[dayIndex].enabled}
          value={[
            weekSchedule[dayIndex].minuteIntervals[0].from,
            weekSchedule[dayIndex].minuteIntervals[0].to,
          ]}
          onChange={(_, newValue) => {
            if (!Array.isArray(newValue)) {
              return;
            }

            weekSchedule[dayIndex].minuteIntervals[0].from = newValue[0];
            weekSchedule[dayIndex].minuteIntervals[0].to = newValue[1];

            onChange(weekSchedule);
          }}
          valueLabelDisplay="auto"
          step={30} // 30 min increments
          min={0} // 12:00am
          max={1410} // 11:30pm
          valueLabelFormat={(value) => formatTime(value)}
          disableSwap
        />
      </Box>
    </Stack>
  );
}

export default DaySchedule;
