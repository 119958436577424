import FilterSelect from "./FilterSelect";
import React from "react";

export type CoHostFilterOption = "allMeetings" | "withCoHost" | "withoutCoHost";

interface CoHostFilterOptionValue {
  label: string;
  value: CoHostFilterOption;
}

export const CoHostFilterOptions: CoHostFilterOptionValue[] = [
  { label: "All Meetings", value: "allMeetings" },
  { label: "With Co-Host", value: "withCoHost" },
  { label: "Without Co-Host", value: "withoutCoHost" },
];

export function CoHostFilter(props: {
  value?: CoHostFilterOption | null;
  onChange?: (value: CoHostFilterOption | null) => void;
}) {
  return (
    <FilterSelect
      label="Co-Host"
      value={props.value || "allMeetings"}
      onChange={props.onChange}
      options={CoHostFilterOptions}
    />
  );
}

export default CoHostFilter;
