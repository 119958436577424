import { useEffect, useCallback, useReducer } from "react";
import { ERROR, IDLE, PENDING, SUCCESS } from "./status";

const INITIAL_STATE = Object.freeze({
  controller: null,
  endedAt: null,
  error: null,
  startedAt: null,
  status: IDLE,
  value: null,
});

function asyncReducer(state, action) {
  switch (action.type) {
    case "abort": {
      return {
        ...state,
        controller: null,
        endedAt: null,
        startedAt: null,
        status: IDLE,
      };
    }
    case "setToPending":
      return {
        ...state,
        controller: action.controller,
        endedAt: null,
        startedAt: new Date(),
        status: PENDING,
      };
    case "setError":
      return {
        ...state,
        endedAt: new Date(),
        error: action.error,
        status: ERROR,
        value: null,
      };
    case "setValue":
      return {
        ...state,
        endedAt: new Date(),
        error: null,
        status: SUCCESS,
        value: action.value,
      };
    default:
      throw new Error(`asyncReducer action type invalid: ${action.type}`);
  }
}

// TODO: What happens if invalid function is passed in?
export function useAsync(deferredFn, immediate = true) {
  const [state, dispatch] = useReducer(asyncReducer, INITIAL_STATE);
  // The execute function wraps deferredFn and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if deferredFn changes.
  const execute = useCallback(async () => {
    if (deferredFn === null) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    dispatch({
      controller,
      type: "setToPending",
    });
    try {
      const result = await deferredFn(controller);
      const { aborted } = signal;
      if (aborted) {
        dispatch({
          type: "abort",
        });
        return;
      }
      dispatch({
        type: "setValue",
        value: result,
      });
    } catch (error) {
      dispatch({
        error,
        type: "setError",
      });
    }
  }, [deferredFn, dispatch]);
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  const isRejected = state.status === ERROR;
  const isFufilled = state.status === SUCCESS;
  const isSettled = isFufilled || isRejected;
  const isLoading = !isSettled;
  return {
    execute,
    ...state,
    isFufilled,
    isLoading,
    isRejected,
    isSettled,
  };
}
