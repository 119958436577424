import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Link, Stack, Typography } from "@mui/material";

import { Loading } from "src/componentsV2/Loading";
import { useUploadUsers } from "src/mutations";
import { UserUpload } from "./UserUpload";

export function Submit({ users = [] }: { users: UserUpload }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const uploadUsers = useUploadUsers();

  useEffect(() => {
    if (!submitted) {
      uploadUsers(users)
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
      setSubmitted(true);
    }
  }, [uploadUsers, users, submitted, setSubmitted]);

  if (error) {
    return <Typography>There was an error uploading users</Typography>;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box sx={{ width: "100%", minHeight: "400px" }}>
          <Stack alignItems="center" spacing={2}>
            <Icon path={mdiCheck} size={3} />
            <Typography fontWeight="bold">
              Request submitted for processing!
            </Typography>

            <Stack alignItems="center" spacing={2}>
              <Typography align="center">
                You have successfully submitted a request to invite{" "}
                {users.length} {users.length === 1 ? "user" : "users"} to the
                Kronologic platform. The invited users will soon recieve emails
                guiding them through the sign-up process.
              </Typography>
              <Typography align="center">
                View the status of your upload{" "}
                <Link to="/reports" component={RouterLink}>
                  here
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default Submit;
