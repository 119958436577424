import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { errorHandler } from "src/hooks/errorHandler";
import { useUserService } from "../services";
import { Channel, ChannelWithDetails } from "../types";

export function useUpdateChannel() {
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (channel: ChannelWithDetails): Promise<Channel> =>
    service
      // ################################
      // FIXME: fix route to be /api/user/channels/{channelType}/{id}
      // ################################
      .patch(`/api/user/channels/import/${channel.id}`)
      .set(headers)
      .send(channel)
      .then(tokenRefreshHandler)
      .then((res: Response) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate channel queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/user/channels"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .then((res: Response) => res.body)
      .catch(errorHandler);
}
