import React from "react";
import cx from "classnames";
import { P1 } from "../../components/typography";
import style from "./globallogs.module.scss";

const buildMeetingCreatedLog = function* (log) {
  const { structuredData } = log;
  const { meetingId } = structuredData;

  yield (
    <P1>
      <span
        className={cx(style.logBuilder__instanceId, style.logBuilder__italics)}
      >
        {`Meeting ID: ${meetingId} - `}
      </span>
      <span>Meeting created</span>
    </P1>
  );
};

export { buildMeetingCreatedLog as default };
