import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItem, ListItemText, Chip } from "@mui/material";

import { useDebounce } from "../../hooks";
import { useTeams } from "../../queries";
import { Team } from "../../types";

export function TeamSelectInput({
  value,
  onChange,
}: {
  value: Team | null;
  onChange: (team: Team | null) => void;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const q = {
    qry: input,
  };

  const query = useDebounce(q, 750);

  const { data, loading } = useTeams(100, 0, query);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      disablePortal
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      onInputChange={(event, v, reason) => {
        setInput(v);
      }}
      onChange={(event, v, reason) => {
        onChange(v);
      }}
      value={value}
      filterOptions={(options, state) => options}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, state) => (
        <ListItem key={option.id} {...props} dense>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      options={data?.data || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Team"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default TeamSelectInput;
