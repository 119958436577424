import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SecondaryButton from "./buttons/SecondaryButton";
import { GoogleIcon } from "src/icons/GoogleIcon";
import { MicrosoftIcon } from "src/icons/MicrosoftIcon";

type AccountDisconnectModalProps = {
  account?: { email: string };
  open: boolean;
  onClose: () => void;
  onReconnect: (client: string) => void;
};

function AccountReconnectModal(props: AccountDisconnectModalProps) {
  return (
    <Dialog fullWidth open={props.open} onClose={props.onClose}>
      <DialogTitle>Reconnect {props.account?.email}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select the email provider for {props.account?.email}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={() => props.onReconnect("google")}
          icon={<GoogleIcon size="large" />}
          size="large"
        >
          Google
        </SecondaryButton>
        <SecondaryButton
          onClick={() => props.onReconnect("microsoft")}
          icon={<MicrosoftIcon size="large" />}
          size="large"
        >
          Microsoft
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default AccountReconnectModal;
