import React, { useState } from "react";
import { Stack } from "@mui/material";

import Dialog from "src/componentsV2/dialogs/Dialog";
import Dropdown from "src/componentsV2/Dropdown";
import Input from "src/componentsV2/inputs/Input";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";

export function AddDomainDialog({
  open,
  onClose,
  orgId,
  orgName,
  onSubmit,
}: {
  open: boolean;
  onClose?: () => void;
  orgId: number;
  orgName: string;
  onSubmit?: (value: {
    orgId: number;
    domain: string;
    preferredClient: "google" | "microsoft";
  }) => void;
}) {
  const [domain, setDomain] = useState("");
  const [domainConfirmation, setDomainConfirmation] = useState("");
  const [preferredClient, setPreferredClient] = useState<
    "google" | "microsoft" | ""
  >("");

  return (
    <Dialog
      title={`Add Domain to ${orgName}`}
      open={open}
      onClose={onClose}
      actions={
        <>
          <PrimaryButton
            disabled={
              preferredClient.length < 1 ||
              domain.length < 1 ||
              domainConfirmation.length < 1 ||
              domain !== domainConfirmation
            }
            onClick={() => {
              if (onSubmit && orgId && domain && preferredClient) {
                onSubmit({
                  orgId,
                  domain,
                  preferredClient,
                });
              }
            }}
          >
            Add Domain
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ pt: 1 }}>
        <Input
          label="Domain"
          spellCheck={false}
          placeholder="ex. kronologic.ai"
          value={domain}
          onChange={(event) => {
            setDomain(event.target.value);
          }}
        />

        <Input
          label="Confirm Domain"
          spellCheck={false}
          value={domainConfirmation}
          error={domainConfirmation.length > 0 && domain !== domainConfirmation}
          helperText={
            domainConfirmation.length > 0 && domain !== domainConfirmation
              ? "must match domain specified above"
              : ""
          }
          placeholder={domain}
          onChange={(event) => {
            setDomainConfirmation(event.target.value);
          }}
        />
        <Dropdown
          label="Preferred Client"
          options={[
            { label: "Google", value: "google" },
            { label: "Microsoft", value: "microsoft" },
          ]}
          value={preferredClient}
          onChange={(event) => {
            setPreferredClient(event.target.value as "google" | "microsoft");
          }}
        />
      </Stack>
    </Dialog>
  );
}
