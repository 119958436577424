import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import HighEmphasisButton from "./buttons/HighEmphasisButton";
import SecondaryButton from "./buttons/SecondaryButton";

type AccountDisconnectModalProps = {
  account?: { email: string };
  open: boolean;
  onClose: () => void;
  onDisconnect: () => void;
};

function AccountDisconnectModal(props: AccountDisconnectModalProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Disconnect Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography sx={{ mb: 2 }}>
            Disabling Authorization will render all of {props.account?.email}'s
            meetings invalid.
          </Typography>
          <Typography>
            Are you sure you would like to disable Kronologic Authorization for{" "}
            {props.account?.email}?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={props.onClose}>
          Remain Enabled
        </SecondaryButton>
        <HighEmphasisButton onClick={props.onDisconnect}>
          Confirm Disconnect
        </HighEmphasisButton>
      </DialogActions>
    </Dialog>
  );
}

export default AccountDisconnectModal;
