import React from "react";
import cx from "classnames";

import style from "./table.module.scss";

export function Table({ className, children }) {
  return (
    <table className={cx(style["table--basic"], className)}>{children}</table>
  );
}
