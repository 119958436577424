import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Checkbox, ListItem, ListItemText, Typography } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

import { Calendar } from "src/types";
import useCalendars from "src/queries/useCalendars";

export function CalendarSelectInput({
  value,
  onChange,
}: {
  value: Calendar[];
  onChange: (calendars: Calendar[]) => void;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const { data, loading } = useCalendars();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      multiple
      disableCloseOnSelect
      disablePortal
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      onInputChange={(event, v, reason) => {
        setInput(v);
      }}
      onChange={(event, v, reason) => {
        onChange(v);
      }}
      value={value}
      isOptionEqualToValue={(option, value) =>
        option.calendarId === value.calendarId
      }
      filterOptions={(options, state) => options}
      getOptionLabel={(option) => option.calendarName}
      renderOption={(props, option, { selected }) => (
        <ListItem key={option.calendarId} {...props} dense>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography>
            {option.userFirstName} {option.userLastName} - {option.calendarName}
          </Typography>
        </ListItem>
      )}
      options={data || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Calendar"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default CalendarSelectInput;
