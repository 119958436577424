import React, { useMemo } from "react";
import Select from "../../inputs/Select";
import useTimezoneTheme from "./useTimezoneTheme";
import useTimezoneAbbrTheme from "./useTimezoneAbbrTheme";
import useTimezones from "./useTimezones";

export function Timezone({
  loading = false,
  onChange,
  value,
  timezoneAbbr = false,
  name = null,
}) {
  const [components, theme] = useTimezoneTheme();
  const timezoneAbbrTheme = useTimezoneAbbrTheme();
  const { tmzData, tmzAbbrvData } = useTimezones();
  const tzValue = useMemo(() => {
    return tmzData.find((o) => o.value === value);
  }, [tmzData, value]);
  const tmzAbbrValue = useMemo(() => {
    return tmzAbbrvData.find((o) => o.value === value);
  }, [tmzAbbrvData, value]);

  return (
    <Select
      isLoading={loading}
      components={components}
      closeOnSelect
      value={timezoneAbbr ? tmzAbbrValue : tzValue}
      pattern={null}
      name={name}
      options={timezoneAbbr ? tmzAbbrvData : tmzData}
      placeholder={timezoneAbbr ? "" : "Search Timezones"}
      onChange={onChange}
      customTheme={timezoneAbbr ? timezoneAbbrTheme : theme}
    />
  );
}
