import { Redirect, Route, Switch } from "react-router-dom";
import { AccountStatusBanner } from "src/componentsV2/AccountStatusBanner";
import { KMeNavbar } from "src/kronologic-me/componentsV2/Navbar";
import SubscriptionStatusBanner from "src/kronologic-me/componentsV2/SubscriptionStatusBanner";
import KMeContactsPage from "src/kronologic-me/pages/contacts/v2";
import KMeImportExport from "src/kronologic-me/pages/reports";
import KMeExport from "src/kronologic-me/pages/reports/export";
import KMeImport from "src/kronologic-me/pages/reports/import";
import ErrorPage from "src/pages/ErrorPage";
import Accepted from "src/pages/accepted";
import MerlinChat from "src/pages/admin/merlin";
import OrgSettings from "src/pages/settings/org-settings";
import Reporting from "src/pages/admin/reporting";
import OrgReport from "src/pages/admin/reporting/[org]";
import SwitchUsers from "src/pages/admin/users";
import Contact from "src/pages/contacts/[id]";
import Declined from "src/pages/declined";
import Error from "src/pages/error";
import GuestAction from "src/pages/guestAction";
import MeetingTemplateEditPage from "src/pages/meeting-templates/edit/[slug]";
import Meetings from "src/pages/meetings";
import OneClick from "src/pages/oneClick";
import Pending from "src/pages/pending";
import ExportContacts from "src/pages/reports/export/contacts";
import ExportMeetings from "src/pages/reports/export/meetings";
import Success from "src/pages/success";
import Unsubscribe from "src/pages/unsubscribe";
import Users from "src/pages/users";
import User from "src/pages/users/[id]";
import { getUserDetails, setUserToken } from "src/utils/jwtToken";
import { RequireAuth, useActingAs } from "../../auth";
import { ROLE_LEVELS, supersedes } from "../../auth/roles";
import Channels from "../../channels/Channels";
import { ActingAsBanner } from "../../componentsV2/ActingAsBanner";
import { PageBanner } from "../../componentsV2/PageBanner";
import { RequireRole } from "../../componentsV2/RequireRole";
import {
  Features,
  useMeetingTypesForReps,
  useNewMeetingsPage,
} from "../../features";
import { useUrlQuery } from "../../hooks";
import ImportContacts from "../../importExport/import/importContacts";
import { Integrations } from "../../integrations/Integrations";
import { Logout } from "../../logout/Logout";
import MeetingsPage from "../../meetings/MeetingsPage";
import {
  OrgAdminRole,
  SuperAdminRORole,
  SuperAdminRole,
  TeamAdminRole,
} from "../../types";
import KMeProvisioning from "./admin/provisioning";
import KMeLogin from "./login";
import KmeNewMeetingTemplates from "./meeting-templates";
import KMeMeeting from "./meetings/[id]";
import KmeSettings from "./settings";
import KMeSignup from "./singUp";
import FinishSignUp from "./singUp/finishSignup";
import { Helmet } from "react-helmet";

function AuthenticatedRoutes() {
  const [actingAs, setActingAs] = useActingAs();
  const meetingTypesForReps = useMeetingTypesForReps();
  const loggedInUser = getUserDetails();
  const user = actingAs || loggedInUser;

  const newMeetingsPage = useNewMeetingsPage();

  return (
    <>
      <PageBanner mode="sticky">
        {actingAs && (
          <ActingAsBanner
            first={actingAs.firstName}
            last={actingAs.lastName}
            org={actingAs.org}
            role={actingAs.role}
            onClose={() => {
              setActingAs(null);
              window.location.reload();
            }}
          />
        )}
        <div id="kl-profile-menu" />
        <div id="kl-terminal" />
      </PageBanner>
      <AccountStatusBanner />
      <SubscriptionStatusBanner />
      <Switch>
        <Route
          path="*"
          render={() => (
            <>
              <KMeNavbar />
              <Switch>
                {newMeetingsPage && (
                  <Route path="/meetings*" render={() => <KmeSettings />}>
                    <Switch>
                      <Route
                        path="/meetings/:id"
                        render={() => <KMeMeeting />}
                      />
                      <Route path="/meetings" render={() => <Meetings />} />
                    </Switch>
                  </Route>
                )}
                <Route path="/settings*" render={() => <KmeSettings />} />
                <Route
                  path="/users*"
                  render={() => (
                    <Switch>
                      <Route
                        path="/users/:id"
                        render={() => (
                          <RequireRole
                            permitted={[
                              TeamAdminRole,
                              OrgAdminRole,
                              SuperAdminRole,
                              SuperAdminRORole,
                            ]}
                          >
                            <User />
                          </RequireRole>
                        )}
                      />
                      <Route
                        path="/users"
                        render={() => (
                          <RequireRole
                            permitted={[
                              TeamAdminRole,
                              OrgAdminRole,
                              SuperAdminRole,
                              SuperAdminRORole,
                            ]}
                          >
                            <Users />
                          </RequireRole>
                        )}
                      />
                    </Switch>
                  )}
                />
                <Route
                  path="/admin/users"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <SwitchUsers />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/reporting/:org"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <OrgReport />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/reporting"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <Reporting />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/provisioning"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <KMeProvisioning />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/orgSettings"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <OrgSettings />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/merlin"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <MerlinChat />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <Redirect to="/admin/users" />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/integrations"
                  render={() => (
                    <RequireRole
                      permitted={[
                        OrgAdminRole,
                        SuperAdminRole,
                        SuperAdminRORole,
                      ]}
                    >
                      <Integrations />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/reports*"
                  render={() => (
                    <Switch>
                      <Route
                        path="/reports/import/contacts"
                        render={() => <ImportContacts />}
                      />
                      <Route
                        path="/reports/import"
                        render={() => <KMeImport />}
                      />
                      <Route
                        path="/reports/export/contacts"
                        render={() => <ExportContacts />}
                      />
                      <Route
                        path="/reports/export/meetings"
                        render={() => <ExportMeetings />}
                      />
                      <Route
                        path="/reports/export"
                        render={() => <KMeExport />}
                      />
                      <Route path="*" render={() => <KMeImportExport />} />
                    </Switch>
                  )}
                />
                {(supersedes({
                  has: user?.role,
                  required: ROLE_LEVELS.TEAM_ADMIN,
                }) ||
                  meetingTypesForReps) && (
                  <Route
                    path="/meeting-templates*"
                    render={() => (
                      <Switch>
                        <Route
                          path="/meeting-templates/edit/:id"
                          render={() => <MeetingTemplateEditPage />}
                        />
                        <Route
                          path="/"
                          render={() => <KmeNewMeetingTemplates />}
                        />
                      </Switch>
                    )}
                  />
                )}
                <Route path="/instances/:id" render={() => <MeetingsPage />} />
                <Route
                  path="/instances"
                  render={() => {
                    if (newMeetingsPage) {
                      return <Redirect to="/meetings" />;
                    }

                    return <MeetingsPage />;
                  }}
                />
                <Route
                  path="/channels"
                  render={() => (
                    <RequireRole
                      permitted={[
                        TeamAdminRole,
                        OrgAdminRole,
                        SuperAdminRole,
                        SuperAdminRORole,
                      ]}
                    >
                      <Channels />
                    </RequireRole>
                  )}
                />

                <Route path="/contacts*">
                  <Switch>
                    <Route
                      path="/contacts/:id"
                      render={() => (
                        <RequireRole
                          permitted={[
                            TeamAdminRole,
                            OrgAdminRole,
                            SuperAdminRole,
                            SuperAdminRORole,
                          ]}
                        >
                          <Contact />
                        </RequireRole>
                      )}
                    />
                    <Route
                      path="/contacts"
                      render={() => (
                        <RequireRole
                          permitted={[
                            TeamAdminRole,
                            OrgAdminRole,
                            SuperAdminRole,
                            SuperAdminRORole,
                          ]}
                        >
                          <KMeContactsPage />
                        </RequireRole>
                      )}
                    />
                  </Switch>
                </Route>
              </Switch>
            </>
          )}
        />
        <Route path="*" render={() => <ErrorPage status={404} />} />
      </Switch>
    </>
  );
}

const KMeHomePage = () => {
  const params = useUrlQuery();

  if (params["JWT-TOKEN"]) {
    setUserToken(params["JWT-TOKEN"]);

    if (params.redirectUrl) {
      return <Redirect to={params.redirectUrl} />;
    }
  }

  return (
    <>
      <PlausibleScript />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/instances" />} />
        <Route exact path="/login" render={() => <KMeLogin />} />
        <Route exact path="/signUp" render={() => <KMeSignup />} />
        <Route
          exact
          path="/stripeCheckoutSuccess"
          render={() => <FinishSignUp />}
        />

        <Route exact path="/logout" render={() => <Logout />} />
        <Route path="/1-click/:schedulingToken" render={() => <OneClick />} />
        <Route path="/success" render={() => <Success />} />
        <Route path="/accepted" render={() => <Accepted />} />
        <Route path="/declined" render={() => <Declined />} />
        <Route path="/pending" render={() => <Pending />} />
        <Route path="/error" render={() => <Error />} />
        <Route exact path="/unsubscribe" render={() => <Unsubscribe />} />
        <Route exact path="/guestAction" render={() => <GuestAction />} />
        <Route path="/404" render={() => <ErrorPage status={404} />} />
        <Route
          path="/*"
          render={() => (
            <RequireAuth>
              <Features>
                <AuthenticatedRoutes />
              </Features>
            </RequireAuth>
          )}
        />
        <Route path="*" render={() => <ErrorPage status={404} />} />
      </Switch>
    </>
  );
};

const PlausibleScript = () => {
  if (window.config.ENVIRONMENT === "localhost") {
    return null;
  }

  let dataDomain = "mars.kronologic.ai";
  if (window.config.ENVIRONMENT === "us.app") {
    dataDomain = "kronologic.me";
  }
  return (
    <Helmet>
      <script
        defer
        data-domain={dataDomain}
        src="https://plausible.io/js/script.js"
      ></script>
    </Helmet>
  );
};

export default KMeHomePage;
