import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

function UnsubscribedFilter({
  value,
  onChange,
}: {
  value?: boolean;
  onChange?: (value: boolean) => void;
}) {
  return (
    <FormControl>
      <FormLabel>View Filter</FormLabel>
      <RadioGroup
        defaultValue={false}
        value={value ? "unsubscribed" : "all"}
        onChange={(_, newValue) => {
          if (onChange) {
            onChange(newValue === "unsubscribed");
          }
        }}
      >
        <FormControlLabel value="all" control={<Radio />} label="All" />
        <FormControlLabel
          value="unsubscribed"
          control={<Radio />}
          label="Unsubscribed"
        />
      </RadioGroup>
    </FormControl>
  );
}

export default UnsubscribedFilter;
