import { useMemo } from "react";

export function useTags(loadedTags, newlyCreatedMeetingTags, filter, offset) {
  return useMemo(() => {
    if (filter !== "") {
      return [...loadedTags];
    }
    if (offset !== 0) {
      return [...loadedTags];
    }
    const loadedTagIds = loadedTags.map((t) => t.id);
    const remainingNewlyCreatedTags = newlyCreatedMeetingTags.filter((t) => {
      return !loadedTagIds.includes(t.id);
    });
    return [...remainingNewlyCreatedTags, ...loadedTags];
  }, [filter, loadedTags, newlyCreatedMeetingTags, offset]);
}
