import React from "react";

function AutomatedResponsesCircle() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.333252"
        width="30"
        height="30"
        rx="15"
        fill="#9C27B0"
        fillOpacity="0.08"
      />
      <path
        d="M15.3333 23.3333C19.9357 23.3333 23.6667 19.6024 23.6667 15C23.6667 10.3976 19.9357 6.66666 15.3333 6.66666C10.731 6.66666 7 10.3976 7 15C7 19.6024 10.731 23.3333 15.3333 23.3333Z"
        stroke="#9C27B0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3333 20C18.0947 20 20.3333 17.7614 20.3333 15C20.3333 12.2386 18.0947 10 15.3333 10C12.5718 10 10.3333 12.2386 10.3333 15C10.3333 17.7614 12.5718 20 15.3333 20Z"
        stroke="#9C27B0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3332 16.6667C16.2536 16.6667 16.9998 15.9205 16.9998 15C16.9998 14.0795 16.2536 13.3333 15.3332 13.3333C14.4127 13.3333 13.6665 14.0795 13.6665 15C13.6665 15.9205 14.4127 16.6667 15.3332 16.6667Z"
        stroke="#9C27B0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AutomatedResponsesCircle;
