import React from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Logout } from "@mui/icons-material";

import { Role, roleToString } from "../types";

export function ActingAsBanner({
  first,
  last,
  org,
  role,
  onClose,
}: {
  first: string;
  last: string;
  org: string;
  role: number;
  onClose: () => void;
}) {
  const theme = useTheme();
  const name = `${first} ${last}`;

  return (
    <Box
      sx={{
        backgroundColor: "hsl(202, 94%, 57%)",
        color: "white",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "100%", padding: theme.spacing(1) }}
      >
        <Typography>
          <strong>Acting As</strong>: {name} from {org} (
          {roleToString(role as Role)})
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <Logout />
        </IconButton>
      </Stack>
    </Box>
  );
}
