import React from "react";

import { Link, Typography } from "@mui/material";

export function LoginDisclaimer() {
  return (
    <Typography align="center" variant="caption">
      By providing your email you agree to Kronologic's{" "}
      <Link href="https://www.kronologic.com/terms">Terms of Service</Link> and{" "}
      <Link href="https://www.kronologic.com/privacy">Privacy Policy</Link>
    </Typography>
  );
}
