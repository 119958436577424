// SequenceStepIdStringPart is the character substring that we look for in Outreach channel configurations
// we must do this as we only store one piece of data for each channel filter, and so to avoid
// redesigning the shape of channels in the DB, BE, and UI, we simply append the below character
// substring into the saved Sequence Step's name, which will then be followed by the ID we are
// looking for
// ex: "Step #1: Auto Email ----- SEQUENCE STEP ID ----- 473"
// we will know from the above that we are to match against Sequence Step ID 473
const SEQUENCE_STEP_ID_STRING_PART = "----- SEQUENCE STEP ID -----";

// SequenceIdStringPart is the character substring that we look for in Outreach channel configurations to
// find the sequence by ID
const SEQUENCE_ID_STRING_PART = "----- SEQUENCE ID -----";

// cleanOutreachFilterText removes any data string parts (sequence id, sequence step id) from visible text
export function cleanOutreachFilterText(text = "") {
  if (text.length < 1) {
    return text;
  }

  if (text.includes(SEQUENCE_ID_STRING_PART)) {
    return text.slice(0, text.indexOf(SEQUENCE_ID_STRING_PART));
  }

  if (text.includes(SEQUENCE_STEP_ID_STRING_PART)) {
    return text.slice(0, text.indexOf(SEQUENCE_STEP_ID_STRING_PART));
  }

  return text;
}
