import React, { useMemo } from "react";
import cx from "classnames";
import Icon from "@mdi/react";
import { mdiCircle } from "@mdi/js";
import Flexbox from "../../../components/flexbox";
import { useMeeting } from "../state";
import style from "./guestHubGuestListGuestAttempts.module.scss";

const GuestHubGuestListGuestAttempts = () => {
  const [meeting] = useMeeting();

  const attempts = useMemo(() => {
    const { current = 0, total = 1 } = meeting.attempts || {};

    return [...Array(total).keys()].map((attempt) => {
      return {
        id: attempt,
        isAttempted: attempt < current,
      };
    });
  }, [meeting.attempts]);

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <Row className={style.attempts} width="fit-content">
          {attempts &&
            attempts.map(({ id, isAttempted }) => (
              <Column key={id}>
                <Icon
                  className={cx({
                    [style.attempted]: isAttempted,
                    [style.attempt]: !isAttempted,
                  })}
                  key={id}
                  path={mdiCircle}
                  size={0.75}
                />
              </Column>
            ))}
        </Row>
      )}
    </Flexbox>
  );
};

export default GuestHubGuestListGuestAttempts;
