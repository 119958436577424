import { Role, RoleOptions } from "src/types";
import FilterDropdown from "./FilterDropdown";

export function RoleFilter(props: {
  value?: Role[];
  onChange?: (value: Role[]) => void;
}) {
  return (
    <FilterDropdown
      label="Role"
      value={props.value}
      onChange={props.onChange}
      options={RoleOptions}
    />
  );
}

export default RoleFilter;
