import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItem, Checkbox, Typography } from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useDebounce } from "../../../hooks";
import { useTeams } from "../../../queries";
import { Team } from "../../../types";

function TeamSelectFilter({
  value,
  onChange,
}: {
  value: Team[];
  onChange: (team: Team[]) => void;
}) {
  const [input, setInput] = useState("");

  const q = {
    qry: input,
  };

  const query = useDebounce(q, 750);

  const { data, loading } = useTeams(100, 0, query);

  return (
    <Autocomplete
      disablePortal
      multiple
      inputValue={input}
      onInputChange={(_, newValue) => {
        setInput(newValue);
      }}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      value={value}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name}
      options={data?.data || []}
      loading={loading}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Team"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} dense>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography>{option.name}</Typography>
        </ListItem>
      )}
    />
  );
}

export default TeamSelectFilter;
