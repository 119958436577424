import { CancelRounded } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import SignInButton from "src/componentsV2/buttons/SignInButton";
import { useUrlQuery } from "src/hooks";
import { getUserToken } from "src/utils/jwtToken";
import full from "../../assets/kronologic.me/full.svg";
import kronologicMeBG from "../../assets/kronologic.me/kronologicMeBG.png";
import { GoogleIcon } from "src/icons/GoogleIcon";
import { MicrosoftIcon } from "src/icons/MicrosoftIcon";
import { useKMeAuthUrl } from "../queries/useKMeAuthUrl";

function KMeLogin() {
  const accessToken = getUserToken();
  const query = useUrlQuery();

  const theme = useTheme();
  const history = useHistory();

  const [serverError, setServerError] = useState<string | null>(null);

  const { data: authURLs, loading, error } = useKMeAuthUrl();

  // If the request for auth urls was success and the email is a valid user then
  // remember the email for next login.
  useEffect(() => {
    if (!accessToken && query.errorCode) {
      switch (query.errorCode) {
        case "409":
          setServerError("User exists, please use sign in");
          break;
        default:
          setServerError("Server error");
      }
    }
  }, [loading, error, accessToken, query.errorCode]);

  if (accessToken) {
    return <Redirect to={query.redirectUrl || "/instances"} />;
  }

  // If the user is already logged in then bypass the login page.
  if (accessToken) {
    return <Redirect to={"/instances"} />;
  }

  return (
    <CardMedia image={kronologicMeBG} sx={{ height: "100%", width: "100%" }}>
      <Stack
        direction="column"
        sx={{
          backdropFilter: "blur(18px)",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 100%)",
          color: "white",
          height: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          minWidth: "fit-content",
          width: "45%",
        }}
      >
        <CardMedia
          image={full}
          sx={{
            minHeight: "35px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "100px",
            width: "404px",
          }}
        />

        <Typography align="center" variant="caption" marginTop="10px">
          Learn more about{" "}
          <Link href="https://www.kronologic.com/" target="_blank">
            Kronologic
          </Link>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "40px",
            fontWeight: "700",
            lineHeight: "72px",
            letterSpacing: "-0.5px",
            marginTop: "115px",
            textAlign: "center",
          }}
        >
          Log In
        </Typography>
        <>
          {(authURLs || error) && (
            <>
              {(error || serverError) && (
                <Stack alignItems="center" spacing={1}>
                  <CancelRounded sx={{ color: theme.palette.error.main }} />
                  <Typography variant="caption" color="red">
                    {serverError || `Account does not exist`}
                  </Typography>
                </Stack>
              )}

              {authURLs && (
                <Stack
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "50px",
                    width: "300px",
                  }}
                >
                  <>
                    <SignInButton
                      color="#5886d4"
                      icon={<GoogleIcon />}
                      onClick={() => {
                        window.location.href = authURLs.google;
                      }}
                    >
                      Log in with Google
                    </SignInButton>

                    <Box sx={{ marginBottom: "1em" }} />
                  </>

                  <SignInButton
                    color="rgb(213,85,46)"
                    icon={<MicrosoftIcon />}
                    onClick={() => {
                      window.location.href = authURLs.microsoft;
                    }}
                  >
                    Log in with Microsoft
                  </SignInButton>

                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "200",
                      fontSize: "16px",
                      lineHeight: "25.6px",
                      letterSpacing: "0.15px",
                      marginTop: "30px",
                      textAlign: "center",
                    }}
                  >
                    Don't have an account?{" "}
                    <Typography
                      component="span"
                      sx={{
                        color: "#2BAEF9",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/signUp");
                      }}
                    >
                      Sign up
                    </Typography>
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </>
      </Stack>
    </CardMedia>
  );
}

export default KMeLogin;
