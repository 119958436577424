import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import ImportFooter from "../ImportFooter";
import { MeetingTypeSelectInput } from "src/componentsV2/inputs";
import { TagFilter } from "src/importExport/export/filters/TagFilter";
import { MeetingDefinition, Tag } from "src/types";

export function Options({
  initialMeetingTemplate,
  initialTags,
  onPrev,
  onNext,
}: {
  initialMeetingTemplate?: MeetingDefinition | null;
  initialTags?: Tag[];
  onPrev: () => void;
  onNext: (value: {
    meetingTemplate: MeetingDefinition | null;
    tags: Tag[];
  }) => void;
}) {
  const [meetingTemplate, setMeetingTemplate] =
    useState<MeetingDefinition | null>(initialMeetingTemplate || null);

  const [tags, setTags] = useState<Tag[]>(initialTags || []);

  return (
    <>
      <Box sx={{ width: "100%", minHeight: "400px" }}>
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography>
              Would you like to create meetings with these contacts?
            </Typography>
            <MeetingTypeSelectInput
              meetingType={meetingTemplate}
              onMeetingTypeSelect={setMeetingTemplate}
            />
          </Stack>
          {meetingTemplate !== null && (
            <Stack spacing={1}>
              <Typography>
                Would you like to tag the created meetings?
              </Typography>
              <TagFilter value={tags} onChange={setTags} />
            </Stack>
          )}
        </Stack>
      </Box>
      <ImportFooter
        onLeftButtonClick={onPrev}
        onRightButtonClick={() => onNext({ meetingTemplate, tags })}
      />
    </>
  );
}

export default Options;
