import React from "react";
import { ButtonContainer } from "../../../../components/button";
import Card from "../../../../components/card/Card";
import Flexbox from "../../../../components/flexbox";
import useGuestStatusFilterOnClick from "../useGuestStatusFilterOnClick";
import { LoadingCard } from "../../../../components/genericView/GenericView";
import { STAGING_STATUS } from "../../../props";
import { P1, H5 } from "../../../../components/typography";
import style from "./guestProvisioning.module.scss";

const GuestProvisioning = ({ isLoading, staging }) => {
  const handleOnTotalClick = useGuestStatusFilterOnClick([STAGING_STATUS]);

  return (
    <Flexbox>
      {({ Column }) => (
        <div className={style.guestProvisioning}>
          {isLoading && (
            <LoadingCard
              className={style.guestProvisioning__loadingCard}
              height={160}
            />
          )}

          {!isLoading && (
            <Card className={style.guestProvisioning__card}>
              <Column className={style.guestProvisioning__card_content}>
                <P1 className={style.guestProvisioning__text}>Staging</P1>

                <ButtonContainer
                  className={style.guestProvisioning__value}
                  name="/instances/dashboard_gs/staging"
                  onClick={handleOnTotalClick}
                  transparent
                >
                  <H5>{staging}</H5>
                </ButtonContainer>
              </Column>
            </Card>
          )}
        </div>
      )}
    </Flexbox>
  );
};

export default GuestProvisioning;
