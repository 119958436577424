import React from "react";
import cx from "classnames";

import { capitalize } from "./util";
import { P1 } from "../../components/typography";

import style from "./globallogs.module.scss";

const buildMeetingStartTimeChangedLog = function* (log) {
  const { structuredData } = log;
  const { meetingId, userEmail } = structuredData;

  yield (
    <P1>
      <span
        className={cx(style.logBuilder__instanceId, style.logBuilder__italics)}
      >
        {`Meeting ID: ${meetingId} - `}
      </span>
      <span>
        {`${capitalize(userEmail)} `}
        manually updated start time
      </span>
    </P1>
  );
};

export { buildMeetingStartTimeChangedLog as default };
