import { useMemo } from "react";

export function useSelectedCountMessage(selectedTagCount) {
  return useMemo(() => {
    if (selectedTagCount === 1) {
      return "1 tag selected";
    }
    return `${selectedTagCount} tags selected`;
  }, [selectedTagCount]);
}
