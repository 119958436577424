import React, { useState, useEffect, useRef } from "react";
import { mdiContentCopy, mdiCheck } from "@mdi/js";

import { ButtonIcon } from "../button/ButtonV2";

export function CopyButton({ text }) {
  const [success, setSuccess] = useState(false);
  const ref = useRef();

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => (ref.current ? clearTimeout(ref.current) : undefined);
  }, []);

  return (
    <ButtonIcon
      path={success ? mdiCheck : mdiContentCopy}
      onClick={() => {
        if (navigator) {
          navigator.clipboard.writeText(text);
          if (ref.current) clearTimeout(ref.current);
          ref.current = setTimeout(() => setSuccess(false), 2000);
          setSuccess(true);
        }
      }}
      size={0.75}
    />
  );
}
