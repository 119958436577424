import React from "react";
import { mdiDeleteOutline } from "@mdi/js";
import { ButtonContainer, ButtonIcon, ButtonText } from "../button";
import style from "./style.module.scss";
import { useHasOrgAdminPermissions } from "../../auth";

export function DeleteAndSearchMenuRow({
  deleteSelectedTags,
  searchBar,
  selectedCountMessage,
  selectedTagCount,
}) {
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  return (
    <div className={style.tagManager__deleteAndSearchMenuRow}>
      <div className={style.tagManager__deleteAndSearchMenuRowButtons}>
        {hasOrgAdminPermissions && (
          <ButtonContainer
            secondary
            medium
            name="/tag_manager/delete_tag"
            onClick={deleteSelectedTags}
            disabled={selectedTagCount === 0}
          >
            <ButtonIcon path={mdiDeleteOutline} />
            <ButtonText>Delete</ButtonText>
          </ButtonContainer>
        )}
        <span className={style.tagManager__tagCount}>
          {selectedCountMessage}
        </span>
      </div>
      {searchBar}
    </div>
  );
}
