import React, { useMemo } from "react";

import Flexbox from "../../../components/flexbox";
import InitialsIcon from "../../../components/initials";
import { useMeeting } from "../state";
import { getInitials } from "../util/helpers";

import style from "./guestList.module.scss";

const WIDTH = "40px";
const HEIGHT = "40px";
const INITIALS_SIZE = { medium: true };

const MeetingTypeDetailsGuestList = () => {
  const [meeting] = useMeeting();
  const { host = null, guests = null } = meeting;

  const hostInitials = useMemo(() => {
    if (host === null) {
      return "";
    }
    return getInitials(host);
  }, [host]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row
          className={style.meetingType_details_guestList}
          height="fit-content"
          justifyContent="start"
        >
          {host === null && guests?.length === 0 && (
            <InitialsIcon width={WIDTH} height={HEIGHT} />
          )}

          {/* Host always at front with no status */}
          {host !== null && (
            <InitialsIcon
              width={WIDTH}
              height={HEIGHT}
              size={INITIALS_SIZE}
              initials={hostInitials}
            />
          )}
          {guests !== null && guests.length !== 0 && (
            <>
              {guests.map((guest) => (
                <InitialsIcon
                  key={guest?.id}
                  width={WIDTH}
                  height={HEIGHT}
                  size={INITIALS_SIZE}
                  showStatus
                  status={guest?.status}
                  initials={getInitials(guest)}
                />
              ))}
            </>
          )}
        </Row>
      )}
    </Flexbox>
  );
};

export default MeetingTypeDetailsGuestList;
