import React, { useCallback, useMemo, useState } from "react";
import { mdiBorderColor } from "@mdi/js";
import Button from "../../../../components/button/Button";
import { CHANNEL_TYPES } from "../../../props";
import { cleanOutreachFilterText } from "../../../util";
import style from "../style.module.scss";

function ConditionText({
  channelType,
  condition,
  enableEditing,
  field,
  value,
  isOutreach,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const textValue = useMemo(() => {
    if (!value) {
      return "";
    }

    let v = value;

    if (isOutreach) {
      v = cleanOutreachFilterText(value);
    }

    if (v.length > 30) {
      return `${v.slice(0, 30)}...`;
    }

    return v;
  }, [isOutreach, value]);

  const hoverValue = useMemo(() => {
    if (!value) {
      return "";
    }

    let v = value;

    if (isOutreach) {
      v = cleanOutreachFilterText(value);
    }

    return v;
  }, [isOutreach, value]);

  const onMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <>
      <div>
        <span
          className={
            style.conditions__card_condition_dropdowns_wrapper__bolded_words
          }
        >
          {`${field?.label}`}
        </span>
        {!(isOutreach && channelType === CHANNEL_TYPES.UPDATE) && (
          <>
            <span>
              {` ${
                channelType === CHANNEL_TYPES.IMPORT ||
                channelType === CHANNEL_TYPES.INTERCEPT
                  ? condition.replaceAll("_", " ")
                  : "is"
              } `}
              {isOutreach && ` on step `}
            </span>
            <span
              className={
                style.conditions__card_condition_dropdowns_wrapper__bolded_words
              }
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {`${textValue}`}
            </span>
          </>
        )}
      </div>
      <Button
        type="tertiary"
        icon={mdiBorderColor}
        name={`/channels/${channelType}/add_channel/filter_edit`}
        onClick={enableEditing}
      />

      {isHovering && (
        <div className={style.conditionText__hover}>
          <div>{hoverValue}</div>
        </div>
      )}
    </>
  );
}

export default ConditionText;
