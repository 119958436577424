import React from "react";
import { useHasOrgAdminPermissions } from "../../auth";
import { ButtonContainer, ButtonText } from "../button";
import style from "./style.module.scss";

export function CreateMenuRow({ onShowNewMeetingForm }) {
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  return (
    <div className={style.tagManager__createMenuRow}>
      <span>Select tags to edit or delete.</span>
      {hasOrgAdminPermissions && (
        <ButtonContainer
          name="/tag_manager/create_tag"
          primary
          large
          onClick={onShowNewMeetingForm}
        >
          <ButtonText>Create Tag</ButtonText>
        </ButtonContainer>
      )}
    </div>
  );
}
