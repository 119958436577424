import React, { useContext } from "react";
import { ModalRenderProps } from "./context";
import Modal from "./Modal";

export function useModal() {
  return useContext(ModalRenderProps);
}

export { Modal as default };
export { DEFAULT, DRAWER } from "./types";
export { default as useModalV2 } from "./useModalV2";
