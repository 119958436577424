export const FileUploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <mask
      id="a"
      width={28}
      height={28}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h28v28H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        d="M7 23.333c-.642 0-1.19-.228-1.647-.684A2.248 2.248 0 0 1 4.667 21v-3.5H7V21h14v-3.5h2.333V21a2.25 2.25 0 0 1-.684 1.648 2.25 2.25 0 0 1-1.649.685H7Zm5.833-4.666V9.158L9.8 12.192 8.167 10.5 14 4.667l5.833 5.833-1.633 1.692-3.033-3.034v9.509h-2.334Z"
      />
    </g>
  </svg>
);
