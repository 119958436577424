import { useMemo } from "react";

export function useUnshift(initialSelectedTags, newlyCreatedMeetingTags) {
  return useMemo(() => {
    return [
      ...newlyCreatedMeetingTags.map((t) => t.id),
      ...initialSelectedTags.map((t) => t.id),
    ];
  }, [initialSelectedTags, newlyCreatedMeetingTags]);
}
