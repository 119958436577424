import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Contact } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

export function useUnsubscribeContactByID() {
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (contact: { id: number; unsubscribed: boolean }): Promise<Contact> => {
    let APICall = service.post;
    if (contact.unsubscribed) {
      APICall = service.delete;
    }
    return APICall(`/api/contact/${contact.id}/unsubscribe`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate contact queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/contact"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .then((res: Response) => res.body)
      .catch(errorHandler);
  };
}
