import React, { useRef } from "react";
import Flexbox from "../../../components/flexbox";
import MeetingTypeDetails from "../meetingTypeDetails";
import MessengerBody from "../messengerBody";
import MessengerInput from "../messengerInput";
import DirectMessenger from "../directMessenger";
import { useMessengerState } from "../state";
import style from "./messenger.module.scss";

const Messenger = () => {
  const messengerRef = useRef(null);
  const messengerHeaderRef = useRef(null);
  const messengerInputRef = useRef(null);
  const { messenger } = useMessengerState();

  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.messenger} ref={messengerRef}>
          <Column className={style.messenger__image} />

          <MeetingTypeDetails
            ref={messengerHeaderRef}
            messenger={messengerRef.current}
            messengerInput={messengerInputRef.current}
            messengerHeader={messengerHeaderRef.current}
          />
          <MessengerBody messengerHeader={messengerHeaderRef.current} />

          {messenger.dmIsOpen && (
            <DirectMessenger
              messenger={messengerRef.current}
              messengerInput={messengerInputRef.current}
              messengerHeader={messengerHeaderRef.current}
            />
          )}

          <MessengerInput ref={messengerInputRef} />
        </Column>
      )}
    </Flexbox>
  );
};

export default Messenger;
