import { Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

type BaseInternalLinkProps = {
  to: string;
  children?: React.ReactNode;
};
export const BaseInternalLink = (props: BaseInternalLinkProps) => (
  <Link sx={{ textDecoration: "none" }} component={RouterLink} to={props.to}>
    {props.children}
  </Link>
);
