import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

function AttemptsFilter({
  value,
  onChange,
}: {
  value?: boolean;
  onChange?: (value: boolean) => void;
}) {
  return (
    <FormControl>
      <FormLabel>Include Attempts</FormLabel>
      <RadioGroup
        defaultValue={false}
        value={value ? "yes" : "no"}
        onChange={(_, v) => {
          if (onChange) {
            onChange(v === "yes");
          }
        }}
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="Yes (include emails to/from the guest)"
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label="No (exclude emails to/from the guest)"
        />
      </RadioGroup>
    </FormControl>
  );
}

export default AttemptsFilter;
