export type ContactStatus = "active" | "unsubscribed";

export interface ContactStatusOption {
  label: string;
  value: ContactStatus;
}

export const ContactStatusOptions: ContactStatusOption[] = [
  { label: "Active", value: "active" },
  { label: "Unsubscribed", value: "unsubscribed" },
];
