import React from "react";
import Flexbox from "../../../components/flexbox";
import MeetingTypeSelect from "../../meetingTypeSelect";
import style from "./newTableCells.module.scss";

const MeetingType = ({ isSaving, setMeetingTypeAndSave, meetingType }) => {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.cell}>
          <MeetingTypeSelect
            disabled={isSaving}
            meetingType={meetingType}
            setMeetingType={setMeetingTypeAndSave}
          />
        </Column>
      )}
    </Flexbox>
  );
};

export default MeetingType;
