import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import useUserSubscription from "../queries/useUserSubscription";
import { PageBanner } from "../../componentsV2/PageBanner";
import useBilling from "../mutations/useBilling";

export default function SubscriptionStatusBanner() {
  const theme = useTheme();
  const fetchBillingUrl = useBilling();
  const { data, loading, error } = useUserSubscription();

  if (loading || error || data?.data?.active) {
    return null;
  }

  const onBillingClick = async () => {
    try {
      const data = await fetchBillingUrl();
      window.location.href = data.url;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PageBanner>
      <Box
        sx={{
          p: 2,
          backgroundColor: theme.palette.warning.main,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.contrastText,
          }}
        >
          Looks like you don't have active subscription required to be able to
          schedule your meetings. Go to the
          <Link component={Button} onClick={onBillingClick} color="inherit">
            subscription management
          </Link>
          to add valid payment method and re-enable your subscription.
        </Typography>
      </Box>
    </PageBanner>
  );
}
