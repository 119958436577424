import { Icon } from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import React from "react";

import style from "./select.module.scss";

export function DropdownIndicator() {
  return (
    <div className={style.select__dropdownIndicator}>
      <Icon path={mdiChevronDown} size={0.7} />
    </div>
  );
}
