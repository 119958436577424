import React from "react";
import { Table, TableRow, TableCell } from "@mui/material";

import { MetricCard } from "./MetricsCard";

export function MetricTable({
  title,
  help,
  rows,
}: {
  title: string;
  help?: string;
  rows: { label: string; value: string | number }[];
}) {
  return (
    <MetricCard title={title} help={help}>
      <Table size="small">
        {rows.map((r) => (
          <TableRow key={r.label}>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {r.label}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                borderBottom: "none",
              }}
            >
              {r.value}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </MetricCard>
  );
}
