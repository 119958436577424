import React from "react";
import {
  Modal as MuiModal,
  Box,
  Paper,
  Typography,
  Stack,
} from "@mui/material";

export function Modal({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) {
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box
        sx={{
          left: "50%",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          sx={{
            padding: "1em",
          }}
        >
          {children}
        </Paper>
      </Box>
    </MuiModal>
  );
}
