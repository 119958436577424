import React, { useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import { P1 } from "../../../components/typography";
import { cleanNote } from "../util/helpers";
import style from "./messageTypes.module.scss";

const SystemNote = ({ message }) => {
  const cleanedNote = useMemo(() => cleanNote(message), [message]);
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.systemNote} height="fit-content">
          <P1 className={style.systemNote__text} bold>
            {cleanedNote}
          </P1>
        </Column>
      )}
    </Flexbox>
  );
};

export default SystemNote;
