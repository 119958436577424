import React from "react";
import { Box } from "@mui/material";

function SimpleBanner({
  color,
  height,
  children,
}: {
  color?: string;
  height?: string | number;
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        backgroundColor: color,
        height,
        overflow: "visible",
        top: 0,
        width: "100%",
        zIndex: 500,
      }}
    >
      {children}
    </Box>
  );
}

function StickyBanner({
  color,
  height,
  children,
}: {
  color?: string;
  height?: string | number;
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        backgroundColor: color,
        height,
        overflow: "visible",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 500,
      }}
    >
      {children}
    </Box>
  );
}

export function PageBanner({
  mode = "simple",
  color,
  height,
  children,
}: {
  mode?: "simple" | "overlay" | "sticky";
  color?: string;
  height?: string | number;
  children: React.ReactNode;
}) {
  switch (mode) {
    default:
      return (
        <SimpleBanner color={color} height={height}>
          {children}
        </SimpleBanner>
      );
    case "overlay":
      return (
        <StickyBanner color={color} height={height}>
          {children}
        </StickyBanner>
      );
    case "sticky":
      return (
        <>
          <SimpleBanner color={color} height={height}>
            {children}
          </SimpleBanner>
          <StickyBanner color={color} height={height}>
            {children}
          </StickyBanner>
        </>
      );
  }
}
